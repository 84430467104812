const loading = require("./loading.gif");

const OPTIONS = {
  start: "2009-07-01",
  end: "2009-12-01",
  loadingScreenTemplate: function () {
    //return '<h1>Loading...</h1>'
    return `<img id='loading' src=${loading}/>`;
  },
  locale: "ja", // vis.jsのデフォルトにはjaは無いがmoment-with-locales.jsを読むと使える
  format: {
    minorLabels: {
      millisecond: "SSS",
      second: "s",
      minute: "HH:mm",
      hour: "HH:mm",
      weekday: "D日(dd)",
      day: "D日(dd)",
      week: "D日(dd)",
      month: "M月",
      year: "YYYY年",
    },
    majorLabels: {
      millisecond: "YYYY年M月D日(dd) HH:mm:ss",
      second: "YYYY年M月D日(dd) HH:mm",
      minute: "YYYY年M月D日(dd)",
      hour: "YYYY年M月D日(dd)",
      weekday: "YYYY年M月",
      day: "YYYY年M月",
      week: "YYYY年M月",
      month: "YYYY年",
      year: "",
    },
  },
};

const DISSMILAR_COLORS = [
  "#000000",
  /* "#FFFF00",*/ "#1CE6FF",
  "#FF34FF",
  "#FF4A46",
  "#008941",
  "#006FA6",
  "#A30059",
  /* "#FFDBE5",*/ "#7A4900",
  "#0000A6",
  "#63FFAC",
  "#B79762",
  "#004D43",
  "#8FB0FF",
  "#997D87",
  "#5A0007",
  "#809693",
  /* "#FEFFE6",*/ "#1B4400",
  "#4FC601",
  "#3B5DFF",
  "#4A3B53",
  "#FF2F80",
  "#61615A",
  "#BA0900",
  "#6B7900",
  "#00C2A0",
  "#FFAA92",
  "#FF90C9",
  "#B903AA",
  "#D16100",
  "#DDEFFF",
  /* "#000035",*/ "#7B4F4B",
  "#A1C299",
  "#300018",
  "#0AA6D8",
  "#013349",
  "#00846F",
  /* "#372101",*/ "#FFB500",
  "#C2FFED",
  "#A079BF",
  "#CC0744",
  "#C0B9B2",
  "#C2FF99",
  "#001E09",
  "#00489C",
  "#6F0062",
  "#0CBD66",
  "#EEC3FF",
  "#456D75",
  "#B77B68",
  "#7A87A1",
  "#788D66",
  "#885578",
  "#FAD09F",
  "#FF8A9A",
  "#D157A0",
  "#BEC459",
  "#456648",
  "#0086ED",
  "#886F4C",
  "#34362D",
  "#B4A8BD",
  "#00A6AA",
  "#452C2C",
  "#636375",
  "#A3C8C9",
  "#FF913F",
  "#938A81",
  "#575329",
  "#00FECF",
  "#B05B6F",
  "#8CD0FF",
  "#3B9700",
  "#04F757",
  "#C8A1A1",
  "#1E6E00",
  "#7900D7",
  "#A77500",
  "#6367A9",
  "#A05837",
  "#6B002C",
  "#772600",
  "#D790FF",
  "#9B9700",
  "#549E79",
  "#FFF69F",
  "#201625",
  "#72418F",
  "#BC23FF",
  "#99ADC0",
  "#3A2465",
  "#922329",
  "#5B4534",
  "#FDE8DC",
  "#404E55",
  "#0089A3",
  "#CB7E98",
  "#A4E804",
  "#324E72",
  "#6A3A4C",
];

export { OPTIONS, DISSMILAR_COLORS };
