import { CLIENT } from "../consts_path";

export const getCropsError = (status) => {
  return {
    type: "GET_CROPS_ERROR",
    hasError: status,
  };
};

export const loadCrops = (status) => {
  return {
    type: "LOAD_CROPS",
    isLoading: status,
  };
};

export const fetchCropsSuccess = (crops) => {
  return {
    type: "FETCH_CROPS_SUCCESS",
    crops,
  };
};

export const fetchCropShowSuccess = (crops) => {
  return {
    type: "FETCH_CROPS_SHOW_SUCCESS",
    crops,
  };
};

export const fetchCropCommonSuccess = (common) => {
  return {
    type: "FETCH_CROP_COMMON_SUCCESS",
    common,
  };
};

export const success = (event) => {
  return {
    type: "SUCCESS",
    event,
  };
};

export const calender = (calender) => {
  return {
    type: "CALENDER",
    calender,
  };
};

export const textbox = (textbox) => {
  return {
    type: "TEXTBOX",
    textbox,
  };
};

export const checkbox = (checkbox) => {
  return {
    type: "CHECKBOX",
    checkbox,
  };
};

export const fetchCrops = (url) => {
  return (dispatch) => {
    dispatch(loadCrops(true));

    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((crops) => {
        dispatch(fetchCropsSuccess(crops));
        dispatch(loadCrops(false));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        // window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const fetchCropShow = (url) => {
  return (dispatch) => {
    dispatch(loadCrops(true));
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((crops) => {
        dispatch(fetchCropShowSuccess(crops.crop));
        dispatch(loadCrops(false));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const fetchCropCommon = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((crops) => {
        dispatch(fetchCropCommonSuccess(crops));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const onChangeRadio = (event, crop) => {
  return (dispatch) => {
    const assign = Object.assign(crop);
    const { target } = event;

    // dispatch(fetchCropShowSuccess(assign));
    dispatch(success({ target, assign }));
  };
};

export const onChangeCalender = (event, crop) => {
  return (dispatch) => {
    const assign = Object.assign(crop);
    const { target } = event;
    const whichOne = event.target.getAttribute("data-which-one");
    dispatch(calender({ target, assign, whichOne }));
  };
};

export const onChangeTextValue = (event, crop) => {
  return (dispatch) => {
    const assign = Object.assign(crop);
    const { target } = event;
    dispatch(textbox({ target, assign }));
  };
};

export const onClickCheckbox = (event, crop) => {
  return (dispatch) => {
    const assign = Object.assign(crop);
    const { target } = event;
    dispatch(checkbox({ target, assign }));
  };
};
