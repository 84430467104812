/* eslint-disable no-dupe-keys */
export const K00_SOIL_CLASS_VALUE2DESCRIPTION_LUT = {
  "A1": ["人工物質土", "人工物質土"],
  "A1j1": ["硬盤型人工物質土", "硬盤型人工物質土"],
  "A1j2": ["無機質人工物質土", "無機質人工物質土"],
  "A1j3": ["有機質人工物質土", "有機質人工物質土"],
  "A2": ["盛土造成土", "盛土造成土"],
  "A2i1": ["台地盛土造成土", "台地盛土造成土"],
  "A2i3": ["低地盛土造成土", "低地盛土造成土"],
  "B1": ["泥炭土", "湿生植物の遺体が、過湿のため分解を免れ厚く堆積した土壌。"],
  "B1e1": ["高位泥炭土", "ミズゴケ類、ホロムイスゲ、ツルコケモモ、ミカズキグサ類、ホロムイソウを合わせた割合（面積）が最も多い泥炭物質からなる泥炭土。"],
  "B1e1w1": ["表層無機質高位泥炭土", "表層に厚さ10cm以上の「無機質層」がある高位泥炭土。"],
  "B1e1w2": ["下層無機質高位泥炭土", "土壌表面から75cm以内に積算して25cm以上の「無機質層」がある高位泥炭土。"],
  "B1e1y1": ["典型高位泥炭土", "典型的な高位泥炭土。"],
  "B1e2": ["中間泥炭土", "ヌマガヤ、ワタスゲ、ヤチヤナギ、アカエゾマツを合わせた割合（面積）が最も多い泥炭物質からなる泥炭土。"],
  "B1e2w1": ["表層無機質中間泥炭土", "表層に厚さ10cm以上の「無機質層」がある中間泥炭土。"],
  "B1e2w2": ["下層無機質中間泥炭土", "土壌表面から75cm以内に積算して25cm以上の「無機質層」がある中間泥炭土。"],
  "B1e2x2": ["繊維質中間泥炭土", "土壌表面から50cmまでの「泥炭物質」のうち、「繊維質泥炭物質」の割合が最も多い中間泥炭土。"],
  "B1e2y1": ["典型中間泥炭土", "典型的な中間泥炭土。"],
  "B1e3": ["低位泥炭土", "高位泥炭物質、中間泥炭物質以外の泥炭物質からなる泥炭土。"],
  "B1e3w1": ["表層無機質低位泥炭土", "表層に厚さ10cm以上の「無機質層」がある低位泥炭土。"],
  "B1e3w2": ["下層無機質低位泥炭土", "土壌表面から75cm以内に積算して25cm以上の「無機質層」がある低位泥炭土。"],
  "B1e3x2": ["繊維質低位泥炭土", "土壌表面から50cmまでの「泥炭物質」のうち、「繊維質泥炭物質」の割合が最も多い低位泥炭土。"],
  "B1e3y1": ["典型低位泥炭土", "典型的な低位泥炭土。"],
  "B1e4": ["低位泥炭土", "高位泥炭物質、中間泥炭物質以外の泥炭物質からなる泥炭土。"],
  "B1e4": ["腐朽質泥炭土", "土壌表面から50cmまでの「泥炭物質」からなる層のうち、「腐朽質泥炭物質」の割合が最も多い泥炭土。"],
  "B1e4w1": ["表層無機質低位泥炭土", "表層に厚さ10cm以上の「無機質層」がある低位泥炭土。"],
  "B1e4w1": ["表層無機質腐朽質泥炭土", "表層に厚さ10cm以上の「無機質層」がある腐朽質泥炭土。"],
  "B1e4w2": ["下層無機質低位泥炭土", "土壌表面から75cm以内に積算して25cm以上の「無機質層」がある低位泥炭土。"],
  "B1e4w2": ["下層無機質腐朽質泥炭土", "土壌表面から75cm以内に積算して25cm以上の「無機質層」がある腐朽質泥炭土。"],
  "B1e4x2": ["繊維質低位泥炭土", "土壌表面から50cmまでの「泥炭物質」のうち、「繊維質泥炭物質」の割合が最も多い低位泥炭土。"],
  "B1e4x2": ["不明", "不明"],
  "B1e4y1": ["典型低位泥炭土", "典型的な低位泥炭土。"],
  "B1e4y1": ["典型腐朽質泥炭土", "典型的な腐朽質泥炭土。"],
  "C1": ["ポドゾル", "漂白した層と腐植または鉄・アルミニウムが集積した層の層序をもつ土壌。"],
  "C1a3": ["湿性ポドゾル", "土壌表面から75cm以内に「地下水湿性特徴」を示す層または「グライ層」の上端が現れるポドゾル。"],
  "C1a3p1": ["岩盤質湿性ポドゾル", "土壌表面から60cm以内に岩盤が現れる湿性ポドゾル。"],
  "C1a3p2": ["礫質湿性ポドゾル", "土壌表面から60cm以内に礫層が現れる湿性ポドゾル。"],
  "C1a3y1": ["典型湿性ポドゾル", "典型的な湿性ポドゾル。"],
  "C1b1": ["疑似グライ化ポドゾル", "土壌表面から50-75cmに「疑似グライ層」の上端が現れるポドゾル。"],
  "C1b1p1": ["岩盤質疑似グライ化ポドゾル", "土壌表面から60cm以内に岩盤が現れる疑似グライ化ポドゾル。"],
  "C1b1p2": ["礫質疑似グライ化ポドゾル", "土壌表面から60cm以内に礫層が現れる疑似グライ化ポドゾル。"],
  "C1b1y1": ["典型疑似グライ化ポドゾル", "典型的な疑似グライ化ポドゾル。"],
  "C1b2": ["表層疑似グライ化ポドゾル", "土壌表面から50cm以内に「疑似グライ層」の上端が現れるポドゾル。"],
  "C1b2p1": ["岩盤質表層疑似グライ化ポドゾル", "土壌表面から60cm以内に岩盤が現れる表層疑似グライ化ポドゾル。"],
  "C1b2p2": ["礫質表層疑似グライ化ポドゾル", "土壌表面から60cm以内に礫層が現れる表層疑似グライ化ポドゾル。"],
  "C1b2y1": ["典型表層疑似グライ化ポドゾル", "典型的な表層疑似グライ化ポドゾル。"],
  "C1e5": ["表層泥炭質ポドゾル", "土壌表面から25cm以内に、積算して厚さ10cm以上の「泥炭物質」からなる層をもつポドゾル。"],
  "C1e5p1": ["岩盤質表層泥炭質ポドゾル", "土壌表面から60cm以内に岩盤が現れる表層泥炭質ポドゾル。"],
  "C1e5p2": ["礫質表層泥炭質ポドゾル", "土壌表面から60cm以内に礫層が現れる表層泥炭質ポドゾル。"],
  "C1e5y1": ["典型表層泥炭質ポドゾル", "典型的な表層泥炭質ポドゾル。"],
  "C1z1": ["普通ポドゾル", "典型的なポドゾル。"],
  "C1z1p1": ["岩盤質普通ポドゾル", "土壌表面から60cm以内に岩盤が現れる普通ポドゾル。"],
  "C1z1p2": ["礫質普通ポドゾル", "土壌表面から60cm以内に礫層が現れる普通ポドゾル。"],
  "C1z1y1": ["典型普通ポドゾル", "典型的な普通ポドゾル。"],
  "D1": ["未熟黒ボク土", "堆積した火山放出物が、ある程度の土壌化作用を受け、リン酸を固定する性質や有機物の集積（全炭素で3％以上）を示しはじめた段階の土壌。"],
  "D1a3": ["湿性未熟黒ボク土", "土壌表面から50cm以内に、「地下水湿性特徴」を示す層または「地下水グライ層」の上端が現れる未熟黒ボク土。"],
  "D1a3w3": ["下層泥炭湿性未熟黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「泥炭物質」からなる層をもつ湿性未熟黒ボク土。"],
  "D1a3w4": ["下層低地湿性未熟黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層をもつ湿性未熟黒ボク土。"],
  "D1a3y1": ["典型湿性未熟黒ボク土", "典型的な湿性未熟黒ボク土。"],
  "D1h3": ["腐植質未熟黒ボク土", "表層に有機物を多く含む「腐植質表層」または「多腐植質表層」をもつ未熟黒ボク土 。"],
  "D1h3p3": ["盤層型腐植質未熟黒ボク土", "土壌表面から60cm以内に盤層が現れる腐植質未熟黒ボク土。"],
  "D1h3w4": ["下層低地腐植質未熟黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層のをもつ腐植質未熟黒ボク土。"],
  "D1h3w5": ["下層黒ボク腐植質未熟黒ボク土", "土壌表面から100cm以内に積算で25cm以上の「黒ボク特徴」を示す層をもつ腐植質未熟黒ボク土。"],
  "D1h3y1": ["典型腐植質未熟黒ボク土", "典型的な腐植質未熟黒ボク土。"],
  "D1h4": ["埋没腐植質未熟黒ボク土", "過去に表層であった層で、かつ、有機物が蓄積した「埋没腐植層」の上端が土壌表面から50cm以内に現れる未熟黒ボク土。"],
  "D1h4p3": ["盤層型埋没腐植質未熟黒ボク土", "土壌表面から60cm以内に盤層が現れる埋没腐植質未熟黒ボク土。"],
  "D1h4y1": ["典型埋没腐植質未熟黒ボク土", "典型的な埋没腐植質未熟黒ボク土。"],
  "D1z1": ["普通未熟黒ボク土", "典型的な未熟黒ボク土。"],
  "D1z1p3": ["盤層型普通未熟黒ボク土", "土壌表面から60cm以内に盤層が現れる普通未熟黒ボク土。"],
  "D1z1w4": ["下層低地普通未熟黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層のをもつ普通未熟黒ボク土。"],
  "D1z1w5": ["下層黒ボク普通未熟黒ボク土", "土壌表面から100cm以内に積算で25cm以上の「黒ボク特徴」を示す層をもつ普通未熟黒ボク土。"],
  "D1z1y1": ["典型普通未熟黒ボク土", "典型的な普通未熟黒ボク土。"],
  "D2": ["グライ黒ボク土", "地下水位の高い場所にある黒ボク土で、一年を通じて水で飽和されている層が50cm以内に出てくる土壌。"],
  "D2e6": ["泥炭質グライ黒ボク土", "土壌表面から100cm以内に積算して厚さ 25cm以上の湿生植物の遺体からなる層をもつグライ黒ボク土。"],
  "D2e6v3": ["多腐植質泥炭質グライ黒ボク土", "「多腐植質表層」をもつ泥炭質グライ黒ボク土。"],
  "D2e6y1": ["典型泥炭質グライ黒ボク土", "典型的な泥炭質グライ黒ボク土。"],
  "D2h1": ["厚層グライ黒ボク土", "表層に有機物を多く含み、かつ、層の厚さが50cmを超える「腐植質表層」または「多腐植質表層」をもつグライ黒ボク土。"],
  "D2h1v3": ["多腐植質厚層グライ黒ボク土", "「多腐植質表層」をもつ厚層グライ黒ボク土。"],
  "D2h1v4": ["腐植質厚層グライ黒ボク土", "「腐植質表層」をもつ厚層グライ黒ボク土。"],
  "D2h1x4": ["非アロフェン質厚層グライ黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ厚層グライ黒ボク土。"],
  "D2z1": ["普通グライ黒ボク土", "典型的なグライ黒ボク土。"],
  "D2z1p1": ["岩盤質普通グライ黒ボク土", "土壌表面から60cm以内に岩盤が現れる普通グライ黒ボク土。"],
  "D2z1p2": ["礫質普通グライ黒ボク土", "土壌表面から60cm以内に礫層が現れる普通グライ黒ボク土。"],
  "D2z1v3": ["多腐植質普通グライ黒ボク土", "「多腐植質表層」をもつ普通グライ黒ボク土。"],
  "D2z1v4": ["普通グライ黒ボク土の一種", ""],
  "D2z1y1": ["典型普通グライ黒ボク土", "典型的な普通グライ黒ボク土。"],
  "D3": ["多湿黒ボク土", "地下水の影響で湿っている黒ボク土。"],
  "D3d1": ["下層台地多湿黒ボク土", "土壌表面から50cm以内に黒ボク土とは異なる台地土壌の上端が現れる多湿黒ボク土。"],
  "D3d1v3": ["多腐植質下層台地多湿黒ボク土", "「多腐植質表層」をもつ下層台地多湿黒ボク土。"],
  "D3d1y1": ["典型下層台地多湿黒ボク土", "典型的な下層台地多湿黒ボク土。"],
  "D3d2": ["下層低地多湿黒ボク土", "土壌表面から75cm以内に積算して25cm以上の河川氾濫堆積物（沖積堆積物）からなる層をもつ多湿黒ボク土。"],
  "D3d2p2": ["礫質下層低地多湿黒ボク土", "土壌表面から60cm以内に礫層または岩盤が現れる下層低地多湿黒ボク土。"],
  "D3d2v3": ["多腐植質下層低地多湿黒ボク土", "「多腐植質表層」をもつ下層低地多湿黒ボク土。"],
  "D3d2y1": ["典型下層低地多湿黒ボク土", "典型的な下層低地多湿黒ボク土。"],
  "D3e6": ["泥炭質多湿黒ボク土", "土壌表面から100cm以内に積算して厚さ 25cm以上の湿生植物の遺体からなる層をもつ多湿黒ボク土。"],
  "D3e6v3": ["多腐植質泥炭質多湿黒ボク土", "「多腐植質表層」をもつ泥炭質多湿黒ボク土。"],
  "D3e6y1": ["典型泥炭質多湿黒ボク土", "典型的な泥炭質多湿黒ボク土。"],
  "D3h1": ["厚層多湿黒ボク土", "表層に有機物を多く含み、かつ、層の厚さが50cmを超える「腐植質表層」または「多腐植質表層」をもつ多湿黒ボク土。"],
  "D3h1p1": ["岩盤質厚層多湿黒ボク土", "土壌表面から60cm以内に岩盤が現れる厚層多湿黒ボク土。"],
  "D3h1p2": ["礫質厚層多湿黒ボク土", "土壌表面から60cm以内に礫層が現れる厚層多湿黒ボク土。"],
  "D3h1v3": ["多腐植質厚層多湿黒ボク土", "「多腐植質表層」をもつ厚層多湿黒ボク土。"],
  "D3h1v4": ["腐植質厚層多湿黒ボク土", "「腐植質表層」をもつ厚層多湿黒ボク土。"],
  "D3h1x4": ["非アロフェン質厚層多湿黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ厚層多湿黒ボク土。"],
  "D3h1y1": ["厚層多湿黒ボク土の一種", ""],
  "D3z1": ["普通多湿黒ボク土", "典型的な多湿黒ボク土。"],
  "D3z1p1": ["岩盤質普通多湿黒ボク土", "土壌表面から60cm以内に岩盤が現れる普通多湿黒ボク土。"],
  "D3z1p2": ["礫質普通多湿黒ボク土", "土壌表面から60cm以内に礫層が現れる普通多湿黒ボク土。"],
  "D3z1t1": ["普通多湿黒ボク土の一種", ""],
  "D3z1t2": ["普通多湿黒ボク土の一種", ""],
  "D3z1v3": ["多腐植質普通多湿黒ボク土", "「多腐植質表層」をもつ普通多湿黒ボク土。"],
  "D3z1v4": ["普通多湿黒ボク土の一種", ""],
  "D3z1x4": ["非アロフェン質普通多湿黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ普通多湿黒ボク土。"],
  "D3z1y1": ["典型普通多湿黒ボク土", "典型的な普通多湿黒ボク土。"],
  "D4": ["褐色黒ボク土", "森林下に出来る黒ボク土で、有機物含量は高いが、黒色味が弱く褐色の表層を持つ。"],
  "D4h1": ["厚層褐色黒ボク土", "表層60cm以内に50cm以上の褐色で有機物含量が高い「褐色多腐植質黒ボク表層」をもつ褐色黒ボク土。"],
  "D4h1p1": ["岩盤質厚層褐色黒ボク土", "土壌表面から60cm以内に岩盤が現れる厚層褐色黒ボク土。"],
  "D4h1p2": ["礫質厚層褐色黒ボク土", "土壌表面から60cm以内に礫層が現れる厚層褐色黒ボク土。"],
  "D4h1x4": ["非アロフェン質厚層褐色黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ厚層褐色黒ボク土。"],
  "D4h1y1": ["典型厚層褐色黒ボク土", "典型的な厚層褐色黒ボク土。"],
  "D4h4": ["埋没腐植質褐色黒ボク土", "過去に表層であった層で、かつ、有機物が蓄積した「埋没腐植層」の上端が土壌表面から50cm以内に現れる褐色黒ボク土。"],
  "D4h4p1": ["岩盤質埋没腐植質褐色黒ボク土", "土壌表面から60cm以内に岩盤が現れる埋没腐植質褐色黒ボク土。"],
  "D4h4p2": ["礫質埋没腐植質褐色黒ボク土", "土壌表面から60cm以内に礫層が現れる埋没腐植質褐色黒ボク土。"],
  "D4h4x4": ["非アロフェン質埋没腐植質褐色黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ埋没腐植質褐色黒ボク土。"],
  "D4h4y1": ["典型埋没腐植質褐色黒ボク土", "典型的な埋没腐植質褐色黒ボク土。"],
  "D4z1": ["普通褐色黒ボク土", "典型的な褐色黒ボク土。"],
  "D4z1p1": ["岩盤質普通褐色黒ボク土", "土壌表面から60cm以内に岩盤が現れる普通褐色黒ボク土。"],
  "D4z1p2": ["礫質普通褐色黒ボク土", "土壌表面から60cm以内に礫層が現れる普通褐色黒ボク土。"],
  "D4z1x4": ["非アロフェン質普通褐色黒ボク土", "土壌表面から50cm以内に積算して厚さ25cm以上の「非アロフェン質黒ボク特徴」を示す層をもつ普通褐色黒ボク土。"],
  "D4z1y1": ["典型普通褐色黒ボク土", "典型的な普通褐色黒ボク土。"],
  "D5": ["非アロフェン質黒ボク土", "結晶性粘土を含む黒ボク土。"],
  "D5c1": ["水田化非アロフェン質黒ボク土", "水田利用による湛水期間中に代かき層から還元溶脱した鉄が下部の酸化的な層で酸化沈殿して形成された「水田鉄集積層」をもつ非アロフェン質黒ボク土。"],
  "D5c1p1": ["岩盤質水田化非アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる水田化非アロフェン質黒ボク土。"],
  "D5c1p2": ["礫質水田化非アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる水田化非アロフェン質黒ボク土。"],
  "D5c1v2": ["厚層水田化非アロフェン質黒ボク土", "厚さ50cm以上の「腐植質表層」または「多腐植質表層」をもつ水田化非アロフェン質黒ボク土。"],
  "D5c1w4": ["下層低地水田化非アロフェン質黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層をもつ水田化非アロフェン質黒ボク土。"],
  "D5c1y1": ["典型水田化非アロフェン質黒ボク土", "典型的な水田化非アロフェン質黒ボク土。"],
  "D5h1": ["厚層非アロフェン質黒ボク土", "表層に有機物を多く含み、かつ、層の厚さが50cmを超える「腐植質表層」または「多腐植質表層」をもつ非アロフェン質黒ボク土。"],
  "D5h1v3": ["多腐植質厚層非アロフェン質黒ボク土", "「多腐植質表層」をもつ厚層非アロフェン質黒ボク土。"],
  "D5h1v4": ["腐植質厚層非アロフェン質黒ボク土", "「腐植質表層」をもつ厚層非アロフェン質黒ボク土。"],
  "D5h4": ["埋没腐植質非アロフェン質黒ボク土", "過去に表層であった層で、かつ、有機物が蓄積した「埋没腐植層」の上端が土壌表面から50cm以内に現れる非アロフェン質黒ボク土。"],
  "D5h4p1": ["岩盤質埋没腐植質非アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる埋没腐植質非アロフェン質黒ボク土。"],
  "D5h4p2": ["礫質埋没腐植質非アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる埋没腐植質非アロフェン質黒ボク土。"],
  "D5h4y1": ["典型埋没腐植質非アロフェン質黒ボク土", "典型的な埋没腐植質非アロフェン質黒ボク土。"],
  "D5z1": ["普通非アロフェン質黒ボク土", "典型的な非アロフェン質黒ボク土。"],
  "D5z1p1": ["岩盤質普通非アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる普通非アロフェン質黒ボク土。"],
  "D5z1p2": ["礫質普通非アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる普通非アロフェン質黒ボク土。"],
  "D5z1v1": ["淡色普通非アロフェン質黒ボク土", "「腐植質表層」または「多腐植質表層」をもたない普通非アロフェン質黒ボク土。"],
  "D5z1v3": ["多腐植質普通非アロフェン質黒ボク土", "「多腐植質表層」をもつ普通非アロフェン質黒ボク土。"],
  "D5z1v4": ["腐植質普通非アロフェン質黒ボク土", "「腐植質表層」をもつ普通非アロフェン質黒ボク土。"],
  "D5z1w4": ["下層低地普通非アロフェン質黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層をもつ普通非アロフェン質黒ボク土。"],
  "D6": ["アロフェン質黒ボク土", "非晶質粘土鉱物（アロフェン）主体の黒ボク土。"],
  "D6c1": ["水田化アロフェン質黒ボク土", "水田利用による湛水期間中に代かき層から還元溶脱した鉄が下部の酸化的な層で酸化沈殿して形成された「水田鉄集積層」をもつアロフェン質黒ボク土。"],
  "D6c1p1": ["岩盤質水田化アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる水田化アロフェン質黒ボク土。"],
  "D6c1p2": ["礫質水田化アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる水田化アロフェン質黒ボク土。"],
  "D6c1v1": ["淡色水田化アロフェン質黒ボク土", "「腐植質表層」または「多腐植質表層」をもたない水田化アロフェン質黒ボク土。"],
  "D6c1v2": ["厚層水田化アロフェン質黒ボク土", "厚さ50cm以上の「腐植質表層」または「多腐植質表層」をもつ水田化アロフェン質黒ボク土。"],
  "D6c1v3": ["多腐植質水田化アロフェン質黒ボク土", "「多腐植質表層」をもつ水田化アロフェン質黒ボク土。"],
  "D6c1v4": ["腐植質水田化アロフェン質黒ボク土", "「腐植質表層」をもつ水田化アロフェン質黒ボク土。"],
  "D6c1w4": ["下層低地水田化アロフェン質黒ボク土", "土壌表面から75cm以内に積算して厚さ25cm以上の「沖積堆積物」からなる層をもつ水田化アロフェン質黒ボク土。"],
  "D6d1": ["下層台地アロフェン質黒ボク土", "土壌表面から50cm以内に黒ボク土とは異なる台地土壌の上端が現れるアロフェン質黒ボク土。"],
  "D6d1p1": ["岩盤質下層台地アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる下層台地アロフェン質黒ボク土。"],
  "D6d1p2": ["礫質下層台地アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる下層台地アロフェン質黒ボク土。"],
  "D6d1v1": ["淡色下層台地アロフェン質黒ボク土", "「腐植質表層」または「多腐植質表層」をもたない下層台地アロフェン質黒ボク土。"],
  "D6d1v3": ["多腐植質下層台地アロフェン質黒ボク土", "「多腐植質表層」をもつ下層台地アロフェン質黒ボク土。"],
  "D6d1v4": ["腐植質下層台地アロフェン質黒ボク土", "「腐植質表層」をもつ下層台地アロフェン質黒ボク土。"],
  "D6d2": ["下層低地アロフェン質黒ボク土", "土壌表面から75cm以内に積算して25cm以上の河川氾濫堆積物（沖積堆積物）からなる層をもつアロフェン質黒ボク土。"],
  "D6d2p2": ["礫質下層低地アロフェン質黒ボク土", "土壌表面から60cm以内に礫層または岩盤が現れる下層低地アロフェン質黒ボク土。"],
  "D6d2v1": ["淡色下層低地アロフェン質黒ボク土", "「腐植質表層」または「多腐植質表層」をもたない下層低地アロフェン質黒ボク土。"],
  "D6d2v3": ["多腐植質下層低地アロフェン質黒ボク土", "「多腐植質表層」をもつ下層低地アロフェン質黒ボク土。"],
  "D6d2v4": ["腐植質下層低地アロフェン質黒ボク土", "「腐植質表層」をもつ下層低地アロフェン質黒ボク土。"],
  "D6h1": ["厚層アロフェン質黒ボク土", "表層に有機物を多く含み、かつ、層の厚さが50cmを超える「腐植質表層」または「多腐植質表層」をもつアロフェン質黒ボク土 。"],
  "D6h1p1": ["岩盤質厚層アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる厚層アロフェン質黒ボク土。"],
  "D6h1p2": ["礫質厚層アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる厚層アロフェン質黒ボク土。"],
  "D6h1p3": ["盤層型厚層アロフェン質黒ボク土", "土壌表面から60cm以内に盤層が現れる厚層アロフェン質黒ボク土。"],
  "D6h1v3": ["多腐植質厚層アロフェン質黒ボク土", "「多腐植質表層」をもつ厚層アロフェン質黒ボク土。"],
  "D6h1v4": ["腐植質厚層アロフェン質黒ボク土", "「腐植質表層」をもつ厚層アロフェン質黒ボク土。"],
  "D6h2": ["淡色アロフェン質黒ボク土", "表層の有機物含量は低く、土色は淡いアロフェン質黒ボク土。"],
  "D6h2p1": ["岩盤質淡色アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる淡色アロフェン質黒ボク土。"],
  "D6h2p2": ["礫質淡色アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる淡色アロフェン質黒ボク土。"],
  "D6h2p3": ["盤層型淡色アロフェン質黒ボク土", "土壌表面から60cm以内に盤層が現れる淡色アロフェン質黒ボク土。"],
  "D6h2t2": ["淡色アロフェン質黒ボク土の一種", ""],
  "D6h2v5": ["埋没腐植質淡色アロフェン質黒ボク土", "土壌表面から50cm以内に「埋没腐植層」の上端が現れる淡色アロフェン質黒ボク土。"],
  "D6h2y1": ["典型淡色アロフェン質黒ボク土", "典型的な淡色アロフェン質黒ボク土。"],
  "D6h4": ["埋没腐植質アロフェン質黒ボク土", "過去に表層であった層で、かつ、有機物が蓄積した「埋没腐植層」の上端が土壌表面から50cm以内に現れるアロフェン質黒ボク土。"],
  "D6h4p1": ["岩盤質埋没腐植質アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる埋没腐植質アロフェン質黒ボク土。"],
  "D6h4p2": ["礫質埋没腐植質アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる埋没腐植質アロフェン質黒ボク土。"],
  "D6h4p3": ["盤層型埋没腐植質アロフェン質黒ボク土", "土壌表面から60cm以内に盤層が現れる埋没腐植質アロフェン質黒ボク土。"],
  "D6h4y1": ["典型埋没腐植質アロフェン質黒ボク土", "典型的な埋没腐植質アロフェン質黒ボク土。"],
  "D6z1": ["普通アロフェン質黒ボク土", "典型的なアロフェン質黒ボク土。"],
  "D6z1p1": ["岩盤質普通アロフェン質黒ボク土", "土壌表面から60cm以内に岩盤が現れる普通アロフェン質黒ボク土。"],
  "D6z1p2": ["礫質普通アロフェン質黒ボク土", "土壌表面から60cm以内に礫層が現れる普通アロフェン質黒ボク土。"],
  "D6z1p3": ["盤層型普通アロフェン質黒ボク土", "土壌表面から60cm以内に盤層が現れる普通アロフェン質黒ボク土。"],
  "D6z1t2": ["普通アロフェン質黒ボク土の一種", ""],
  "D6z1v3": ["多腐植質普通アロフェン質黒ボク土", "「多腐植質表層」をもつ普通アロフェン質黒ボク土。"],
  "D6z1v4": ["腐植質普通アロフェン質黒ボク土", "「腐植質表層」をもつ普通アロフェン質黒ボク土。"],
  "D6z1y3": ["普通アロフェン質黒ボク土の一種", ""],
  "E1": ["石灰性暗赤色土", "石灰岩など石灰質の母材から出来た土壌。"],
  "E1j4": ["粘土集積石灰性暗赤色土", "「粘土集積層」をもつ石灰性暗赤色土。"],
  "E1j4p1": ["岩盤質粘土集積石灰性暗赤色土", "土壌表面から60cm以内に岩盤が現れる粘土集積石灰性暗赤色土。"],
  "E1j4p2": ["礫質粘土集積石灰性暗赤色土", "土壌表面から60cm以内に礫層が現れる粘土集積石灰性暗赤色土。"],
  "E1j4y1": ["典型粘土集積石灰性暗赤色土", "典型的な粘土集積石灰性暗赤色土。"],
  "E1z1": ["普通石灰性暗赤色土", "典型的な石灰性暗赤色土。"],
  "E1z1p1": ["岩盤質普通石灰性暗赤色土", "土壌表面から60cm以内に岩盤が現れる普通石灰性暗赤色土。"],
  "E1z1p2": ["礫質普通石灰性暗赤色土", "土壌表面から60cm以内に礫層が現れる普通石灰性暗赤色土。"],
  "E1z1y1": ["典型普通石灰性暗赤色土", "典型的な普通石灰性暗赤色土。"],
  "E2": ["酸性暗赤色土", "暗赤色の次表層の一部で酸性（pHが5.5未満）を示す土壌。"],
  "E2j4": ["粘土集積酸性暗赤色土", "「粘土集積層」をもつ酸性暗赤色土。"],
  "E2j4p1": ["岩盤質粘土集積酸性暗赤色土", "土壌表面から60cm以内に岩盤が現れる粘土集積酸性暗赤色土。"],
  "E2j4p2": ["礫質粘土集積酸性暗赤色土", "土壌表面から60cm以内に礫層が現れる粘土集積酸性暗赤色土。"],
  "E2j4t1": ["粘土集積酸性暗赤色土の一種", ""],
  "E2j4y1": ["典型粘土集積酸性暗赤色土", "典型的な粘土集積酸性暗赤色土。"],
  "E2z1": ["普通酸性暗赤色土", "典型的な酸性暗赤色土。"],
  "E2z1p1": ["岩盤質普通酸性暗赤色土", "土壌表面から60cm以内に岩盤が現れる普通酸性暗赤色土。"],
  "E2z1p2": ["礫質普通酸性暗赤色土", "土壌表面から60cm以内に礫層が現れる普通酸性暗赤色土。"],
  "E2z1y1": ["典型普通酸性暗赤色土", "典型的な普通酸性暗赤色土。"],
  "E3": ["塩基性暗赤色土", "主に超塩基性岩（かんらん岩、蛇紋岩）を母材とし、pHが高い土壌。"],
  "E3j4": ["粘土集積塩基性暗赤色土", "「粘土集積層」をもつ塩基性暗赤色土。"],
  "E3j4p1": ["岩盤質粘土集積塩基性暗赤色土", "土壌表面から60cm以内に岩盤が現れる粘土集積塩基性暗赤色土。"],
  "E3j4p2": ["礫質粘土集積塩基性暗赤色土", "土壌表面から60cm以内に礫層が現れる粘土集積塩基性暗赤色土。"],
  "E3j4y1": ["典型粘土集積塩基性暗赤色土", "典型的な粘土集積塩基性暗赤色土。"],
  "E3z1": ["普通塩基性暗赤色土", "典型的な塩基性暗赤色土。"],
  "E3z1p1": ["岩盤質普通塩基性暗赤色土", "土壌表面から60cm以内に岩盤が現れる普通塩基性暗赤色土。"],
  "E3z1p2": ["礫質普通塩基性暗赤色土", "土壌表面から60cm以内に礫層が現れる普通塩基性暗赤色土。"],
  "E3z1y1": ["典型普通塩基性暗赤色土", "典型的な普通塩基性暗赤色土。"],
  "F1": ["低地水田土", "元々地下水の影響が無いか弱いところに水田を作ったため、鉄集積層が出来たり、灰色化した特徴的な断面をもつようになった低地の土壌。"],
  "F1a3": ["湿性低地水田土", "土壌表面から75cm以内にグライ層よりも酸化的な「地下水湿性特徴」が現れる低地水田土。"],
  "F1a3p2": ["礫質湿性低地水田土", "土壌表面から60cm以内に礫層または岩盤が現れる湿性低地水田土。"],
  "F1a3t1": ["細粒質湿性低地水田土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である湿性低地水田土。"],
  "F1a3t2": ["中粒質湿性低地水田土", "次表層の土性がシルト質壌土、壌土、細砂壌土である湿性低地水田土。"],
  "F1a3t4": ["粗粒質湿性低地水田土", "次表層の土性が粗砂壌土、壌質砂土、砂土である湿性低地水田土。"],
  "F1a5": ["表層グライ化低地水田土", "水稲耕作下で灌漑水によって生成し、落水後も維持され作土から下方へ発達しているグライ層がある低地水田土。 "],
  "F1a5p2": ["礫質表層グライ化低地水田土", "土壌表面から60cm以内に礫層または岩盤が現れる表層グライ化低地水田土。"],
  "F1a5t1": ["細粒質表層グライ化低地水田土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である表層グライ化低地水田土。"],
  "F1a5t3": ["中粗粒質表層グライ化低地水田土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である表層グライ化低地水田土。"],
  "F1b3": ["下層褐色低地水田土", "土壌表面から75cm以内に厚さ15cm以上の黄褐色の層（乾いた条件下で生成）の上端が現れる低地水田土。"],
  "F1b3p2": ["礫質下層褐色低地水田土", "土壌表面から60cm以内に礫層または岩盤が現れる下層褐色低地水田土。"],
  "F1b3t1": ["細粒質下層褐色低地水田土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である下層褐色低地水田土。"],
  "F1b3t2": ["中粒質下層褐色低地水田土", "次表層の土性がシルト質壌土、壌土、細砂壌土である下層褐色低地水田土。"],
  "F1b3t4": ["粗粒質下層褐色低地水田土", "次表層の土性が粗砂壌土、壌質砂土、砂土である下層褐色低地水田土。"],
  "F1j6": ["漂白化低地水田土", "水田の作土層のうち、粘土、鉄の移動により漂白化を受けた低地水田土。"],
  "F1j6p2": ["礫質漂白化低地水田土", "土壌表面から60cm以内に礫層または岩盤が現れる漂白化低地水田土。"],
  "F1j6y1": ["典型漂白化低地水田土", "典型的な漂白化低地水田土。"],
  "F1z1": ["普通低地水田土", "典型的な低地水田土。"],
  "F1z1p2": ["礫質普通低地水田土", "土壌表面から60cm以内に礫層または岩盤が現れる普通低地水田土。"],
  "F1z1t1": ["細粒質普通低地水田土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通低地水田土。"],
  "F1z1t2": ["中粒質普通低地水田土", "次表層の土性がシルト質壌土、壌土、細砂壌土である普通低地水田土。"],
  "F1z1t3": ["普通低地水田土の一種", ""],
  "F1z1t4": ["粗粒質普通低地水田土", "次表層の土性が粗砂壌土、壌質砂土、砂土である普通低地水田土。"],
  "F2": ["グライ低地土", "低地土大群の中で、最も地下水位が高く、年間を通じてほとんど水に飽和されたグライ層が50cm以内に出てくる土壌。"],
  "F2a1": ["還元型グライ低地土", "土壌表面から25cm以深のグライ層に「含む」以上の斑鉄がないグライ低地土。"],
  "F2a1p2": ["礫質還元型グライ低地土", "土壌表面から60cm以内に礫層または岩盤が現れる還元型グライ低地土。"],
  "F2a1t1": ["細粒質還元型グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である還元型グライ低地土。"],
  "F2a1t2": ["中粒質還元型グライ低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である還元型グライ低地土。"],
  "F2a1t4": ["粗粒質還元型グライ低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である還元型グライ低地土。"],
  "F2a2": ["斑鉄型グライ低地土", "土壌表面から25cm以深のグライ層に「含む」以上の斑鉄があるグライ低地土。還元型グライ低地土よりもやや酸化的である。"],
  "F2a2p2": ["礫質斑鉄型グライ低地土", "土壌表面から60cm以内に礫層または岩盤が現れる斑鉄型グライ低地土。"],
  "F2a2t1": ["細粒質斑鉄型グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である斑鉄型グライ低地土。"],
  "F2a2t2": ["中粒質斑鉄型グライ低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である斑鉄型グライ低地土。"],
  "F2a2t4": ["粗粒質斑鉄型グライ低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である斑鉄型グライ低地土。"],
  "F2a6": ["表層灰色グライ低地土", "土壌表面から25-50cmの間にグライ層よりも酸化的な「地下水湿性特徴」が現れるグライ低地土。"],
  "F2a6p2": ["礫質表層灰色グライ低地土", "土壌表面から60cm以内に礫層または岩盤が現れる表層灰色グライ低地土。"],
  "F2a6t1": ["細粒質表層灰色グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である表層灰色グライ低地土。"],
  "F2a6t2": ["中粒質表層灰色グライ低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である表層灰色グライ低地土。"],
  "F2a6t4": ["粗粒質表層灰色グライ低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である表層灰色グライ低地土。"],
  "F2e6": ["泥炭質グライ低地土", "表層100cm以内に積算して25cm以上の「泥炭物質」からなる層があるグライ低地土。 "],
  "F2e6t1": ["細粒質泥炭質グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である泥炭質グライ低地土。"],
  "F2e6t2": ["中粒質泥炭質グライ低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である泥炭質グライ低地土。"],
  "F2e6t4": ["粗粒質泥炭質グライ低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である泥炭質グライ低地土。"],
  "F2h3": ["腐植質グライ低地土", "表層に有機物を多く含む「腐植質表層」または「多腐植質表層」をもつ グライ低地土。"],
  "F2h3p2": ["礫質腐植質グライ低地土", "土壌表面から60cm以内に礫層または岩盤が現れる腐植質グライ低地土。"],
  "F2h3t1": ["細粒質腐植質グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質グライ低地土。"],
  "F2h3t3": ["中粗粒質腐植質グライ低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質グライ低地土。"],
  "F2j7": ["硫酸酸性質グライ低地土", "硫化物を含む湖沼成・海成堆積物からなり、強い酸性を示すグライ低地土。"],
  "F2j7t1": ["細粒質硫酸酸性質グライ低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である硫酸酸性質グライ低地土。"],
  "F2j7y1": ["典型硫酸酸性質グライ低地土", "典型的な硫酸酸性質グライ低地土。"],
  "F2z1t1": ["グライ低地土の一種", ""],
  "F2z1t3": ["グライ低地土の一種", ""],
  "F3": ["灰色低地土", "中間的な湿性状態の沖積地の土壌。"],
  "F3a5": ["表層グライ化灰色低地土", "水稲耕作下で灌漑水によって生成し、落水後も維持され作土から下方へ発 達しているグライ層をもつ灰色低地土。 "],
  "F3a5p2": ["礫質表層グライ化灰色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる表層グライ化灰色低地土。"],
  "F3a5t1": ["細粒質表層グライ化灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である表層グライ化灰色低地土。"],
  "F3a5t3": ["中粗粒質表層グライ化灰色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である表層グライ化灰色低地土。"],
  "F3a5t4": ["表層グライ化灰色低地土の一種", ""],
  "F3a7": ["グライ化灰色低地土", "土壌表面から50-75cmに地下水によって生成するグライ層の上端が現れる灰色低地土。"],
  "F3a7p2": ["礫質グライ化灰色低地土", "土壌表面から60cm以内に礫層または岩盤が現れるグライ化灰色低地土。"],
  "F3a7t1": ["細粒質グライ化灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土であるグライ化灰色低地土。"],
  "F3a7t2": ["中粒質グライ化灰色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土であるグライ化灰色低地土。"],
  "F3a7t4": ["粗粒質グライ化灰色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土であるグライ化灰色低地土。"],
  "F3d3": ["下層黒ボク灰色低地土", "土壌表面から100cm以内に積算して厚さ25cm以上の「黒ボク特徴」を示す層をもつ灰色低地土。"],
  "F3d3t1": ["細粒質下層黒ボク灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である下層黒ボク灰色低地土。"],
  "F3d3y1": ["典型下層黒ボク灰色低地土", "典型的な下層黒ボク灰色低地土。"],
  "F3e6": ["泥炭質灰色低地土", "表層100cm以内に積算して25cm以上の「泥炭物質」からなる層がある灰色低地土。"],
  "F3e6p2": ["礫質泥炭質灰色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる泥炭質灰色低地土。"],
  "F3e6t1": ["細粒質泥炭質灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である泥炭質灰色低地土。"],
  "F3e6t3": ["中粗粒質泥炭質灰色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である泥炭質灰色低地土。"],
  "F3h3": ["腐植質灰色低地土", "表層に有機物を多く含む「腐植質表層」または「多腐植質表層」をもつ 灰色低地土。"],
  "F3h3p2": ["礫質腐植質灰色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる腐植質灰色低地土。"],
  "F3h3t1": ["細粒質腐植質灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質灰色低地土。"],
  "F3h3t3": ["中粗粒質腐植質灰色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質灰色低地土。"],
  "F3j7": ["硫酸酸性質灰色低地土", "硫化物を含む湖沼成・海成堆積物からなり、強い酸性を示す灰色低地土。"],
  "F3j7t1": ["細粒質硫酸酸性質灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である硫酸酸性質灰色低地土。"],
  "F3j7y1": ["典型硫酸酸性質灰色低地土", "典型的な硫酸酸性質灰色低地土。"],
  "F3z1": ["普通灰色低地土", "典型的な灰色低地土。"],
  "F3z1p2": ["礫質普通灰色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる普通灰色低地土。"],
  "F3z1t": ["普通灰色低地土の一種", ""],
  "F3z1t1": ["細粒質普通灰色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通灰色低地土。"],
  "F3z1t2": ["中粒質普通灰色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である普通灰色低地土。"],
  "F3z1t3": ["普通灰色低地土の一種", ""],
  "F3z1t4": ["粗粒質普通灰色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である普通灰色低地土。"],
  "F4": ["褐色低地土", "沖積低地の中では最も乾いた土地にある、黄褐色の次表層を持つ土壌。"],
  "F4a3": ["湿性褐色低地土", "土壌表面から75cm以内にグライ層よりも酸化的な「地下水湿性特徴」が現れる褐色低地土。"],
  "F4a3p2": ["礫質湿性褐色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる湿性褐色低地土。"],
  "F4a3t1": ["細粒質湿性褐色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である湿性褐色低地土。"],
  "F4a3t2": ["中粒質湿性褐色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である湿性褐色低地土。"],
  "F4a3t4": ["粗粒質湿性褐色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である湿性褐色低地土。"],
  "F4c1": ["水田化褐色低地土", "作土下方まで灰色化し斑鉄をもつ褐色低地土。"],
  "F4c1p2": ["礫質水田化褐色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる水田化褐色低地土。"],
  "F4c1t1": ["細粒質水田化褐色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田化褐色低地土。"],
  "F4c1t2": ["中粒質水田化褐色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である水田化褐色低地土。"],
  "F4c1t4": ["粗粒質水田化褐色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である水田化褐色低地土。"],
  "F4h3": ["腐植質褐色低地土", "表層に有機物を多く含む「腐植質表層」または「多腐植質表層」をもつ 褐色低地土。"],
  "F4h3p2": ["礫質腐植質褐色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる腐植質褐色低地土。"],
  "F4h3t1": ["細粒質腐植質褐色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質褐色低地土。"],
  "F4h3t2": ["中粒質腐植質褐色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である腐植質褐色低地土。"],
  "F4h3t4": ["粗粒質腐植質褐色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である腐植質褐色低地土。"],
  "F4z1": ["普通褐色低地土", "典型的な褐色低地土。"],
  "F4z1p2": ["礫質普通褐色低地土", "土壌表面から60cm以内に礫層または岩盤が現れる普通褐色低地土。"],
  "F4z1t1": ["細粒質普通褐色低地土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通褐色低地土。"],
  "F4z1t2": ["中粒質普通褐色低地土", "次表層の土性がシルト質壌土、壌土、細砂壌土である普通褐色低地土。"],
  "F4z1t4": ["粗粒質普通褐色低地土", "次表層の土性が粗砂壌土、壌質砂土、砂土である普通褐色低地土。"],
  "F5": ["未熟低地土", "未風化の土砂が堆積したままの土壌。"],
  "F5a3": ["湿性未熟低地土", "土壌表面から75cm以内にグライ層よりも酸化的な「地下水湿性特徴」が現れる未熟低地土。"],
  "F5a3p2": ["礫質湿性未熟低地土", "土壌表面から60cm以内に礫層または岩盤が現れる湿性未熟低地土。"],
  "F5a3y1": ["典型湿性未熟低地土", "典型的な湿性未熟低地土。"],
  "F5z1": ["普通未熟低地土", "典型的な未熟低地土。"],
  "F5z1p2": ["礫質普通未熟低地土", "土壌表面から60cm以内に礫層または岩盤が現れる普通未熟低地土。"],
  "F5z1t2": ["普通未熟低地土の一種", ""],
  "F5z1t4": ["普通未熟低地土の一種", ""],
  "F5z1y1": ["典型普通未熟低地土", "典型的な普通未熟低地土。"],
  "G1": ["粘土集積赤黄色土", "「粘土集積層」をもつ赤黄色土。"],
  "G1a3": ["湿性粘土集積赤黄色土", "土壌表面から50-75cmに「地下水湿性特徴」を示す層の上端が現れる粘土集積赤黄色土。"],
  "G1a3p1": ["岩盤質湿性粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる湿性粘土集積赤黄色土。"],
  "G1a3p2": ["礫質湿性粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる湿性粘土集積赤黄色土。"],
  "G1a3t1": ["細粒質湿性粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である湿性粘土集積赤黄色土。"],
  "G1a3t3": ["中粗粒質湿性粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である湿性粘土集積赤黄色土。"],
  "G1b1": ["疑似グライ化粘土集積赤黄色土", "土壌表面から75cm以内に「疑似グライ層」の上端が現れる粘土集積赤黄色土。"],
  "G1b1p1": ["岩盤質疑似グライ化粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる疑似グライ化粘土集積赤黄色土。"],
  "G1b1p2": ["礫質疑似グライ化粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる疑似グライ化粘土集積赤黄色土。"],
  "G1b1t1": ["細粒質疑似グライ化粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である疑似グライ化粘土集積赤黄色土。"],
  "G1b1t3": ["中粗粒質疑似グライ化粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である疑似グライ化粘土集積赤黄色土。"],
  "G1c1": ["水田化粘土集積赤黄色土", "「水田鉄集積層」をもつ粘土集積赤黄色土。"],
  "G1c1p1": ["岩盤質水田化粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる水田化粘土集積赤黄色土。"],
  "G1c1p2": ["礫質水田化粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる水田化粘土集積赤黄色土。"],
  "G1c1t1": ["細粒質水田化粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田化粘土集積赤黄色土。"],
  "G1c1t3": ["中粗粒質水田化粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である水田化粘土集積赤黄色土。"],
  "G1h3": ["腐植質粘土集積赤黄色土", "「腐植質表層」または「多腐植質表層」をもつ粘土集積赤黄色土。"],
  "G1h3p1": ["岩盤質腐植質粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる腐植質粘土集積赤黄色土。"],
  "G1h3p2": ["礫質腐植質粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる腐植質粘土集積赤黄色土。"],
  "G1h3t1": ["細粒質腐植質粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質粘土集積赤黄色土。"],
  "G1h3t3": ["中粗粒質腐植質粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質粘土集積赤黄色土。"],
  "G1j5": ["灰白化粘土集積赤黄色土", "「漂白層」をもつ粘土集積赤黄色土。"],
  "G1j5p1": ["岩盤質灰白化粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる灰白化粘土集積赤黄色土。"],
  "G1j5p2": ["礫質灰白化粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる灰白化粘土集積赤黄色土。"],
  "G1j5t1": ["細粒質灰白化粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である灰白化粘土集積赤黄色土。"],
  "G1j5t3": ["中粗粒質灰白化粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である灰白化粘土集積赤黄色土。"],
  "G1k1": ["赤色粘土集積赤黄色土", "湿状態の土色が赤色の「粘土集積層」をもつ粘土集積赤黄色土。"],
  "G1k1p1": ["岩盤質赤色粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる赤色粘土集積赤黄色土。"],
  "G1k1p2": ["礫質赤色粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる赤色粘土集積赤黄色土。"],
  "G1k1t1": ["細粒質赤色粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である赤色粘土集積赤黄色土。"],
  "G1k1t2": ["赤色粘土集積赤黄色土の一種", ""],
  "G1k1t3": ["中粗粒質赤色粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である赤色粘土集積赤黄色土。"],
  "G1k2t1": ["粘土集積赤黄色土の一種", ""],
  "G1z1": ["普通粘土集積赤黄色土", "典型的な粘土集積赤黄色土。"],
  "G1z1k2": ["普通粘土集積赤黄色土の一種", ""],
  "G1z1p1": ["岩盤質普通粘土集積赤黄色土", "土壌表面から60cm以内に岩盤が現れる普通粘土集積赤黄色土。"],
  "G1z1p2": ["礫質普通粘土集積赤黄色土", "土壌表面から60cm以内に礫層が現れる普通粘土集積赤黄色土。"],
  "G1z1t1": ["細粒質普通粘土集積赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通粘土集積赤黄色土。"],
  "G1z1t2": ["普通粘土集積赤黄色土の一種", ""],
  "G1z1t3": ["中粗粒質普通粘土集積赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である普通粘土集積赤黄色土。"],
  "G2": ["風化変質赤黄色土", "「粘土集積層」をもたず、「風化変質層」をもつ赤黄色土。"],
  "G2a3": ["湿性風化変質赤黄色土", "土壌表面から50-75cmに「地下水湿性特徴」を示す層の上端が現れる風化変質赤黄色土。"],
  "G2a3p1": ["岩盤質湿性風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる湿性風化変質赤黄色土。"],
  "G2a3p2": ["礫質湿性風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる湿性風化変質赤黄色土。"],
  "G2a3t1": ["細粒質湿性風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である湿性風化変質赤黄色土。"],
  "G2a3t3": ["中粗粒質湿性風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である湿性風化変質赤黄色土。"],
  "G2b1": ["疑似グライ化風化変質赤黄色土", "土壌表面から75㎝以内に「疑似グライ層」の上端が現れる風化変質赤黄色土。"],
  "G2b1p1": ["岩盤質疑似グライ化風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる疑似グライ化風化変質赤黄色土。"],
  "G2b1p2": ["礫質疑似グライ化風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる疑似グライ化風化変質赤黄色土。"],
  "G2b1t1": ["細粒質疑似グライ化風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である疑似グライ化風化変質赤黄色土。"],
  "G2b1t3": ["中粗粒質疑似グライ化風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である疑似グライ化風化変質赤黄色土。"],
  "G2c1": ["水田化風化変質赤黄色土", "「水田鉄集積層」をもつ風化変質赤黄色土。"],
  "G2c1p1": ["岩盤質水田化風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる水田化風化変質赤黄色土。"],
  "G2c1p2": ["礫質水田化風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる水田化風化変質赤黄色土。"],
  "G2c1t1": ["細粒質水田化風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田化風化変質赤黄色土。"],
  "G2c1t3": ["中粗粒質水田化風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である水田化風化変質赤黄色土。"],
  "G2h3": ["腐植質風化変質赤黄色土", "「腐植質表層」または「多腐植質表層」をもつ風化変質赤黄色土。"],
  "G2h3p1": ["岩盤質腐植質風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる腐植質風化変質赤黄色土。"],
  "G2h3p2": ["礫質腐植質風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる腐植質風化変質赤黄色土。"],
  "G2h3t1": ["細粒質腐植質風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質風化変質赤黄色土。"],
  "G2h3t3": ["中粗粒質腐植質風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質風化変質赤黄色土。"],
  "G2j5": ["灰白化風化変質赤黄色土", "「漂白層」をもつ風化変質赤黄色土。"],
  "G2j5p1": ["岩盤質灰白化風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる灰白化風化変質赤黄色土。"],
  "G2j5p2": ["礫質灰白化風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる灰白化風化変質赤黄色土。"],
  "G2j5t1": ["細粒質灰白化風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である灰白化風化変質赤黄色土。"],
  "G2j5t3": ["中粗粒質灰白化風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である灰白化風化変質赤黄色土。"],
  "G2k1": ["赤色風化変質赤黄色土", "湿状態の土色が赤色の「粘土集積層」をもつ風化変質赤黄色土。"],
  "G2k1p1": ["岩盤質赤色風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる赤色風化変質赤黄色土。"],
  "G2k1p2": ["礫質赤色風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる赤色風化変質赤黄色土。"],
  "G2k1t1": ["細粒質赤色風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である赤色風化変質赤黄色土。"],
  "G2k1t3": ["中粗粒質赤色風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である赤色風化変質赤黄色土。"],
  "G2k2": ["ばん土質風化変質赤黄色土", "土壌表面から50cm以内にリン酸吸収係数1000mg P2O5/100g以上（リン酸保持量60％以上）の層が25cm以上ある風化変質赤黄色土。"],
  "G2k2p1": ["岩盤質ばん土質風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れるばんど質風化変質赤黄色土。"],
  "G2k2p2": ["礫質ばん土質風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れるばんど質風化変質赤黄色土。"],
  "G2k2t1": ["細粒質ばん土質風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土であるばんど質風化変質赤黄色土。"],
  "G2k2t2": ["ばん土質風化変質赤黄色土の一種", ""],
  "G2k2t3": ["中粗粒質ばん土質風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土であるばんど質風化変質赤黄色土。"],
  "G2z1": ["普通風化変質赤黄色土", "典型的な風化変質赤黄色土。"],
  "G2z1p1": ["岩盤質普通風化変質赤黄色土", "土壌表面から60cm以内に岩盤が現れる普通風化変質赤黄色土。"],
  "G2z1p2": ["礫質普通風化変質赤黄色土", "土壌表面から60cm以内に礫層が現れる普通風化変質赤黄色土。"],
  "G2z1t1": ["細粒質普通風化変質赤黄色土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通風化変質赤黄色土。"],
  "G2z1t2": ["普通風化変質赤黄色土の一種", ""],
  "G2z1t3": ["中粗粒質普通風化変質赤黄色土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である普通風化変質赤黄色土。"],
  "H1": ["停滞水グライ土", "土壌表面から50cm以内に「グライ層」の上端が現れ、かつその層の下端が土壌表面から50cm以深まで及んでいるか、または岩盤まで及んでいる土壌。"],
  "H1c3": ["水田型停滞水グライ土", "「水田逆グライ層」をもつ停滞水グライ土。"],
  "H1c3p1": ["岩盤質水田型停滞水グライ土", "土壌表面から60cm以内に岩盤が現れる水田型停滞水グライ土。"],
  "H1c3p2": ["礫質水田型停滞水グライ土", "土壌表面から60cm以内に礫層が現れる水田型停滞水グライ土。"],
  "H1c3t1": ["細粒質水田型停滞水グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田型停滞水グライ土。"],
  "H1c3t3": ["中粗粒質水田型停滞水グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である水田型停滞水グライ土。"],
  "H1e5": ["表層泥炭質停滞水グライ土", "土壌表面から25cm以内に、積算して10cm以上の「泥炭物質」からなる層をもつ停滞水グライ土。"],
  "H1e5p1": ["岩盤質表層泥炭質停滞水グライ土", "土壌表面から60cm以内に岩盤が現れる表層泥炭質停滞水グライ土。"],
  "H1e5p2": ["礫質表層泥炭質停滞水グライ土", "土壌表面から60cm以内に礫層が現れる表層泥炭質停滞水グライ土。"],
  "H1e5t1": ["細粒質表層泥炭質停滞水グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である表層泥炭質停滞水グライ土。"],
  "H1e5t3": ["中粗粒質表層泥炭質停滞水グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である表層泥炭質停滞水グライ土。"],
  "H1h3": ["腐植質停滞水グライ土", "「腐植質表層」または「多腐植質表層」をもつ停滞水グライ土。"],
  "H1h3p1": ["岩盤質腐植質停滞水グライ土", "土壌表面から60cm以内に岩盤が現れる腐植質停滞水グライ土。"],
  "H1h3p2": ["礫質腐植質停滞水グライ土", "土壌表面から60cm以内に礫層が現れる腐植質停滞水グライ土。"],
  "H1h3t1": ["細粒質腐植質停滞水グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質停滞水グライ土。"],
  "H1h3t3": ["中粗粒質腐植質停滞水グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質停滞水グライ土。"],
  "H1z1": ["普通停滞水グライ土", "典型的な停滞水グライ土。"],
  "H1z1p1": ["岩盤質普通停滞水グライ土", "土壌表面から60cm以内に岩盤が現れる普通停滞水グライ土。"],
  "H1z1p2": ["礫質普通停滞水グライ土", "土壌表面から60cm以内に礫層が現れる普通停滞水グライ土。"],
  "H1z1t1": ["細粒質普通停滞水グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通停滞水グライ土。"],
  "H1z1t3": ["中粗粒質普通停滞水グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である普通停滞水グライ土。"],
  "H2": ["疑似グライ土", "台地に分布し、緻密な下層をもつ排水の良くない土壌。"],
  "H2a4": ["地下水型疑似グライ土", "土壌表面から50-75cmに、厚さ10cm以上の「地下水湿性特徴」または「グライ特徴」を示す層の上端が現れる疑似グライ土。"],
  "H2a4p1": ["岩盤質地下水型疑似グライ土", "土壌表面から60cm以内に岩盤が現れる地下水型疑似グライ土。"],
  "H2a4p2": ["礫質地下水型疑似グライ土", "土壌表面から60cm以内に礫層が現れる地下水型疑似グライ土。"],
  "H2a4t1": ["細粒質地下水型疑似グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である地下水型疑似グライ土。"],
  "H2a4t3": ["中粗粒質地下水型疑似グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である地下水型疑似グライ土。"],
  "H2b4": ["褐色疑似グライ土", "土壌表面から30cm以内に「疑似グライ層」または厚さ10cm以上の「地下水湿性特徴」を示す層がみられない疑似グライ土。"],
  "H2b4p1": ["岩盤質褐色疑似グライ土", "土壌表面から60cm以内に岩盤が現れる褐色疑似グライ土。"],
  "H2b4p2": ["礫質褐色疑似グライ土", "土壌表面から60cm以内に礫層が現れる褐色疑似グライ土。"],
  "H2b4t1": ["細粒質褐色疑似グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である褐色疑似グライ土。"],
  "H2b4t3": ["中粗粒質褐色疑似グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である褐色疑似グライ土。"],
  "H2c1": ["水田化疑似グライ土", "「水田鉄集積層」をもつ疑似グライ土。"],
  "H2c1p1": ["岩盤質水田化疑似グライ土", "土壌表面から60cm以内に岩盤が現れる水田化疑似グライ土。"],
  "H2c1p2": ["礫質水田化疑似グライ土", "土壌表面から60cm以内に礫層が現れる水田化疑似グライ土。"],
  "H2c1t1": ["細粒質水田化疑似グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田化疑似グライ土。"],
  "H2c1t2": ["水田化疑似グライ土の一種", ""],
  "H2c1t3": ["中粗粒質水田化疑似グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である水田化疑似グライ土。"],
  "H2c1tp2": ["水田化疑似グライ土の一種", ""],
  "H2h3": ["腐植質疑似グライ土", "「腐植質表層」または「多腐植質表層」をもつ疑似グライ土。"],
  "H2h3p1": ["岩盤質腐植質疑似グライ土", "土壌表面から60cm以内に岩盤が現れる腐植質疑似グライ土。"],
  "H2h3p2": ["礫質腐植質疑似グライ土", "土壌表面から60cm以内に礫層が現れる腐植質疑似グライ土。"],
  "H2h3t1": ["細粒質腐植質疑似グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質疑似グライ土。"],
  "H2h3t3": ["中粗粒質腐植質疑似グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質疑似グライ土。"],
  "H2z1": ["普通疑似グライ土", "典型的な疑似グライ土。"],
  "H2z1p1": ["岩盤質普通疑似グライ土", "土壌表面から60cm以内に岩盤が現れる普通疑似グライ土。"],
  "H2z1p2": ["礫質普通疑似グライ土", "土壌表面から60cm以内に礫層が現れる普通疑似グライ土。"],
  "H2z1t1": ["細粒質普通疑似グライ土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通疑似グライ土。"],
  "H2z1t2": ["普通疑似グライ土の一種", ""],
  "H2z1t3": ["中粗粒質普通疑似グライ土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である普通疑似グライ土。"],
  "I1": ["褐色森林土", "火山灰の影響の少ない山地・丘陵地に分布する褐色あるいは黄褐色の「風化変質層」をもつ土壌。"],
  "I1a3": ["湿性褐色森林土", "土壌表面から75cm以内に「表面水湿性特徴」または「地下水湿性特徴」を示す層の上端が現れる褐色森林土。"],
  "I1a3p1": ["岩盤質湿性褐色森林土", "土壌表面から60cm以内に岩盤が現れる湿性褐色森林土。"],
  "I1a3p2": ["礫質湿性褐色森林土", "土壌表面から60cm以内に礫層が現れる湿性褐色森林土。"],
  "I1a3t1": ["細粒質湿性褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である湿性褐色森林土。"],
  "I1a3t3": ["中粗粒質湿性褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である湿性褐色森林土。"],
  "I1c1": ["水田化褐色森林土", "「水田鉄集積層」をもつ褐色森林土。"],
  "I1c1p1": ["岩盤質水田化褐色森林土", "土壌表面から60cm以内に岩盤が現れる水田化褐色森林土。"],
  "I1c1p2": ["礫質水田化褐色森林土", "土壌表面から60cm以内に礫層が現れる水田化褐色森林土。"],
  "I1c1t1": ["細粒質水田化褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である水田化褐色森林土。"],
  "I1c1t3": ["中粗粒質水田化褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である水田化褐色森林土。"],
  "I1d4": ["下層赤黄色褐色森林土", "土壌表面から75cm以内に「赤黄色特徴」を示す「風化変質層」または「粘土集積層」をもつ褐色森林土。"],
  "I1d4p1": ["岩盤質下層赤黄色褐色森林土", "土壌表面から60cm以内に岩盤が現れる下層赤黄色褐色森林土。"],
  "I1d4p2": ["礫質下層赤黄色褐色森林土", "土壌表面から60cm以内に礫層が現れる下層赤黄色褐色森林土。"],
  "I1d4t1": ["細粒質下層赤黄色褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である下層赤黄色褐色森林土。"],
  "I1d4t3": ["中粗粒質下層赤黄色褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である下層赤黄色褐色森林土。"],
  "I1h3": ["腐植質褐色森林土", "「腐植質表層」または「多腐植質表層」をもつ褐色森林土。"],
  "I1h3p1": ["岩盤質腐植質褐色森林土", "土壌表面から60cm以内に岩盤が現れる腐植質褐色森林土。"],
  "I1h3p2": ["礫質腐植質褐色森林土", "土壌表面から60cm以内に礫層が現れる腐植質褐色森林土。"],
  "I1h3t1": ["細粒質腐植質褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である腐植質褐色森林土。"],
  "I1h3t3": ["中粗粒質腐植質褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である腐植質褐色森林土。"],
  "I1i1": ["台地褐色森林土", "洪積世堆積物（更新世の河成、海成、湖沼成の古い未固結堆積物） を母材とする褐色森林土。"],
  "I1i1p1": ["岩盤質台地褐色森林土", "土壌表面から60cm以内に岩盤が現れる台地褐色森林土。"],
  "I1i1p2": ["礫質台地褐色森林土", "土壌表面から60cm以内に礫層が現れる台地褐色森林土。"],
  "I1i1t1": ["細粒質台地褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である台地褐色森林土。"],
  "I1i1t2": ["台地褐色森林土の一種", ""],
  "I1i1t3": ["中粗粒質台地褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である台地褐色森林土。"],
  "I1i2t1": ["褐色森林土の一種", ""],
  "I1j8": ["塩基型褐色森林土", "次表層の全ての亜層位でpH(H2O)が6.5以上である褐色森林土。"],
  "I1j8p1": ["岩盤質塩基型褐色森林土", "土壌表面から60cm以内に岩盤が現れる塩基型褐色森林土。"],
  "I1j8p2": ["礫質塩基型褐色森林土", "土壌表面から60cm以内に礫層が現れる塩基型褐色森林土。"],
  "I1j8t1": ["細粒質塩基型褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である塩基型褐色森林土。"],
  "I1j8t3": ["中粗粒質塩基型褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である塩基型褐色森林土。"],
  "I1k2": ["ばん土質褐色森林土", "土壌表面から50cm以内にリン酸吸収係数1000mg P2O5/100g以上（リン酸保持量60％以上）の層が25cm以上ある褐色森林土。"],
  "I1k2p1": ["岩盤質ばん土質褐色森林土", "土壌表面から60cm以内に岩盤が現れるばん土質褐色森林土。"],
  "I1k2p2": ["礫質ばん土質褐色森林土", "土壌表面から60cm以内に礫層が現れるばん土質褐色森林土。"],
  "I1k2t1": ["細粒質ばん土質褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土であるばん土質褐色森林土。"],
  "I1k2t3": ["中粗粒質ばん土質褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土であるばん土質褐色森林土。"],
  "I1k3": ["ポドゾル化褐色森林土", "「ポドゾル性集積層（断面形態）」または「ポドゾル性集積層（分析値）」をもつ褐色森林土。"],
  "I1k3p1": ["岩盤質ポドゾル化褐色森林土", "土壌表面から60cm以内に岩盤が現れるポドゾル化褐色森林土。"],
  "I1k3p2": ["礫質ポドゾル化褐色森林土", "土壌表面から60cm以内に礫層が現れるポドゾル化褐色森林土。"],
  "I1k3t1": ["細粒質ポドゾル化褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土であるポドゾル化褐色森林土。"],
  "I1k3t3": ["中粗粒質ポドゾル化褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土であるポドゾル化褐色森林土。"],
  "I1z1": ["普通褐色森林土", "典型的な褐色森林土。"],
  "I1z1p1": ["岩盤質普通褐色森林土", "土壌表面から60cm以内に岩盤が現れる普通褐色森林土。"],
  "I1z1p2": ["礫質普通褐色森林土", "土壌表面から60cm以内に礫層が現れる普通褐色森林土。"],
  "I1z1t1": ["細粒質普通褐色森林土", "次表層の土性が重埴土、シルト質埴土、軽埴土、砂質埴土、シルト質埴壌土、埴壌土、砂質埴壌土である普通褐色森林土。"],
  "I1z1t3": ["中粗粒質普通褐色森林土", "次表層の土性が粗砂壌土、壌質砂土、砂土、シルト質壌土、壌土、細砂壌土である普通褐色森林土。"],
  "J1": ["火山放出物未熟土", "層位分化が未発達で、土壌表面から50cm以内に積算で厚さ25cm以上の「火山放出物」からなる層をもつ土壌。"],
  "J1a3": ["湿性火山放出物未熟土", "土壌表面から50cm以内に「グライ特徴」または「地下水湿性特徴」を示す層の上端が現れる火山放出物未熟土。"],
  "J1a3w3": ["下層泥炭湿性火山放出物未熟土", "土壌表面から75cm以内に積算して25cm以上の「泥炭物質」からなる層をもつ湿性火山放出物未熟土。"],
  "J1a3w4": ["下層低地湿性火山放出物未熟土", "土壌表面から75cm以内に積算して25cm以上の「沖積堆積物」をもつ層をもつ湿性火山放出物未熟土。"],
  "J1a3y1": ["典型湿性火山放出物未熟土", "典型的な湿性火山放出物未熟土。"],
  "J1z1": ["普通火山放出物未熟土", "典型的な火山放出物未熟土。"],
  "J1z1p3": ["盤層型普通火山放出物未熟土", "土壌表面から60cm以内に盤層が現れる普通火山放出物未熟土。"],
  "J1z1w4": ["下層低地普通火山放出物未熟土", "土壌表面から75cm以内に積算して25cm以上の「沖積堆積物」からなる層をもつ普通火山放出物未熟土。"],
  "J1z1w5": ["下層黒ボク普通火山放出物未熟土", "土壌表面から100cm以内に積算して25cm以上の「黒ボク特徴」をもつ層をもつ普通火山放出物未熟土。"],
  "J1z1y1": ["典型普通火山放出物未熟土", "典型的な普通火山放出物未熟土。"],
  "J2": ["砂質未熟土", "土壌表面から100cmまで、石礫含量が断面割合で35％未満で、かつ、土性は壌質砂土、またはそれより粗い。ただし、石灰質、サンゴ質の砂も含む。"],
  "J2a3": ["湿性砂質未熟土", "土壌表面から75cm以内に「グライ特徴」または「地下水湿性特徴」を示す層の上端が現れる砂質未熟土。"],
  "J2a3y1": ["典型湿性砂質未熟土", "すべての湿性砂質未熟土。"],
  "J2f2": ["石灰質砂質未熟土", "土壌表面から20-50㎝の間で、炭酸カルシウム換算で2.0％以上の炭酸塩を含む砂質未熟土。"],
  "J2f2y1": ["典型石灰質砂質未熟土", "すべての石灰質砂質未熟土。"],
  "J2z1": ["普通砂質未熟土", "典型的な砂質未熟土。"],
  "J2z1y1": ["典型普通砂質未熟土", "すべての普通砂質未熟土。"],
  "J3": ["固結岩屑土", "土壌表面から30cm以内に固結した岩盤が現れる土壌。"],
  "J3a3": ["湿性固結岩屑土", "土壌表面から岩盤までの間に「グライ特徴」または「地下水湿性特徴」を示す層の上端が現れる固結岩屑土。"],
  "J3a3y1": ["典型湿性固結岩屑土", "すべての湿性固結岩屑土。"],
  "J3f2": ["石灰質固結岩屑土", "岩盤上の層が炭酸カルシウム換算で2.0％以上の炭酸塩を含む固結岩屑土。"],
  "J3f2x3": ["暗色表層石灰質固結岩屑土", "「富塩基暗色表層」をもち、かつ、岩盤上の層が炭酸カルシウム換算で2.0％以上の炭酸塩を含む固結岩屑土。"],
  "J3f2y1": ["典型石灰質固結岩屑土", "典型的な石灰質固結岩屑土。"],
  "J3z1": ["普通固結岩屑土", "典型的な固結岩屑土。"],
  "J3z1y1": ["典型普通固結岩屑土", "すべての普通固結岩屑土。"],
  "J4": ["陸成未熟土", "層位分化が進んでおらず、母材そのものの物理的・化学的性質の影響を強く受けている土壌。"],
  "J4f1": ["花崗岩型陸成未熟土", "花崗岩に由来する陸成未熟土。"],
  "J4f1y1": ["典型花崗岩型陸成未熟土", "すべての花崗岩型陸成未熟土。"],
  "J4f2": ["石灰質陸成未熟土", "土壌表面から20-50cmの間で炭酸カルシウム換算で2.0％以上の炭酸塩を含む陸成未熟土。"],
  "J4f2x3": ["暗色表層石灰質陸成未熟土", "「富塩基暗色表層」をもち、かつ、土壌表面から20-50cmの間で炭酸カルシウム換算で2.0％以上の炭酸塩を含む陸成未熟土。"],
  "J4f2y1": ["典型石灰質陸成未熟土", "典型的な石灰質陸成未熟土。"],
  "J4f3": ["泥灰岩質陸成未熟土", "泥灰岩に由来し、土壌表面から100cm、または基岩までの全ての層位で粘土含量が30％以上である陸成未熟土。"],
  "J4f3x1": ["湿性泥灰岩質陸成未熟土", "土壌表面から75㎝以内に「グライ特徴」または「地下水湿性特徴」を示す層の上端が現れる泥灰岩質陸成未熟土。 "],
  "J4f3y1": ["典型泥灰岩質陸成未熟土", "典型的な泥灰岩質陸成未熟土。"],
  "J4f4": ["軟岩型陸成未熟土", "礫または岩盤がコテ、シャベルで削れる陸成未熟土。"],
  "J4f4y1": ["典型軟岩型陸成未熟土", "すべての軟岩型陸成未熟土。"],
  "J4z1": ["普通陸成未熟土", "典型的な陸成未熟土。 "],
  "J4z1y1": ["典型普通陸成未熟土", "すべての普通陸成未熟土。"],
  "Z1": ["岩石地", "岩石地。"],
  "Z4": ["その他", "不明。"]
};
