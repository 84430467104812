import React from "react";
import { Input, FormGroup, Label, Col } from "reactstrap";

const CommonConfigList = ({ onchange, common, keys, label }) => (
  <FormGroup row>
    <Label for={keys} md="5">
      {label}
    </Label>
    <Col md="3">
      <Input
        type="number"
        step="1"
        name={keys}
        id={keys}
        value={common[keys]}
        onChange={(e) => onchange(e, common)}
      />
    </Col>
  </FormGroup>
);

export default CommonConfigList;
