import join from "url-join";

/** Root namespace for API server */
const API = {
  BASE_URL: process.env.REACT_APP_API_URL,
  PATH: {
    inputs: "/inputs",
    inputNew: "/inputs/new",
    inputDup: "/inputs/dup",
    landuse: "/inputs/:input_id/land_use",
    weather: "/inputs/:input_id/weather_station",
    irrigate: "/inputs/:input_id/irrigation_datum",
    geolocation: "/inputs/:input_id/geo_locations",
    soillayer: "/inputs/:input_id/soil_layers",
    soildata: "/inputs/:input_id/soil_data",
    soildataedit: "/inputs/:input_id/soil_data/:id",
    croprotation: "/inputs/:input_id/crop_rotations",
    crop: "/inputs/:input_id/crops",
    cropcommons: "/inputs/:input_id/crop_commons",
    cropdelete: "/inputs/:input_id/crops/:id",
    fertilization: "/inputs/:input_id/fertilizations",
    fertilizationupdate: "/inputs/:input_id/fertilizations/:id",
    fertilizationdelete: "/inputs/:input_id/fertilizations/:id",
    cultivation: "/inputs/:input_id/cultivations",
    cultivationdelete: "/inputs/:input_id/cultivations/:id",
    confirm: "/inputs/:input_id/calculate_dates",
    result: "/inputs/:input_id/calculations",
    calculations: "/calculations",
    calculation: "/calculations/:calculation_id",
    resultdelete: "/calculations/:id",
    vis: "/inputs/:input_id/timelines",
    timelinerange: "/outputs/:output_id/segmental_results/time_line_range",
    timeLine: "/outputs/:output_id/segmental_results/time_line?fields=:query",
    nearestSites: "/nearest_sites",
  },
  REP: {
    inputid: ":input_id",
    paramid: ":id",
    soildatumid: ":soil_datum_id",
  },
  URL: {}, // created in below
};

const DL = {
  BASE_URL: process.env.REACT_APP_API_DLURL,
};

const VISUAL = {
  GRAPH: process.env.REACT_APP_VISUAL_MODE,
};
const CLIENT = {
  PATH: {
    root: "/",
    landuse: "/land_use",
    weather: "/weather/:input_id",
    segmentpartition: "/segment_partition/:input_id",
    segmentparamater: "/segment_parameter/:input_id",
    segmconfig: "/segment_parameter/:input_id/edit/:id",
    croprotation: "/crop_rotations/:input_id",
    crop: "/crops/:input_id",
    commonconfig: "/crops/:input_id/edit",
    cropcommon: "/crops/:input_id/edit",
    cropconfig: "/crops/:input_id/edit/:id",
    fertilization: "/fertilizations/:input_id",
    fertconfig: "/fertilizations/:input_id/edit/:id",
    cultivation: "/cultivations/:input_id",
    cultivationconfig: "/cultivations/:input_id/edit/:id",
    confirm: "/confirm/:input_id",
    result: "/result",
    graph: "/calculation/:calculation_id/graph/:output_id",
    condition: "/result/:input_id/condition/:id",
    co2Toleachm: "/co2_to_leachm.html",
    co2:
      process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production"
        ? "/co2_to_leachm.html"
        : "http://soilco2.dc.affrc.go.jp/calc.php",
    soilinvetory:
      "https://soil-inventory.dc.affrc.go.jp/old_explain/:scode/:scode.html",
  },
  URL: {},
};

console.log(
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production"
    ? "/co2_to_leachm.html"
    : "http://soilco2.dc.affrc.go.jp/calc.php"
);

const GRAPHAPI = {
  BASE_URL: process.env.REACT_APP_API_URL,
  PATH: {
    fieldoptions: "/outputs/:output_id/segmental_results/field_options",
    segmentoptions: "/outputs/:output_id/segmental_results/segment_options",
    verticalprofile: "/outputs/:output_id/segmental_results/vertical_profile",
  },
  REP: {
    calculationid: ":output_id",
    outputid: ":output_id",
    paramid: ":id",
    soildatumid: ":soil_datum_id",
  },
  URL: {}, // created in below
};

// build full URL to access each endpoint of API
Object.keys(API.PATH).forEach((key) => {
  API.URL[key] = join(API.BASE_URL, API.PATH[key]);
});

Object.keys(CLIENT.PATH).forEach((key) => {
  CLIENT.URL[key] = CLIENT.PATH[key];
});

// build full URL to access each endpoint of API
Object.keys(GRAPHAPI.PATH).forEach((key) => {
  GRAPHAPI.URL[key] = join(GRAPHAPI.BASE_URL, GRAPHAPI.PATH[key]);
});

// XXX: This could be conciderable.
// eslint-disable-next-line import/prefer-default-export
export { API, CLIENT, DL, VISUAL, GRAPHAPI };
