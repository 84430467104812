var dashArray = ["solid", "dot", "dash"];
var marker = [0, 1, 2];

var timeY = {
  x: [],
  y: [],
  name: "",
  type: "scatter",
  mode: "lines+markers",
};
var timeY2 = {
  x: [],
  y: [],
  name: "",
  type: "scatter",
  mode: "lines+markers",
  yaxis: "y2",
  line: {
    dash: "",
    color: "blue",
    width: 4,
  },
};

var depthY = {
  x: [],
  y: [],
  name: "",
  type: "scatter",
  mode: "lines+markers",
  marker: {
    color: "red",
    size: 8,
  },
};
var depthY2 = {
  x: [],
  y: [],
  name: "",
  type: "scatter",
  mode: "lines+markers",
  xaxis: "x2",
  yaxis: "y2",
};
export default {
  timeY,
  timeY2,
  depthY,
  depthY2,
  dashArray,
  marker,
};
