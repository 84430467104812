import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import i18next from "i18next";

class ShowModal extends Component {
  render() {
    const headers = [
      "施肥年月日",
      `${i18next.t("fertilization.showEdit.dep")} (${i18next.t(
        "extension.mm"
      )})`,
      `${i18next.t("fertilization.showEdit.fer")} (${i18next.t(
        "extension.kgN"
      )})`,
      `${i18next.t("fertilization.showEdit.nh4N")} (${i18next.t(
        "extension.kgN"
      )})`,
      `${i18next.t("fertilization.showEdit.no3N")} (${i18next.t(
        "extension.kgN"
      )})`,
      `${i18next.t("fertilization.showEdit.residueN")} (${i18next.t(
        "extension.kgN"
      )})`,
      `${i18next.t("fertilization.showEdit.residueC")} (${i18next.t(
        "extension.kgC"
      )})`,
      `${i18next.t("fertilization.showEdit.manureN")} (${i18next.t(
        "extension.kgN"
      )})`,
      `${i18next.t("fertilization.showEdit.manureC")} (${i18next.t(
        "extension.kgC"
      )})`,
    ];
    const keys = [
      "var_dfer",
      "depth",
      "var_fer10",
      "var_fer1",
      "var_fer2",
      "var_fer3",
      "var_fer6",
      "var_fer5",
      "var_fer8",
    ];
    const { isOpen, OpenModal, crop } = this.props;
    return (
      <Modal isOpen={isOpen} size="lg" toggle={(e) => OpenModal(isOpen, crop)}>
        <ModalHeader toggle={(e) => OpenModal(isOpen, crop)}>
          {crop.crop.crop_detail}
          <br />
          {crop.fert_name}
        </ModalHeader>
        <ModalBody>
          <Table bordered size="sm">
            <tbody>
              {(() => {
                return headers.map((header, index) => (
                  <tr key={header}>
                    <th>{index + 1}</th>
                    <th>{header}</th>
                    {(() => {
                      return <td>{crop[keys[index]]}</td>;
                    })()}
                  </tr>
                ));
              })()}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

export default ShowModal;
