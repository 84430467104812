import React, { Component } from "react";
// import { Row, Col, Button } from 'reactstrap';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

class ScrollTop extends Component {
  componentDidMount() {
    const { watchCurrentPosition } = this.props;
    window.addEventListener(
      "scroll",
      (event) => watchCurrentPosition(event),
      true
    );
  }

  render() {
    const { scrollTop } = this.props;
    return (
      <Button
        color="primary"
        className="scroll-top"
        id="js-button"
        onClick={(e) => scrollTop("js-button", "300", e)}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Button>
    );
  }
}

export default ScrollTop;
