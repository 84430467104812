import { CLIENT } from "../../consts_path";

const AlertError = (i18) => {
  const result = window.confirm(i18);
  if (result) {
    window.location = CLIENT.URL.co2Toleachm;
  } else {
    return "セッションの有効期限が切れたため、データがありません。地点選択からやり直してください。";
  }
};

export { AlertError };
