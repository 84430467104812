import React from "react";
import { Input, Col, Label, FormGroup } from "reactstrap";
// import { FaQuestionCircle } from 'react-icons/fa';
import Tips from "../../Tips/index";

const TextBox = ({ onChange, crop, labelFor, step, label, comment }) => (
  <FormGroup row>
    <Label for={labelFor} sm={3} xs={3}>
      {label}
    </Label>
    <Col sm={3} xs={3}>
      <Input
        type="number"
        step={step}
        name={labelFor}
        id={labelFor}
        onChange={(e) => onChange(e, crop)}
        value={crop[labelFor]}
      />
    </Col>
    {/*
      comment &&
        <span title={comment} className="info"><FaQuestionCircle/></span>
    */}
    {comment && <Tips id={labelFor.split("_")[1]} comment={comment} />}
  </FormGroup>
);

export default TextBox;
