import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Navbar } from "reactstrap";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const style = {
  backgroundColor: "#F8F8F8",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
  zIndex: "1050",
};
/**
 * 画面の次へ、戻るなどのボタンコントールコンポーネント
 * assign: ボタンの文字列、URLなどを持つオブジェクト
 * col: グリッドサイズの数値
 * serverSendFunction: サーバにPUT、POSTなどするための関数
 * initialize: inputの初期化関数
 * flag_n/p: ボタンのdisabledのフラグ
 * @returns {undefined}
 */

const PageController = ({
  assign,
  serverSendFunction,
  initialize,
  flagN,
  flagP,
}) => (
  <Navbar id="pc" color="light" light expand="md" style={style}>
    <Container>
      <div id="pageController">
        <Row>
          <Col xs="auto" style={assign.displayN}>
            {serverSendFunction ? (
              <Button
                color="success"
                disabled={flagN}
                onClick={serverSendFunction}
              >
                <FaArrowCircleRight className="icon-page-controller" />
                {assign.value[1]}
              </Button>
            ) : (
              <Link to={assign.url[1]} className={flagN ? "disabled-link" : ""}>
                <Button color="success" disabled={flagN}>
                  <FaArrowCircleRight className="icon-page-controller" />
                  {assign.value[1]}
                </Button>
              </Link>
            )}
          </Col>
          <Col xs="auto" style={assign.display}>
            {initialize ? (
              <Button color="secondary" disabled={flagP} onClick={initialize}>
                <FaArrowCircleLeft className="icon-page-controller" />
                {assign.value[0]}
              </Button>
            ) : (
              <Link to={assign.url[0]}>
                <Button color="secondary" disabled={flagP}>
                  <FaArrowCircleLeft className="icon-page-controller" />
                  {assign.value[0]}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  </Navbar>
);

export default PageController;
