import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "reactstrap";
import BreadCrumb from "../../BreadCrumb/index";
import PageController from "../../PageController/index";
import * as common from "../../Common/common";
import {
  FetchPartitionData,
  onChangePartion,
} from "../../../Actions/partition";
import { fetchGeoLocation } from "../../../Actions/landUse";
import PartitionTable from "./partition_table";
import DataInfo from "../../LandUse/dataInfoChild";
import { API, CLIENT } from "../../../consts_path";
import SelectModelType from "./select_model_type";
import BbcModelType from "./select_bbc_type";
import { getToken } from "../../../Actions/common";
import { Spinner } from "../../iconSpinner";

class Partition extends Component {
  componentDidMount() {
    const { input, PartitionData, GeoLocationData, getToken } = this.props;
    const urlForGeo = API.URL.geolocation.replace(":input_id", input.id);
    GeoLocationData(urlForGeo);
    const getForSoilLayer = API.URL.soillayer.replace(":input_id", input.id);
    PartitionData(getForSoilLayer);
    getToken();
  }

  /**
   * 分割数をapiに送信する。
   * 送信パラメータ
   * input[soil_layer_segm_nums], [1,1,1,1,1,1]
   * @returns {no content: 204}
   */
  update = (event, soillayer) => {
    event.preventDefault();
    const form = new FormData();
    const inputs = Object.keys(soillayer)[0];
    const { input, history, csrfToken } = this.props;
    // const soil_layer_segm_nums = Object.keys(soillayer.input)[1];
    Object.keys(soillayer.input).map((key) =>
      form.append(`${inputs}[${key}]`, soillayer.input[key])
    );
    // 更新用のエンドポイントへのパス
    const updateForSegPar = API.URL.soillayer.replace(":input_id", input.id);
    fetch(updateForSegPar, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        //alert("地点選択からやり直してください。");
        //throw Error((window.location = CLIENT.URL.root));
      } else {
        const segmentparamater = CLIENT.URL.segmentparamater.replace(
          API.REP.inputid,
          input.id
        );
        history.push(segmentparamater);
      }
    });
  };

  render() {
    const assign = common.pageController;
    const { input, soillayer, onChange, isLoading } = this.props;
    assign.display = { display: "block" };
    assign.url = [
      CLIENT.URL.weather.replace(API.REP.inputid, input.id),
      CLIENT.URL.segmentparamater.replace(API.REP.inputid, input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb landuse={0} irrigate={0} partition={1} />
        <Form name="partiotion" id="mform">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <DataInfo />
              {soillayer && (
                <Row>
                  <Col xs={12} md={5}>
                    <SelectModelType
                      onChangeRadio={onChange}
                      soillayer={soillayer}
                    />
                    <BbcModelType
                      onChangeRadio={onChange}
                      soillayer={soillayer}
                    />
                  </Col>
                  <Col xs={12} md={7}>
                    <h3>各層のセグメント分割条件設定</h3>
                    <PartitionTable soilLayer={soillayer} onChange={onChange} />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form>
        {!isLoading && soillayer && (
          <PageController
            assign={assign}
            serverSendFunction={(e) => this.update(e, soillayer)}
            flagN={soillayer.is_invalid && soillayer.is_invalid.includes(true)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  soillayer: state.soilLayer,
  geoLocation: state.geoLocation,
  isLoading: state.load,
  csrfToken: state.token,
  input: state.input,
});

const mapDispatchToProps = (dispatch) => ({
  PartitionData: (url) => dispatch(FetchPartitionData(url)),
  GeoLocationData: (url) => dispatch(fetchGeoLocation(url)),
  onChange: (soillayer, target, i, variable) =>
    dispatch(onChangePartion(soillayer, target, i, variable)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partition);
