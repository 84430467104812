import React from "react";
import i18next from "i18next";
import { Col, FormGroup, Label, Input } from "reactstrap";

const CheckboxGroup = ({ crop, onClickCheckbox }) => (
  <div>
    <FormGroup row>
      <Col sm={5} xs={5} style={{ margin: "10px" }}>
        <Label for="copy_crops">
          <Input
            type="checkbox"
            name="copy_crops"
            id="copy_crops"
            onClick={(e) => onClickCheckbox(e, crop)}
          />
          {i18next.t("crops.showEdit.ch")}
        </Label>
      </Col>
    </FormGroup>
  </div>
);

export default CheckboxGroup;
