import React from "react";
import i18next from "i18next";
import { Col, FormGroup, Label, Input } from "reactstrap";

const RadioGroup = ({ onChangeRadio, crop }) => (
  <div>
    <FormGroup row>
      <Label sm={4} xs={4}>{`${i18next.t("crops.showEdit.igrowth")}`}</Label>
      <Col sm={8} xs={8}>
        <Label for="var_igrowth1" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_igrowth"
            id="var_igrowth1"
            data-id={1}
            checked={crop.var_igrowth === 1}
          />
          成長なし
        </Label>
        <Label for="var_igrowth2" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_igrowth"
            id="var_igrowth2"
            data-id={2}
            checked={crop.var_igrowth === 2}
          />
          成長あり
        </Label>
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label sm={4} xs={4}>{`${i18next.t("crops.showEdit.icut")}`}</Label>
      <Col sm={8} xs={8}>
        <Label for="var_icut1" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_icut"
            id="var_icut1"
            data-id={1}
            checked={crop.var_icut === 1}
          />
          多年生
        </Label>
        <Label for="var_icut2" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_icut"
            id="var_icut2"
            data-id={2}
            checked={crop.var_icut === 2}
          />
          非多年生
        </Label>
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label sm={4} xs={4}>
        {i18next.t("crops.showEdit.rtype")}
      </Label>
      <Col sm={8} xs={8}>
        <Label for="var_rtype1" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_rtype"
            id="var_rtype1"
            data-id={1}
            checked={crop.var_rtype === 1}
          />
          植物成熟日まで
        </Label>
        <Label for="var_rtype2" sm={4} xs={4}>
          <Input
            onChange={(event) => onChangeRadio(event, crop)}
            type="radio"
            name="var_rtype"
            id="var_rtype2"
            data-id={2}
            checked={crop.var_rtype === 2}
          />
          非収穫日まで
        </Label>
      </Col>
    </FormGroup>
  </div>
);

export default RadioGroup;
