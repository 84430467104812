export const data = (state = null, action) => {
  switch (action.type) {
    case "FETCH_DATA_SUCCESS":
      return action.data;
    default:
      return state;
  }
};

export const year = (state = [], action) => {
  switch (action.type) {
    case "eYear_SUCCESS": {
      const copy = Object.assign({}, action.year);
      copy.assign[action.year.target.name] = Number(action.year.target.value);
      return action.year;
    }
    default:
      return state;
  }
};

export const modal = (state = { isOpen: false, crop: null }, action) => {
  switch (action.type) {
    case "OPEN_SUCCESS":
      // const copyHash = Object.assign({}, action.hash);
      var copy = { isOpen: action.isOpen, crop: action.crop };
      copy = Object.assign({}, copy);
      copy.isOpen = !copy.isOpen;
      return copy;
    default:
      return state;
  }
};

export const token = (state = null, action) => {
  switch (action.type) {
    case "GET_TOKEN":
      return action.value;
    default:
      return state;
  }
};

export const calc = (state = null, action) => {
  switch (action.type) {
    case "SEND_CALC":
      return action.json;
    default:
      return state;
  }
};
