import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import i18next from "i18next";

import * as common from "../Common/common";
import PageController from "../PageController/index";
import BreadCrumb from "../BreadCrumb/index";
import { fetchCultivations } from "../../Actions/cultivation";
import { fetchCropRotations } from "../../Actions/cropRotations";
import { fetchCrops } from "../../Actions/crops";
import DetailScreen from "../DetailScreen/culti";

import CultivationTable from "./Table";
import NewCultivation from "./newCultivation";
import { handleModal } from "../../Actions/common";

import { API, CLIENT } from "../../consts_path";
import VisTimeLine from "../VisTimeLine/index";
import { fetchVisItems } from "../../Actions/vis_timeline";
import IconAndLabel from "../IconAndLabel/index";
import { getToken } from "../../Actions/common";

const culti = require("../../assets/culti.png");

class Cultivation extends Component {
  componentDidMount() {
    const {
      input,
      getToken,
      fetchCultivations,
      fetchCropRotationsData,
      Crop,
    } = this.props;
    const urlForCulti = common.makeUrlIndex(API.URL.cultivation, input.id);
    const urlForCropRotation = common.makeUrlIndex(
      API.URL.croprotation,
      input.id
    );
    // const urlForTimeLine = common.makeUrlIndex(API.URL.vis, input.id);
    const urlForCrops = API.URL.crop.replace(":input_id", input.id);
    fetchCultivations(urlForCulti, "FETCH_CULTIVATION_SUCCESS");
    fetchCropRotationsData(urlForCropRotation);
    Crop(urlForCrops);
    getToken();
    // this.props.VisItems(urlForTimeLine);
  }
  zeroPadding = (num, length) => {
    return ("00" + num).slice(length);
  };

  create = (event, ymd) => {
    event.preventDefault();
    const form = new FormData();
    const { csrfToken, input, fetchCultivations } = this.props;
    form.append(
      "cultivation[var_dcult]",
      `${ymd.year}${this.zeroPadding(ymd.month, -2)}${this.zeroPadding(
        ymd.day,
        -2
      )}`
    );
    const urlForCulti = API.URL.cultivation.replace(API.REP.inputid, input.id);
    fetch(urlForCulti, {
      credentials: "same-origin",
      method: "POST",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          alert("地点選択からやり直してください。");
          throw Error((window.location = CLIENT.URL.root));
        }
        return res;
      })
      .then(() => {
        fetchCultivations(urlForCulti, "FETCH_CULTIVATION_SUCCESS");
      });
  };
  render() {
    const assign = Object.assign(common.pageController);
    const {
      input,
      timeline,
      crops,
      data,
      cropRotations,
      isOpen,
      isLoading,
      csrfToken,
    } = this.props;
    assign.display = { display: "block" };
    assign.url = [
      common.makeUrlIndex(CLIENT.URL.fertilization, input.id),
      common.makeUrlIndex(CLIENT.URL.confirm, input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb
          landuse={0}
          irrigate={0}
          partition={0}
          parameter={0}
          crop={0}
          fertilization={0}
          cultivation={1}
        />
        <br />
        {timeline && <VisTimeLine timeline={timeline} />}
        {
          // isLoading ?
          // <span className="text-center"><Icon spin name="spinner" size="2x" /></span>
          //:
          data && cropRotations && (
            <div>
              <NewCultivation
                cropRotations={cropRotations}
                cultivations={data.cultivations}
                create={this.create}
                crops={crops}
              />
              {data.is_valid && (
                <Alert
                  color="danger"
                  style={{
                    width: `${i18next.t("alert_cul.danger").length * 2}%`,
                  }}
                >
                  {i18next.t("alert_cul.danger")}
                </Alert>
              )}
              <IconAndLabel
                path={culti}
                alt="耕起"
                id="cultiico"
                label="耕起条件一覧"
              />
              <CultivationTable
                cultivations={data.cultivations}
                isLoading={isLoading}
                token={csrfToken}
              />
              {isOpen && <DetailScreen />}
              {!isLoading && (
                <PageController assign={assign} flagN={data.is_valid} />
              )}
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crops: state.crops,
  data: state.cultivation,
  cropRotations: state.cropRotations,
  input: state.input,
  timeline: state.visTimelineItems,
  isOpen: state.modal,
  isLoading: state.loadCultivations,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCultivations: (url, variable) =>
    dispatch(fetchCultivations(url, variable)),
  fetchCropRotationsData: (url) => dispatch(fetchCropRotations(url)),
  VisItems: (url) => dispatch(fetchVisItems(url)),
  Crop: (url) => dispatch(fetchCrops(url)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cultivation);
