// export const result = (state = { calculation: { is_finished: false, msg: '計算待機中...' } }, action) => {
//   switch (action.type) {
//     case 'FETCH_RESULT_DATA_SUCCESS': {
//       const assign = Object.assign(action.result);
//       if (action.result.calculation.status.match(/discontinued|completed/)) {
//         assign.calculation.is_finished = true;
//         if (action.result.calculation.status.match(/discontinued/)) {
//           assign.calculation.msg = '計算が失敗しました。';
//         } else {
//           assign.calculation.msg = '計算が終了しました。';
//         }
//       } else {
//         if (action.result.calculation.status.match(/waiting/)) {
//           assign.calculation.msg = '計算待機中...';
//         } else {
//           assign.calculation.msg = '計算中...';
//         }
//         assign.calculation.is_finished = false;
//       }
//       return assign;
//     }
//     default: {
//       return state;
//     }
//   }
// };

export const result = (state = null, action) => {
  switch (action.type) {
    case "FETCH_RESULT_DATA_SUCCESS": {
      const copyResult = Object.assign(action.result);
      copyResult.calculations.flag = copyResult.calculations.map((v) => {
        switch (v.status) {
          case "waiting":
          case "in_progress":
          case "discontinued":
          case null:
            return true;
          default:
            return false;
        }
      });
      copyResult.calculations.statusFlag = copyResult.calculations.map((v) => {
        switch (v.status) {
          case "completed":
            return 0;
          case "in_progress":
            return 1;
          case "discontinued":
            return 2;
          default:
            return null;
        }
      });
      var lat = copyResult.calculations
        .map((v) => {
          return v.input.latitude;
        })
        .filter((v) => v);
      var lon = copyResult.calculations
        .map((v) => {
          return v.input.longitude;
        })
        .filter((v) => v);

      copyResult.calculations.lat = [...new Set(lat)];
      copyResult.calculations.lon = [...new Set(lon)];
      return copyResult;
    }
    default: {
      return state;
    }
  }
};

export const calcres = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CALC_RES_SUCCESS": {
      const copyCalc = Object.assign(action.calcRes);
      return copyCalc.calculation;
    }
    default: {
      return state;
    }
  }
};

export const latlong = (state = null, action) => {
  switch (action.type) {
    case "MAP_ON_CLICK_SUCCESS": {
      const arr = action.res.split(/,/).map(Number);
      return arr;
    }
    default: {
      return state;
    }
  }
};

export const markdown = (state = null, action) => {
  switch (action.type) {
    case "FETCH_MARKDOWN_SUCCESS": {
      return action.markdown;
    }
    default: {
      return state;
    }
  }
};

export const loadResult = (state = true, action) => {
  switch (action.type) {
    case "LOAD_RESULT":
      return action.isLoading;
    default:
      return state;
  }
};
