export const getCropsError = (state = false, action) => {
  switch (action.type) {
    case "GET_CROPS_ERROR":
      return action.hasError;
    default:
      return state;
  }
};

export const loadCrops = (state = false, action) => {
  switch (action.type) {
    case "LOAD_CROPS":
      return action.isLoading;
    default:
      return state;
  }
};

export const crops = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CROPS_SUCCESS": {
      const copyCrop = Object.assign({}, action.crops);
      const isValid = copyCrop.crops.filter((el) => {
        return el["is_valid?"] === false;
      }).length;
      copyCrop.isValid = isValid === 0 ? null : true;
      copyCrop.yearStr = "収穫年を選択してください。";
      copyCrop.cropStr = "作物を選択してください。";
      return copyCrop;
    }
    default:
      return state;
  }
};

export const cropShow = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CROPS_SHOW_SUCCESS":
      return action.crops;
    default:
      return state;
  }
};

export const cropCommon = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CROP_COMMON_SUCCESS":
      action.common.keys = Object.keys(action.common)
        .filter((s) => {
          return s !== "id";
        })
        .filter((s) => {
          return s !== "var_klplt";
        });
      return action.common;
    default:
      return state;
  }
};

export const success = (state = "", action) => {
  switch (action.type) {
    case "SUCCESS":
      if (
        action.event.target.getAttribute("data-id") !==
        action.event.assign.var_igrowth
      ) {
        action.event.assign[action.event.target.name] = parseInt(
          action.event.target.getAttribute("data-id")
        );
      }
      return action.event;
    default:
      return state;
  }
};

export const calender = (state = "", action) => {
  switch (action.type) {
    case "CALENDER":
      const date = action.calender.assign[action.calender.target.name].split(
        /-/
      );
      if (action.calender.whichOne === "y") {
        date[0] = action.calender.target.value;
      } else if (action.calender.whichOne === "m") {
        date[1] = action.calender.target.value;
      } else {
        date[2] = action.calender.target.value;
      }
      action.calender.assign[action.calender.target.name] = date.join("-");
      return action.calender;
    default:
      return state;
  }
};

export const textbox = (state = "", action) => {
  switch (action.type) {
    case "TEXTBOX":
      action.textbox.assign[action.textbox.target.name] =
        action.textbox.target.value;
      return action.textbox;
    default:
      return state;
  }
};

export const checkbox = (state = "", action) => {
  switch (action.type) {
    case "CHECKBOX":
      action.checkbox.assign[action.checkbox.target.name] = !!action.checkbox
        .target.checked;
      return action.checkbox;
    default:
      return state;
  }
};
