import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import i18next from "i18next";

class ShowModal extends Component {
  render() {
    const headers = [
      i18next.t("crops.showEdit.igrowth"),
      i18next.t("crops.showEdit.icut"),
      i18next.t("crops.showEdit.rtype"),
      i18next.t("crops.common.cultivation_start"),
      i18next.t("crops.common.planting_date"),
      i18next.t("crops.showEdit.estart"),
      i18next.t("crops.showEdit.rdfday"),
      i18next.t("crops.showEdit.estop"),
      i18next.t("crops.common.harvest_date"),
      i18next.t("crops.common.cultivation_end"),
      i18next.t("crops.showEdit.rdep"),
      i18next.t("crops.showEdit.ak1"),
      i18next.t("crops.showEdit.ccovhar"),
      i18next.t("crops.showEdit.mulch"),
      i18next.t("crops.showEdit.panfac"),
      i18next.t("crops.showEdit.uptn"),
      i18next.t("crops.showEdit.uptp"),
      i18next.t("crops.showEdit.fixedn"),
      i18next.t("crops.showEdit.hfrac"),
      i18next.t("crops.showEdit.cnratio"),
    ];
    const keys = [
      "var_igrowth",
      "var_icut",
      "var_rtype",
      "start_date",
      "var_pday",
      "var_estart",
      "var_rdfday",
      "var_estop",
      "var_dayhar",
      "end_date",
      "var_rdep",
      "var_ak1",
      "var_ccovhar",
      "var_mulch",
      "var_panfac",
      "var_uptn",
      "var_uptp",
      "var_fixedn",
      "var_hfrac",
      "var_cnratio",
    ];
    const radioValue = {
      var_igrowth: ["成長なし", "成長あり"],
      var_icut: ["多年生", "非多年生"],
      var_rtype: ["植物成熟日", "非収穫日"],
    };
    const { isOpen, OpenModal, crop } = this.props;
    return (
      <Modal isOpen={isOpen} size="lg" toggle={(e) => OpenModal(isOpen, crop)}>
        <ModalHeader toggle={(e) => OpenModal(isOpen, crop)}>
          {`${crop.base_year || "−"} : ${crop.crop_detail}`}
        </ModalHeader>
        <ModalBody>
          <Table bordered size="sm">
            <tbody>
              {(() => {
                return headers.map((header, index) => (
                  <tr key={header}>
                    <th>{index + 1}</th>
                    <th>{header}</th>
                    {(() => {
                      if (keys[index].match(/var_igrowth|var_icut|var_rtype/)) {
                        return (
                          <td>
                            {radioValue[keys[index]][crop[keys[index]] - 1]}
                          </td>
                        );
                      }
                      return <td>{crop[keys[index]]}</td>;
                    })()}
                  </tr>
                ));
              })()}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

export default ShowModal;
