import React from "react";
import { Input, FormGroup, FormFeedback } from "reactstrap";
import NumericInput from "react-numeric-input";

// function format(str) {
//   return str + ' 分割';
// };

/*eslint no-useless-escape: "error"*/

const style = { width: "fit-content", display: "none" };

const PartitionTableTd = ({ soilLayer, onChange }) => (
  <tbody>
    {soilLayer.input.soil_layer_segm_nums.map((v, i) => {
      return (
        <tr key={`layer-${i + 1}`}>
          {/* <td>{i+1}</td> */}
          <td>{i + 1}層</td>
          <td>
            <FormGroup>
              <NumericInput
                // onInvalid={valueAsNumber => <span>設定できる数値はほげ</span>}
                className="form-control"
                name={`layer-${i + 1}`}
                max={20}
                min={1}
                value={v}
                inputMode="numeric"
                mobile
                // format={format}
                // onChange={e => onChange(soilLayer, e, i, 'ON_CHANGE_SUCCESS')}
                onChange={(valueAsNumber) =>
                  onChange(soilLayer, valueAsNumber, i, "ON_CHANGE_SUCCESS")
                }
                required
                // pattern={"\\d"}
                style={{
                  wrap: { width: "30vh" },
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                invalid={soilLayer.is_invalid[i]}
                // name={`layer-${i + 1}`}
                // type="number"
                // value={v}
                // min={1}
                // max={20}
                onChange={(e) => onChange(soilLayer, e, i, "ON_CHANGE_SUCCESS")}
                required
                style={style}
              />
              <FormFeedback>
                設定できる分割数は、「1~20」までです。
              </FormFeedback>
            </FormGroup>
          </td>
          {/* <td>分割</td> */}
        </tr>
      );
    })}
  </tbody>
);

export default PartitionTableTd;
