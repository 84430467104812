import React from "react";
import { Card, CardHeader, CardBody, Input, Col } from "reactstrap";
import CommonTableBody from "./common_table_body";

const Model = ({ label, data, onChange }) => (
  <Card id="modeltype" className="anchor modeltype">
    <CardHeader> {label} </CardHeader>
    <CardBody>
      <Col sm="7" xs="7" style={{ paddingLeft: "0px" }}>
        <Input
          type="select"
          value={data.soil_datum.modret_num}
          name="select"
          onChange={(e) => onChange(e, data, "CHANGE_MODEL_TYPE")}
        >
          <option name="vg" value={1}>
            van Genuchten式モデル
          </option>
          <option name="du" value={0}>
            Durner式モデル
          </option>
        </Input>
      </Col>
      {data.modret_num === "1" && (
        <CommonTableBody
          id="van_genuchten"
          data={data.structure.van_genuchten}
          keys={data.vgkeys}
          allData={data}
          onChange={onChange}
        />
      )}
      {data.modret_num === "0" && (
        <CommonTableBody
          id="durner"
          data={data.structure.durner}
          keys={data.dukeys}
          allData={data}
          onChange={onChange}
        />
      )}
    </CardBody>
  </Card>
);

export default Model;
