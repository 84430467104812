import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import i18next from "i18next";

import BreadCrumb from "../BreadCrumb/index";
import * as common from "../Common/common";
import PageController from "../PageController/index";

import { fetchFertilization } from "../../Actions/fertilization";
import { fetchCropRotations } from "../../Actions/cropRotations";
import DetailScreen from "../DetailScreen/fert";
import { handleModal } from "../../Actions/common";

import FertilizationTable from "./Table";
import NewFertilization from "./newFertilization";
import { API, CLIENT } from "../../consts_path";
import { fetchCrops } from "../../Actions/crops";

import VisTimeLine from "../VisTimeLine/index";
import { fetchVisItems } from "../../Actions/vis_timeline";
import IconAndLabel from "../IconAndLabel/index";
import { getToken } from "../../Actions/common";
const fert = require("../../assets/fert2.png");

class Fertilization extends Component {
  componentDidMount() {
    this.getCrops();
    this.getFertilization();
    this.getCropRotation();
    this.getCSFRToken();
  }
  getCrops = () => {
    const { input, Crop } = this.props;
    const urlForCrops = API.URL.crop.replace(":input_id", input.id);
    Crop(urlForCrops);
  };
  getFertilization = () => {
    const { input, fetchFert } = this.props;
    const urlForFertilization = API.URL.fertilization.replace(
      ":input_id",
      input.id
    );
    fetchFert(urlForFertilization);
  };
  getCropRotation = () => {
    const { input, fetchCropRotate } = this.props;
    const urlForCropRotaion = API.URL.croprotation.replace(
      ":input_id",
      input.id
    );
    fetchCropRotate(urlForCropRotaion);
  };
  getCSFRToken = () => {
    const { getToken } = this.props;
    getToken();
  };
  zeroPadding = (num, length) => {
    return ("00" + num).slice(length);
  };

  create = (event, ymd) => {
    event.preventDefault();
    const { csrfToken, input } = this.props;
    const form = new FormData();
    form.append(
      "fertilization[var_dfer]",
      `${ymd.year}${this.zeroPadding(ymd.month, -2)}${this.zeroPadding(
        ymd.day,
        -2
      )}`
    );
    const urlForFertilization = API.URL.fertilization.replace(
      ":input_id",
      input.id
    );
    fetch(urlForFertilization, {
      credentials: "same-origin",
      method: "POST",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          alert("地点選択からやり直してください。");
          throw Error((window.location = CLIENT.URL.root));
        }
        return res;
      })
      .then((res) => res.body)
      .then(() => {
        this.getFertilization();
      });
  };
  render() {
    const {
      cropRotations,
      fertilization,
      crops,
      timeline,
      isOpen,
      isLoading,
      csrfToken,
      fetchData,
      input,
    } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "block" };
    assign.url = [
      CLIENT.URL.crop.replace(":input_id", input.id),
      CLIENT.URL.cultivation.replace(":input_id", input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb
          landuse={0}
          irrigate={0}
          partition={0}
          parameter={0}
          crop={0}
          fertilization={1}
        />
        <br />
        {timeline && <VisTimeLine timeline={timeline} />}
        {
          // !isLoading &&
          cropRotations && (
            <NewFertilization
              cropRotations={cropRotations}
              fertilizations={fertilization.fertilizations}
              create={this.create}
              crops={crops}
            />
          )
        }
        {fertilization.is_valid ? (
          <Alert
            color="danger"
            style={{ width: `${i18next.t("alert_fer.danger").length * 2}%` }}
          >
            {i18next.t("alert_fer.danger")}
          </Alert>
        ) : (
          ""
        )}
        {
          // isLoading ?
          //   <span className="text-center"><Icon spin name="spinner" size="2x" /></span>
          //   :
          cropRotations && (
            <div>
              <IconAndLabel
                path={fert}
                alt="施肥"
                id="fertico"
                label="施肥条件一覧"
              />
              <FertilizationTable
                fetchData={fetchData}
                fertilization={fertilization.fertilizations}
                isLoading={isLoading}
                token={csrfToken}
              />
            </div>
          )
        }
        {!isLoading && (
          <PageController assign={assign} flagN={fertilization.is_valid} />
        )}
        {isOpen && <DetailScreen />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crops: state.crops,
  input: state.input,
  fertilization: state.fertilization,
  cropRotations: state.cropRotations,
  timeline: state.visTimelineItems,
  isOpen: state.modal,
  isLoading: state.loadFertilizations,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFert: (url) => dispatch(fetchFertilization(url)),
  fetchCropRotate: (url) => dispatch(fetchCropRotations(url)),
  VisItems: (url) => dispatch(fetchVisItems(url)),
  Crop: (url) => dispatch(fetchCrops(url)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fertilization);
