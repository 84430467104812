import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { onChangeSeries2 } from "../../Actions/graph";

class SeriesDepth extends Component {
  state = {
    selectOptions: [],
  };
  render() {
    const { selectOptions } = this.state;
    const options =
      selectOptions.length === 2
        ? selectOptions
        : this.props.series.field_options;
    return (
      <Select
        options={options}
        isMulti
        isLading={true}
        onChange={(e) => {
          e = e === null ? [] : e;
          this.setState({ selectOptions: e });
          this.props.onChangeSeries(e);
        }}
        name="series"
        className="seriesSelect"
        placeholder="データの種類を選択してください"
        classNamePrefix="select"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSeries: state.change_success2,
  series: state.series,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeSeries: (e) => dispatch(onChangeSeries2(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesDepth);
