import * as common from "../Component/Common/common";

export const confirm = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CONFIRM_DATA_SUCCESS":
      action.confirm.invalid = false;
      return action.confirm;
    case "ONCHANGE_TITLE":
      const assign = Object.assign({}, action.data);
      assign.title = action.value;
      assign.invalid = common.isEmpty(action.value);
      return assign;
    default:
      return state;
  }
};
