import React from "react";
import { Row, Col, CustomInput, Card, CardBody, CardHeader } from "reactstrap";

const bbc = [
  { name: "var_bbc", id: "fixeddepth", label: "地下水位一定" },
  { name: "var_bbc", id: "freedrainage", label: "自由排水" },
  { name: "var_bbc", id: "zeroflux", label: "下端流出入ゼロ" },
  { name: "var_bbc", id: "lysimeter", label: "ライシメータ" },
];

const BbcModelType = ({ onChangeRadio, soillayer }) => (
  <Card>
    <CardHeader>下端境界条件の選択</CardHeader>
    <CardBody>
      <Row>
        {bbc.map((v, i) => (
          <Col md={6} xs={12} key={v.id}>
            <CustomInput
              checked={soillayer.input.var_bbc === i + 1}
              id={v.id}
              type="radio"
              value={i + 1}
              name={v.name}
              label={v.label}
              onChange={(e) =>
                onChangeRadio(
                  soillayer,
                  e.target.value,
                  "",
                  "ON_CHANGE_BBC_TYPE"
                )
              }
              inline
            />
          </Col>
        ))}
      </Row>
    </CardBody>
  </Card>
);

export default BbcModelType;
