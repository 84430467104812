import React, { Component } from "react";
import CommonTableBody from "./common_table_body";
import ModelTable from "./model_table";
import ApplyToSameLayerCheckbox from "./apply_to_same_layer_checkbox";

class TableComponentList extends Component {
  componentDidMount() {}

  render() {
    const { data, onChange } = this.props;
    return (
      <span>
        {data && (
          <div>
            <CommonTableBody
              label={`土壌層物性値 （${data.soil_datum.layer_num}層 ${data.soil_datum.segm_num}セグメント）`}
              id="physical_property"
              data={data.structure.physical_property}
              keys={data.ppkeys}
              allData={data}
              onChange={onChange}
            />
            <ModelTable
              label={`土壌層の水分移動特性 （${data.soil_datum.layer_num}層 ${data.soil_datum.segm_num}セグメント）`}
              id="modeltype"
              data={data}
              onChange={onChange}
            />
            <CommonTableBody
              label={`土壌層の物質量 （${data.soil_datum.layer_num}層 ${data.soil_datum.segm_num}セグメント）`}
              id="initial_profile"
              data={data.structure.initial_profile}
              keys={data.omkeys}
              allData={data}
              onChange={onChange}
            />
            <CommonTableBody
              label={`土壌反応速度 （${data.soil_datum.layer_num}層 ${data.soil_datum.segm_num}セグメント）`}
              id="rate_constants"
              data={data.structure.rate_constants}
              keys={data.rrkeys}
              allData={data}
              onChange={onChange}
            />
            <ApplyToSameLayerCheckbox
              ischecked={data.ischecked}
              allData={data}
              onChange={onChange}
            />
          </div>
        )}
      </span>
    );
  }
}

export default TableComponentList;
