import React from "react";
import { FormGroup, Input, Label, Col } from "reactstrap";
import { WiFlood } from "react-icons/wi";

const RegionAndDistrictSelector = ({ weather, nearestSitesData, changeMeteoCrop }) => (
  <div>
    {weather && (
      <div>
        <h3>
          <WiFlood id="amedasIcon" />
          気象データと土壌タイプ
        </h3>
        <hr />
        <FormGroup row id="amedas_station">
          <Label for="district" id="ad" sm={3} xs={3}>
            地点の選択
          </Label>
          <Col sm={6} xs={6}>
            <Input
              type="select"
              name="district"
              id="district"
              value={weather.input.weather_station_value || (nearestSitesData && nearestSitesData[0])}
              onChange={(e) => changeMeteoCrop(e, weather, "NEAREST_SITE")}
            >
              {nearestSitesData &&
                nearestSitesData.map((m) => (
                  <option value={m} key={m[1]}>
                    {m[3]}({m[6] === "1" ? '官署' : 'アメダス'})
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={{ span: 8, offset: 3 }} xs={{ span: 8, offset: 3 }}>
            <Col>
              {/*<Label>(観測期間: {Date()} ~ {Date()})</Label>*/}
              {/*console.log(weather.collected_date)*/}
              <Label>{weather.collected_date}</Label>
            </Col>
          </Col>
        </FormGroup>
      </div>
    )}
  </div>
);

export default RegionAndDistrictSelector;

