import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const NavItems = ({ activeTab, toggle }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "1" })}
        onClick={() => {
          toggle("1");
        }}
      >
        経時変化
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "2" })}
        onClick={() => {
          toggle("2");
        }}
      >
        深度分布
      </NavLink>
    </NavItem>
  </Nav>
);

export default NavItems;
