import React, { Component } from "react";
import { connect } from "react-redux";
import Plot from "react-plotly.js";
import * as conf from "../plotConf";
import { GRAPHAPI } from "../../../consts_path";
import * as common from "../../Common/common";

class DepthDistribution extends Component {
  state = { activeTab: "1", c: null, prevSelcted: [] };

  UNSAFE_componentWillReceiveProps(np) {
    const { graphData } = this.props;
    const { selectedDays, selectedSeries } = np;
    if (selectedDays.dayString.length > 0 && selectedSeries.length > 0) {
      // 選択された日付の配列
      const day_str = selectedDays.dayString.map((d) => {
        return d;
      });
      // 選択されたでデータ種別の配列
      const series_vl = selectedSeries.map((d) => {
        return d.value;
      });
      var query = day_str.map((d) => {
        if (series_vl.length === 1) {
          return `${selectedSeries[0]["value"]}-${d}`;
        } else {
          return `${selectedSeries[0]["value"]}-${d},${selectedSeries[1]["value"]}-${d}`;
        }
      });
      const output_id = graphData.output.id;
      const verticalProfileUrl = common.makeVisualPartsUrl(
        GRAPHAPI.URL.verticalprofile,
        output_id
      );
      this.verticalProfileData(
        `${verticalProfileUrl}?fields=${query.join(",")}`
      );
    } else {
      this.setState({ c: [] });
    }
  }
  verticalProfileData = (url) => {
    fetch(url, { credentials: "same-origin" })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        var assign = conf.default.depthY;
        const a = data.vertical_profile.x1.map((y, i) => {
          var line = { color: "red", dash: "" };
          var marker = { symbol: "" };
          y.type = assign.type;
          y.mode = assign.mode;
          line.dash = conf.default.dashArray[i];
          marker.symbol = conf.default.marker[i];
          y.line = line;
          y.marker = marker;
          return y;
        });
        data.vertical_profile.x2 = data.vertical_profile.x2
          ? data.vertical_profile.x2
          : [];
        var assign2 = conf.default.depthY2;
        const b = data.vertical_profile.x2.map((y, i) => {
          var line = { dash: "", color: "blue", width: 4 };
          var marker = { symbol: "" };
          y.type = assign2.type;
          y.mode = assign2.mode;
          y.yaxis = assign2.yaxis;
          y.xaxis = assign2.xaxis;
          line.dash = conf.default.dashArray[i];
          marker.symbol = conf.default.marker[i];
          y.line = line;
          y.marker = marker;
          return y;
        });
        const ab = a.concat(b);
        this.setState({
          c: ab,
          yAxis: data.vertical_profile.y_axis,
          x1Axis: data.vertical_profile.x1_axis,
          x2Axis: data.vertical_profile.x2_axis,
        });
      });
  };
  render() {
    return (
      <div>
        <Plot
          data={this.state.c}
          style={{ height: "1000px" }}
          layout={{
            // autosize: true,
            showlegend: true,
            xaxis: {
              title: `${this.state.x1Axis || "-"}`,
              rangemode: "tozero",
              side: "top",
              linecolor: "red",
            },
            xaxis2: {
              title: `${this.state.x2Axis || "-"}`,
              rangemode: "tozero",
              side: "bottom",
              linecolor: "blue",
              overlaying: "x",
            },
            yaxis: {
              title: `${this.state.yAxis || "-"}`,
              rangemode: "tozero",
              sode: "left",
              linecolor: "black",
              autorange: "reversed",
            },
            yaxis2: {
              title: `${this.state.yAxis || "-"}`,
              rangemode: "tozero",
              side: "right",
              linecolor: "black",
              autorange: "reversed",
              overlaying: "y",
            },
            legend: { x: 0, y: 1.3 },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSeries: state.change_success2,
  selectedDays: state.selected_day_success,
  graphData: state.graphData,
});

export default connect(mapStateToProps)(DepthDistribution);
