import React from "react";
import i18next from "i18next";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";

const SoilLayerPhysicalProperty = ({
  label,
  id,
  data,
  keys,
  allData,
  onChange,
}) => (
  <Card id={id} className={`anchor ${id}`}>
    {label && <CardHeader>{label}</CardHeader>}
    <CardBody>
      {keys.map((v) => (
        <FormGroup row key={v}>
          <Label for={v} sm={5} xs={5}>{`${i18next.t(`${id}.${v}`)}`}</Label>
          <Col sm={5} xs={5}>
            <Input
              type="number"
              name={v}
              id={id}
              value={data[v] || 0}
              placeholder="値"
              onChange={(e) => onChange(e, allData, "CHANGE_TEXT_VALUE")}
            />
          </Col>
        </FormGroup>
      ))}
    </CardBody>
  </Card>
);

export default SoilLayerPhysicalProperty;
