/* eslint-disable */

require("dotenv").config();

import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter as Router } from 'react-router-dom';
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import configureStore from "./Store/configureStore";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/js/brands.js";
import "@fortawesome/fontawesome-free/js/solid.js";
import "@fortawesome/fontawesome-free/js/fontawesome.js";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18next";

/* eslint-enable */

const store = configureStore();
ReactDOM.render(
  <CookiesProvider>
    <HashRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Provider>
    </HashRouter>
  </CookiesProvider>,
  document.getElementById("app")
);
