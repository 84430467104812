import React from "react";
import { CustomInput, Card, CardBody, CardHeader } from "reactstrap";

const SelectModelType = ({ onChangeRadio, soillayer }) => (
  <Card>
    <CardHeader>水分移動特性式の選択</CardHeader>
    <CardBody>
      {/* VG */}
      <CustomInput
        checked={soillayer.input.cc_modret_num === 1}
        id="vg"
        type="radio"
        value={1}
        name="modret_num"
        label="Van Genuchten"
        onChange={(e) =>
          onChangeRadio(soillayer, e.target.value, "", "ON_CHANGE_MODEL_TYPE")
        }
        inline
      />
      {/* Dunner */}
      <CustomInput
        checked={soillayer.input.cc_modret_num === 0}
        id="durner"
        type="radio"
        value={0}
        name="modret_num"
        label="Durner"
        onChange={(e) =>
          onChangeRadio(soillayer, e.target.value, "", "ON_CHANGE_MODEL_TYPE")
        }
        inline
      />
    </CardBody>
  </Card>
);

export default SelectModelType;
