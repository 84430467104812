import React from "react";
import { Alert } from "reactstrap";

const invalidAlert = ({ text }) => (
  <div>
    <Alert color="danger" style={{ width: "50%" }}>
      {text}
    </Alert>
  </div>
);

export default invalidAlert;
