import React from "react";
import CropTableBodyTd from "./td";

const CropTableBodies = ({ crops, onClickCheckBox }) => (
  <tbody>
    {crops.crop_selections.map((crop, index) => (
      <CropTableBodyTd
        key={crop.id}
        crop={crop}
        index={index}
        crops={crops}
        onClickCheckBox={onClickCheckBox}
      />
    ))}
  </tbody>
);

export default CropTableBodies;
