import { combineReducers } from "redux";
import {
  input,
  landUseData,
  geoLocation,
  weather,
  irrigateData,
  updateGeoLocation,
  nearestSitesData,
} from "./landUse";
import {
  soilLayer,
  parameter,
  physicalproperty,
  physicalpropertydata,
  toggleState,
  load,
} from "./segment";
import { newCropsChangeValue, checked, cropRotations, loadCropRotations } from "./cropRotations";
import {
  checkbox,
  textbox,
  calender,
  success,
  getCropsError,
  loadCrops,
  crops,
  cropShow,
  cropCommon,
} from "./crops";
import { fertilization, fertshow, loadFertilizations } from "./fertilization";
import { cultivation, cultishow, loadCultivations } from "./cultivation";
import { confirm } from "./confirm";
import { result, latlong, markdown, calcres, loadResult } from "./result";
import { data, year, modal, token, calc } from "./common";
import { visTimelineItems } from "./vis_timeline";
import {
  series,
  depth,
  graph,
  change_success,
  change_success2,
  change_depth_success,
  selected_day_success,
  graphData,
  loadGraph,
} from "./graph";

export default combineReducers({
  cropRotations,
  loadCropRotations,
  newCropsChangeValue,
  landUseData,
  irrigateData,
  nearestSitesData,
  geoLocation,
  weather,
  updateGeoLocation,
  soilLayer,
  parameter,
  physicalproperty,
  physicalpropertydata,
  load,
  toggleState,
  checked,
  success,
  calender,
  textbox,
  checkbox,
  getCropsError,
  loadCrops,
  crops,
  cropShow,
  cropCommon,
  input,
  fertilization,
  fertshow,
  loadFertilizations,
  cultivation,
  cultishow,
  loadCultivations,
  data,
  year,
  modal,
  confirm,
  result,
  calcres,
  latlong,
  markdown,
  loadResult,
  visTimelineItems,
  series,
  depth,
  graph,
  change_success,
  change_success2,
  change_depth_success,
  selected_day_success,
  graphData,
  loadGraph,
  token,
  calc,
});
