import React, { Component } from "react";
import { connect } from "react-redux";
import { CLIENT } from "../../consts_path";
import { Row, Col, Card, CardBody } from "reactstrap";
import { fetchMarkDown } from "../../Actions/results";
import * as common from "../Common/common";
import PageController from "../PageController/index";

var Markdown = require("react-markdown");

class CalculateCondition extends Component {
  componentDidMount() {
    // const { MarkDown } = this.props;
    // MarkDown("http://192.168.11.25:3001/conf.md");
  }

  render() {
    const { markdown, input } = this.props;
    const assign = Object.assign(common.pageController);
    assign.display = { display: "block" };
    assign.displayN = { display: "none" };
    assign.url = [common.makeUrlIndex(CLIENT.URL.result, input.id), ""];
    assign.value = ["戻る"];
    return (
      <div>
        <Row>
          <Col sm={12}>
            <br />
            <Card>
              <CardBody>
                <Markdown source={markdown} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <PageController assign={assign} col={4} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  markdown: state.markdown,
});

const mapDispatchToProps = (dispatch) => ({
  MarkDown: (url) => dispatch(fetchMarkDown(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculateCondition);
