import React from "react";

const IconAndLabel = ({ path, alt, id, label }) => (
  <div>
    <img src={path} id={id} alt={alt} />
    <h3 className="cropLabel">{label}</h3>
  </div>
);

export default IconAndLabel;
