import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { K00_SOIL_CLASS_COLOR2VALUE_LUT } from "../../utils/K00_SOIL_CLASS_COLOR2VALUE_LUT";
import { K00_SOIL_CLASS_VALUE2DESCRIPTION_LUT } from "../../utils/K00_SOIL_CLASS_VALUE2DESCRIPTION_LUT";
import { API } from "../../consts_path";

const SOIL_TYPE_TILE_URL = "assets/tile/{z}/{x}/{y}.png";


class Map extends React.Component {
  tilePixelAttributePicker(lat, lng, zoom) {
    // save urls
    this.tileUrl = SOIL_TYPE_TILE_URL;

    // LUT
    this.color2valueLUT = K00_SOIL_CLASS_COLOR2VALUE_LUT;
    this.value2descriptionLUT = K00_SOIL_CLASS_VALUE2DESCRIPTION_LUT;

    // canvas
    this.canvas = document.createElement("canvas");
    this.canvas.width = 256;
    this.canvas.height = 256;


    // 座標変換
    const N = 2**zoom;
    const R = N / (2 * Math.PI);

    const  lat_r = lat * Math.PI / 180;
    const  lng_r = lng * Math.PI / 180;

    const float_x = (R * (lng_r - Math.PI) + N) % N;
    const float_y = -R * Math.atanh(Math.sin(lat_r)) + N/2;

    const pixel_x = Math.trunc(Math.round((float_x - Math.floor(float_x)) * 256));
    const pixel_y = Math.trunc(Math.round((float_y - Math.floor(float_y)) * 256));

    const tile_x = Math.trunc(float_x);
    const tile_y = Math.trunc(float_y);


    // タイルの正規化済みURL
    const tileUrl = this.regulatedUrl(
      this.tileUrl.replace("{x}", tile_x).replace("{y}", tile_y).replace("{z}", zoom)
    );


    // from leaflet layer img
    let img = null;
    // eslint-disable-next-line no-unused-vars
    for( const el of document.querySelectorAll(".leaflet-tile.leaflet-tile-loaded") ){
      if( el.src === tileUrl ){
        img = el;
        break
      }
    };

    // 失敗
    if( img === null )
      return null;
    
    
    // pick pixel value
    const ctx = this.canvas.getContext("2d");
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.drawImage(img, 0, 0);
    const pixel = ctx.getImageData(pixel_x, pixel_y, 1, 1).data;

    // invisible pixel
    if( pixel[3] === 0 )
      return null;

    const rgb = pixel.slice(0,3).join(",");

    // rgb undefined
    if( !this.color2valueLUT.hasOwnProperty(rgb) )
      return null;

    const res = [null, null];
    res[0] = this.color2valueLUT[rgb];

    // description undefined
    if( this.value2descriptionLUT.hasOwnProperty(res[0]) )
      res[1] = this.value2descriptionLUT[res[0]];


    return res;

  }

  //
  // regulatedUrl
  //
  regulatedUrl(url){
    let a = document.createElement("a");
    a.href = url;
    let _url = a.href;
    a.remove();
    return _url;
  }

  createControl() {
    const MapInfo = L.Control.extend({
      onAdd: (map) => {
        // container
        const container = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-opacity-slider-container",
        );
        container.style.backgroundColor = "white";
        L.DomEvent.disableClickPropagation(container);

        return container;
      }
    });
    return new MapInfo({ position: "bottomright" });
  }
  componentDidMount() {
    const { map, getNearestSites, weather, changeSoilType } = this.props;
    const control = this.createControl();
    control.addTo(map);
    let self = this;
    map.on('click', function(e) {
      // 緯度経度を取得
      const lat = e.latlng.lat;
      const lng = e.latlng.lng;
      const zoom = e.target._zoom;

      const urlForNearestSites = API.URL.nearestSites;
      getNearestSites(urlForNearestSites+`?lat=${lat}&lng=${lng}`);

      // pick
      const res = self.tilePixelAttributePicker(
        lat,
        lng,
        zoom,
      );

      // pick成功
      if( res !== null ){
        const soilCode = res[0];
        const soilName = res[1][0];
    
        e.target.value = soilCode.startsWith("D") ? 0 : 1;
        e.target.name = "land_use_id";
        changeSoilType(e, weather, "SOIL_TYPE_INIT");
        window.location.href = "/#/?soil_name=" + soilName + "&soil_code=" + soilCode + "&lat=" + lat + "&lon=" + lng + "&mcode=---";
      }
    });
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(Map);
