import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Col } from "reactstrap";

class makeYearOption extends Component {
  makeCropOption = (cropSelections, string) => {
    let option = [];
    let disabled = false;
    // 作物なしが選択されていた場合、isNull変数に作物なしのオブジェクトがreturnされる
    const isNull = cropSelections.filter((cr) => {
      if (cr.crop_name === "作物なし") {
        return cr.checked === 1;
      }
      return "";
    });
    disabled = isNull.length > 0;
    option = cropSelections.map((cr) => {
      return (
        <option
          key={cr.id}
          id={cr.id}
          value={cr.id}
          disabled={cr.crop_name === "作物なし" ? false : disabled}
        >
          {" "}
          {cr.crop_detail}{" "}
        </option>
      );
    });
    option.unshift(
      <option key={-1} id={-1} value={-1}>
        {string}
      </option>
    );
    return option;
  };

  render() {
    const { val, onchange, crops } = this.props;
    return (
      <Col md={7} sm={7} xs={7}>
        <Input
          type="select"
          name="crops"
          id="crop"
          value={val.crops}
          onChange={(e) => onchange(e, val)}
        >
          {this.makeCropOption(
            crops.crop_selections,
            crops.cropStr
          )}
        </Input>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  crops: state.crops,
  val: state.newCropsChangeValue,
});

export default connect(mapStateToProps)(makeYearOption);
