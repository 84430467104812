import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "reactstrap";
import { API, CLIENT } from "../../consts_path";
import { fetchCropCommon, onChangeTextValue } from "../../Actions/crops";
import CommonConfigList from "./common_config_list";
import BreadCrumb from "../BreadCrumb/index";
import * as common from "../Common/common";
import { getToken } from "../../Actions/common";

class CommonCropConfig extends Component {
  componentDidMount() {
    const { input, cropCommon, getToken } = this.props;
    const urlForCropCommons = common.makeUrlIndex(
      API.URL.cropcommons,
      input.id
    );
    cropCommon(urlForCropCommons);
    getToken();
  }

  update(e, commonProps) {
    e.preventDefault();
    const form = new FormData();
    const { input, history, csrfToken } = this.props;
    const urlForCommon = common.makeUrlIndex(API.URL.cropcommons, input.id);
    Object.keys(commonProps).map((key) =>
      form.append(`input[${key}]`, commonProps[key])
    );

    fetch(urlForCommon, {
      credentials: "same-origin",
      method: "PATCH",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      history.push(common.makeUrlIndex(CLIENT.URL.crop, input.id));
      // window.location.href = common.makeUrlIndex(CLIENT.URL.crop, input.id);
    });
  }

  render() {
    const label = [
      `${i18next.t("crops.commonConfig.hdry")}（${i18next.t("extension.cm")}）`,
      `${i18next.t("crops.commonConfig.hrow")}（${i18next.t("extension.cm")}）`,
      i18next.t("crops.commonConfig.ak2"),
      i18next.t("crops.commonConfig.rres"),
      i18next.t("crops.commonConfig.rres"),
    ];
    const { commons, changeTextValue, input } = this.props;
    return (
      <div>
        <BreadCrumb
          landuse={0}
          partition={0}
          parameter={0}
          irrigate={0}
          crop={1}
        />
        <h4>全作物共通設定の編集</h4>
        <hr />
        {commons && (
          <Form>
            {commons.keys.map((k, i) => {
              return (
                <CommonConfigList
                  onchange={changeTextValue}
                  common={commons}
                  key={k}
                  keys={k}
                  label={label[i]}
                />
              );
            })}
            <Row>
              <Col xs="auto">
                <Button
                  type="button"
                  color="success"
                  onClick={(e) => this.update(e, commons)}
                >
                  更新
                </Button>
              </Col>
              <Col xs="auto">
                <Link to={common.makeUrlIndex(CLIENT.URL.crop, input.id)}>
                  <Button type="button" size="" color="secondary">
                    キャンセル
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  commons: state.cropCommon,
  textbox: state.textbox,
  input: state.input,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  cropCommon: (url) => dispatch(fetchCropCommon(url)),
  changeTextValue: (event, commons) =>
    dispatch(onChangeTextValue(event, commons)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonCropConfig);
