import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

/*
 * crop: 作物情報をもつオブジェクト
 * crops: cropRotationのオブジェクト
 * */

const pointer = { cursor: "pointer" };
const CropTableBodyTd = ({ crop, index, crops, onClickCheckBox }) => (
  <tr key={index}>
    <td>
      <FormGroup>
        <Input
          type="checkbox"
          id={`crop_${crop.id}`}
          name="checked"
          value={crop.checked}
          checked={crop.checked === 1}
          data-id={crop.id}
          data-value={crop.crop_name}
          disabled={crop.crop_name === "作物なし" ? false : crops.disabled}
          onChange={(e) => onClickCheckBox(e, crop, crops)}
        />
        <Label
          style={
            crop.crop_name === "作物なし"
              ? pointer
              : crops.disabled
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
          id={`crop_${crop.id}`}
          for={`crop_${crop.id}`}
        >
          {crop.crop_detail}
        </Label>
      </FormGroup>
    </td>
    <td>{crop.plant_family}</td>
    <td>{crop.start_date}</td>
    <td>{crop.plant_date}</td>
    <td>{crop.harvest_date}</td>
  </tr>
);

export default CropTableBodyTd;
