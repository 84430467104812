import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "reactstrap";

import {
  fetchCropRotations,
  onClickCheckBox,
} from "../../Actions/cropRotations";
import { onChangeEYear } from "../../Actions/common";
import BreadCrumb from "../BreadCrumb/index";
import PageController from "../PageController/index";
import * as common from "../Common/common";
import CropTable from "./Table";
import { API, CLIENT } from "../../consts_path";
import { getToken } from "../../Actions/common";
import {Spinner} from "../iconSpinner";

class CropRotations extends Component {
  state = {
    isChecked: 0,
  };
  componentDidMount() {
    const { input, fetchData, getToken } = this.props;
    const urlForCropRotation = API.URL.croprotation.replace(
      API.REP.inputid,
      input.id
    );
    fetchData(urlForCropRotation);
    getToken();
  }

  update = (event, rotations) => {
    event.preventDefault();
    const { input, history, csrfToken } = this.props;
    const form = new FormData();
    for (var key in rotations) {
      if (key.match(/crop_rotation_ids|start_year|end_year/)) {
        if (key === "crop_rotation_ids") {
          form.append(`input[${key}]`, rotations[key]);
        } else {
          form.append(
            `input[${key}]`,
            rotations[key].toString().split(/-/).join("")
          );
        }
      }
    }
    const urlForCropRotation = common.makeUrlIndex(
      API.URL.croprotation,
      input.id
    );
    fetch(urlForCropRotation, {
      credentials: "same-origin",
      method: "PATCH",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      const urlForCrop = common.makeUrlIndex(CLIENT.URL.crop, input.id);
      history.push(urlForCrop);
    });
  };

  render() {
    const { isLoading, cropRotations, changeEYear, clickCheckBox, input } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "block" };
    assign.url = [
      common.makeUrlIndex(CLIENT.URL.crop, input.id),
      common.makeUrlIndex(CLIENT.URL.crop, input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb
          landuse={0}
          partition={0}
          parameter={0}
          irrigate={0}
          crop={1}
        />
        {isLoading && <Spinner />}
        {!isLoading && cropRotations && (
          <Form>
            <CropTable
              data={cropRotations}
              startYear={cropRotations.start_year}
              endYear={cropRotations.end_year}
              onChangeEYear={changeEYear}
              onClickCheckBox={clickCheckBox}
            />
            <PageController
              assign={assign}
              serverSendFunction={(event) => this.update(event, cropRotations)}
            />
          </Form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cropRotations: state.cropRotations,
  hasError: state.getCropsError,
  year: state.year,
  checked: state.checked,
  csrfToken: state.token,
  input: state.input,
  isLoading: state.loadCropRotations
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (url) => dispatch(fetchCropRotations(url)),
  changeEYear: (event, Rotations) => dispatch(onChangeEYear(event, Rotations)),
  clickCheckBox: (event, selections, rotations) =>
    dispatch(onClickCheckBox(event, selections, rotations)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CropRotations);
