export const K00_SOIL_CLASS_COLOR2VALUE_LUT = {
  "255,255,253": "A1",
  "255,255,252": "A1j1",
  "2,2,1": "A1j1",
  "255,254,253": "A1j2",
  "2,2,2": "A1j2",
  "254,255,253": "A1j3",
  "0,0,3": "A1j3",
  "255,255,249": "A2",
  "255,255,250": "A2i1",
  "0,3,0": "A2i1",
  "255,255,248": "A2i3",
  "3,0,0": "A2i3",
  "0,0,0": "B1",
  "0,0,1": "B1e1",
  "252,255,0": "B1e1",
  "0,1,0": "B1e1w1",
  "254,252,0": "B1e1w1",
  "1,0,0": "B1e1w2",
  "252,254,0": "B1e1w2",
  "0,1,1": "B1e1y1",
  "253,252,0": "B1e1y1",
  "1,0,1": "B1e2",
  "252,253,0": "B1e2",
  "1,1,0": "B1e2w1",
  "252,252,0": "B1e2w1",
  "1,1,1": "B1e2w2",
  "255,255,4": "B1e2w2",
  "0,0,2": "B1e2x2",
  "255,251,0": "B1e2x2",
  "0,2,0": "B1e2y1",
  "251,255,0": "B1e2y1",
  "2,0,0": "B1e3",
  "254,251,0": "B1e3",
  "0,1,2": "B1e3w1",
  "251,254,0": "B1e3w1",
  "0,2,1": "B1e3w2",
  "253,251,0": "B1e3w2",
  "1,0,2": "B1e3x2",
  "251,253,0": "B1e3x2",
  "1,2,0": "B1e3y1",
  "252,251,0": "B1e3y1",
  "2,0,1": "B1e4",
  "251,252,0": "B1e4",
  "2,1,0": "B1e4",
  "251,251,0": "B1e4",
  "0,2,2": "B1e4w1",
  "255,255,5": "B1e4w1",
  "1,1,2": "B1e4w1",
  "255,250,0": "B1e4w1",
  "1,2,1": "B1e4w2",
  "250,255,0": "B1e4w2",
  "2,0,2": "B1e4w2",
  "254,250,0": "B1e4w2",
  "2,1,1": "B1e4x2",
  "250,254,0": "B1e4x2",
  "2,2,0": "B1e4x2",
  "253,250,0": "B1e4x2",
  "1,2,2": "B1e4y1",
  "250,253,0": "B1e4y1",
  "2,1,2": "B1e4y1",
  "252,250,0": "B1e4y1",
  "76,0,115": "C1",
  "76,0,116": "C1a3",
  "0,1,3": "C1a3",
  "76,0,114": "C1a3p1",
  "0,3,1": "C1a3p1",
  "76,1,115": "C1a3p2",
  "1,0,3": "C1a3p2",
  "77,0,115": "C1a3y1",
  "1,3,0": "C1a3y1",
  "75,0,115": "C1b1",
  "3,0,1": "C1b1",
  "76,1,116": "C1b1p1",
  "3,1,0": "C1b1p1",
  "77,0,116": "C1b1p2",
  "0,2,3": "C1b1p2",
  "75,0,114": "C1b1y1",
  "0,3,2": "C1b1y1",
  "77,1,115": "C1b2",
  "1,1,3": "C1b2",
  "77,1,116": "C1b2p1",
  "1,3,1": "C1b2p1",
  "76,0,117": "C1b2p2",
  "2,0,3": "C1b2p2",
  "76,0,113": "C1b2y1",
  "2,3,0": "C1b2y1",
  "76,2,115": "C1e5",
  "250,252,0": "C1e5",
  "78,0,115": "C1e5p1",
  "251,250,0": "C1e5p1",
  "74,0,115": "C1e5p2",
  "250,251,0": "C1e5p2",
  "76,1,117": "C1e5y1",
  "250,250,0": "C1e5y1",
  "76,2,116": "C1z1",
  "77,0,117": "C1z1p1",
  "75,0,113": "C1z1p2",
  "77,2,115": "C1z1y1",
  "255,163,77": "D1",
  "255,163,78": "D1a3",
  "3,0,2": "D1a3",
  "255,163,76": "D1a3w3",
  "3,1,1": "D1a3w3",
  "255,164,77": "D1a3w4",
  "3,2,0": "D1a3w4",
  "255,162,77": "D1a3y1",
  "0,3,3": "D1a3y1",
  "254,163,77": "D1h3",
  "1,2,3": "D1h3",
  "255,164,78": "D1h3p3",
  "1,3,2": "D1h3p3",
  "255,162,76": "D1h3w4",
  "2,1,3": "D1h3w4",
  "254,163,76": "D1h3w5",
  "2,3,1": "D1h3w5",
  "254,162,77": "D1h3y1",
  "3,0,3": "D1h3y1",
  "254,162,76": "D1h4",
  "3,1,2": "D1h4",
  "255,163,79": "D1h4p3",
  "3,2,1": "D1h4p3",
  "255,163,75": "D1h4y1",
  "3,3,0": "D1h4y1",
  "255,165,77": "D1z1",
  "255,161,77": "D1z1p3",
  "253,163,77": "D1z1w4",
  "255,164,79": "D1z1w5",
  "255,162,75": "D1z1y1",
  "255,160,122": "D2",
  "255,160,123": "D2e6",
  "255,255,6": "D2e6",
  "255,160,121": "D2e6v3",
  "255,249,0": "D2e6v3",
  "255,161,122": "D2e6y1",
  "249,255,0": "D2e6y1",
  "255,159,122": "D2h1",
  "1,3,3": "D2h1",
  "254,160,122": "D2h1v3",
  "2,2,3": "D2h1v3",
  "255,161,123": "D2h1v4",
  "2,3,2": "D2h1v4",
  "255,159,121": "D2h1x4",
  "3,1,3": "D2h1x4",
  "254,160,121": "D2z1",
  "254,159,122": "D2z1p1",
  "254,159,121": "D2z1p2",
  "255,160,124": "D2z1v3",
  "255,160,120": "D2z1v4",
  "255,162,122": "D2z1y1",
  "205,78,55": "D3",
  "205,78,56": "D3d1",
  "3,2,2": "D3d1",
  "205,78,54": "D3d1v3",
  "3,3,1": "D3d1v3",
  "205,79,55": "D3d1y1",
  "2,3,3": "D3d1y1",
  "205,77,55": "D3d2",
  "3,2,3": "D3d2",
  "206,78,55": "D3d2p2",
  "3,3,2": "D3d2p2",
  "204,78,55": "D3d2v3",
  "3,3,3": "D3d2v3",
  "205,79,56": "D3d2y1",
  "0,0,4": "D3d2y1",
  "205,77,54": "D3e6",
  "254,249,0": "D3e6",
  "206,78,56": "D3e6v3",
  "249,254,0": "D3e6v3",
  "204,78,54": "D3e6y1",
  "253,249,0": "D3e6y1",
  "206,79,55": "D3h1",
  "0,4,0": "D3h1",
  "204,77,55": "D3h1p1",
  "4,0,0": "D3h1p1",
  "206,79,56": "D3h1p2",
  "0,1,4": "D3h1p2",
  "204,77,54": "D3h1v3",
  "0,4,1": "D3h1v3",
  "205,78,57": "D3h1v4",
  "1,0,4": "D3h1v4",
  "205,78,53": "D3h1x4",
  "1,4,0": "D3h1x4",
  "205,80,55": "D3h1y1",
  "4,0,1": "D3h1y1",
  "205,76,55": "D3z1",
  "207,78,55": "D3z1p1",
  "203,78,55": "D3z1p2",
  "205,79,57": "D3z1t1",
  "205,77,53": "D3z1t2",
  "205,80,56": "D3z1v3",
  "205,76,54": "D3z1v4",
  "206,78,57": "D3z1x4",
  "204,78,53": "D3z1y1",
  "206,135,64": "D4",
  "206,135,65": "D4h1",
  "4,1,0": "D4h1",
  "206,135,63": "D4h1p1",
  "0,2,4": "D4h1p1",
  "206,136,64": "D4h1p2",
  "0,4,2": "D4h1p2",
  "206,134,64": "D4h1x4",
  "1,1,4": "D4h1x4",
  "207,135,64": "D4h1y1",
  "1,4,1": "D4h1y1",
  "205,135,64": "D4h4",
  "2,0,4": "D4h4",
  "206,136,65": "D4h4p1",
  "2,4,0": "D4h4p1",
  "206,134,63": "D4h4p2",
  "4,0,2": "D4h4p2",
  "207,135,65": "D4h4x4",
  "4,1,1": "D4h4x4",
  "205,135,63": "D4h4y1",
  "4,2,0": "D4h4y1",
  "207,136,64": "D4z1",
  "205,134,64": "D4z1p1",
  "207,136,65": "D4z1p2",
  "205,134,63": "D4z1x4",
  "206,135,66": "D4z1y1",
  "137,90,68": "D5",
  "137,90,69": "D5c1",
  "0,3,4": "D5c1",
  "137,90,67": "D5c1p1",
  "0,4,3": "D5c1p1",
  "137,91,68": "D5c1p2",
  "1,2,4": "D5c1p2",
  "137,89,68": "D5c1v2",
  "1,4,2": "D5c1v2",
  "138,90,68": "D5c1w4",
  "2,1,4": "D5c1w4",
  "136,90,68": "D5c1y1",
  "2,4,1": "D5c1y1",
  "137,91,69": "D5h1",
  "3,0,4": "D5h1",
  "137,89,67": "D5h1v3",
  "3,4,0": "D5h1v3",
  "138,90,69": "D5h1v4",
  "4,0,3": "D5h1v4",
  "136,90,67": "D5h4",
  "4,1,2": "D5h4",
  "138,91,68": "D5h4p1",
  "4,2,1": "D5h4p1",
  "136,89,68": "D5h4p2",
  "4,3,0": "D5h4p2",
  "138,91,69": "D5h4y1",
  "0,4,4": "D5h4y1",
  "136,89,67": "D5z1",
  "137,90,70": "D5z1p1",
  "137,90,66": "D5z1p2",
  "137,92,68": "D5z1v1",
  "137,88,68": "D5z1v3",
  "139,90,68": "D5z1v4",
  "135,90,68": "D5z1w4",
  "138,69,0": "D6",
  "138,69,1": "D6c1",
  "1,3,4": "D6c1",
  "138,70,0": "D6c1p1",
  "1,4,3": "D6c1p1",
  "138,68,0": "D6c1p2",
  "2,2,4": "D6c1p2",
  "139,69,0": "D6c1v1",
  "2,4,2": "D6c1v1",
  "137,69,0": "D6c1v2",
  "3,1,4": "D6c1v2",
  "138,70,1": "D6c1v3",
  "3,4,1": "D6c1v3",
  "139,69,1": "D6c1v4",
  "4,0,4": "D6c1v4",
  "139,70,0": "D6c1w4",
  "4,1,3": "D6c1w4",
  "137,68,0": "D6d1",
  "4,2,2": "D6d1",
  "139,70,1": "D6d1p1",
  "4,3,1": "D6d1p1",
  "138,69,2": "D6d1p2",
  "4,4,0": "D6d1p2",
  "138,71,0": "D6d1v1",
  "1,4,4": "D6d1v1",
  "138,67,0": "D6d1v3",
  "2,3,4": "D6d1v3",
  "140,69,0": "D6d1v4",
  "2,4,3": "D6d1v4",
  "136,69,0": "D6d2",
  "3,2,4": "D6d2",
  "138,70,2": "D6d2p2",
  "3,4,2": "D6d2p2",
  "138,71,1": "D6d2v1",
  "4,1,4": "D6d2v1",
  "139,69,2": "D6d2v3",
  "4,2,3": "D6d2v3",
  "139,71,0": "D6d2v4",
  "4,3,2": "D6d2v4",
  "137,67,0": "D6h1",
  "4,4,1": "D6h1",
  "140,69,1": "D6h1p1",
  "2,4,4": "D6h1p1",
  "140,70,0": "D6h1p2",
  "3,3,4": "D6h1p2",
  "136,68,0": "D6h1p3",
  "3,4,3": "D6h1p3",
  "138,71,2": "D6h1v3",
  "4,2,4": "D6h1v3",
  "139,70,2": "D6h1v4",
  "4,3,3": "D6h1v4",
  "139,71,1": "D6h2",
  "4,4,2": "D6h2",
  "140,69,2": "D6h2p1",
  "3,4,4": "D6h2p1",
  "140,70,1": "D6h2p2",
  "4,3,4": "D6h2p2",
  "140,71,0": "D6h2p3",
  "4,4,3": "D6h2p3",
  "136,67,0": "D6h2t2",
  "4,4,4": "D6h2t2",
  "139,71,2": "D6h2v5",
  "0,0,5": "D6h2v5",
  "140,70,2": "D6h2y1",
  "0,5,0": "D6h2y1",
  "140,71,1": "D6h4",
  "5,0,0": "D6h4",
  "140,71,2": "D6h4p1",
  "0,1,5": "D6h4p1",
  "138,69,3": "D6h4p2",
  "0,5,1": "D6h4p2",
  "138,72,0": "D6h4p3",
  "1,0,5": "D6h4p3",
  "138,66,0": "D6h4y1",
  "1,5,0": "D6h4y1",
  "141,69,0": "D6z1",
  "135,69,0": "D6z1p1",
  "138,70,3": "D6z1p2",
  "138,72,1": "D6z1p3",
  "139,69,3": "D6z1t2",
  "139,72,0": "D6z1v3",
  "137,66,0": "D6z1v4",
  "141,69,1": "D6z1y3",
  "115,0,76": "E1",
  "115,0,77": "E1j4",
  "5,0,1": "E1j4",
  "115,0,75": "E1j4p1",
  "5,1,0": "E1j4p1",
  "115,1,76": "E1j4p2",
  "0,2,5": "E1j4p2",
  "116,0,76": "E1j4y1",
  "0,5,2": "E1j4y1",
  "114,0,76": "E1z1",
  "115,1,77": "E1z1p1",
  "116,0,77": "E1z1p2",
  "114,0,75": "E1z1y1",
  "168,0,132": "E2",
  "168,0,133": "E2j4",
  "1,1,5": "E2j4",
  "168,0,131": "E2j4p1",
  "1,5,1": "E2j4p1",
  "168,1,132": "E2j4p2",
  "2,0,5": "E2j4p2",
  "169,0,132": "E2j4t1",
  "2,5,0": "E2j4t1",
  "167,0,132": "E2j4y1",
  "5,0,2": "E2j4y1",
  "168,1,133": "E2z1",
  "169,0,133": "E2z1p1",
  "167,0,131": "E2z1p2",
  "169,1,132": "E2z1y1",
  "230,0,169": "E3",
  "230,0,170": "E3j4",
  "5,1,1": "E3j4",
  "230,0,168": "E3j4p1",
  "5,2,0": "E3j4p1",
  "230,1,169": "E3j4p2",
  "0,3,5": "E3j4p2",
  "231,0,169": "E3j4y1",
  "0,5,3": "E3j4y1",
  "229,0,169": "E3z1",
  "230,1,170": "E3z1p1",
  "231,0,170": "E3z1p2",
  "229,0,168": "E3z1y1",
  "0,112,255": "F1",
  "0,112,254": "F1a3",
  "1,2,5": "F1a3",
  "0,113,255": "F1a3p2",
  "1,5,2": "F1a3p2",
  "0,111,255": "F1a3t1",
  "2,1,5": "F1a3t1",
  "1,112,255": "F1a3t2",
  "2,5,1": "F1a3t2",
  "0,111,254": "F1a3t4",
  "3,0,5": "F1a3t4",
  "1,113,255": "F1a5",
  "3,5,0": "F1a5",
  "0,112,253": "F1a5p2",
  "5,0,3": "F1a5p2",
  "0,114,255": "F1a5t1",
  "5,1,2": "F1a5t1",
  "0,110,255": "F1a5t3",
  "5,2,1": "F1a5t3",
  "2,112,255": "F1b3",
  "5,3,0": "F1b3",
  "0,111,253": "F1b3p2",
  "0,4,5": "F1b3p2",
  "0,110,254": "F1b3t1",
  "0,5,4": "F1b3t1",
  "1,114,255": "F1b3t2",
  "1,3,5": "F1b3t2",
  "2,113,255": "F1b3t4",
  "1,5,3": "F1b3t4",
  "0,110,253": "F1j6",
  "2,2,5": "F1j6",
  "2,114,255": "F1j6p2",
  "2,5,2": "F1j6p2",
  "0,112,252": "F1j6y1",
  "3,1,5": "F1j6y1",
  "0,115,255": "F1z1",
  "0,109,255": "F1z1p2",
  "3,112,255": "F1z1t1",
  "0,111,252": "F1z1t2",
  "0,109,254": "F1z1t3",
  "1,115,255": "F1z1t4",
  "3,15,255": "F2",
  "3,15,254": "F2a1",
  "3,5,1": "F2a1",
  "3,16,255": "F2a1p2",
  "4,0,5": "F2a1p2",
  "3,14,255": "F2a1t1",
  "4,5,0": "F2a1t1",
  "4,15,255": "F2a1t2",
  "5,0,4": "F2a1t2",
  "2,15,255": "F2a1t4",
  "5,1,3": "F2a1t4",
  "3,14,254": "F2a2",
  "5,2,2": "F2a2",
  "2,15,254": "F2a2p2",
  "5,3,1": "F2a2p2",
  "4,16,255": "F2a2t1",
  "5,4,0": "F2a2t1",
  "2,14,255": "F2a2t2",
  "0,5,5": "F2a2t2",
  "2,14,254": "F2a2t4",
  "1,4,5": "F2a2t4",
  "3,15,253": "F2a6",
  "1,5,4": "F2a6",
  "3,17,255": "F2a6p2",
  "2,3,5": "F2a6p2",
  "3,13,255": "F2a6t1",
  "2,5,3": "F2a6t1",
  "5,15,255": "F2a6t2",
  "3,2,5": "F2a6t2",
  "1,15,255": "F2a6t4",
  "3,5,2": "F2a6t4",
  "3,14,253": "F2e6",
  "249,253,0": "F2e6",
  "3,13,254": "F2e6t1",
  "252,249,0": "F2e6t1",
  "2,15,253": "F2e6t2",
  "249,252,0": "F2e6t2",
  "4,17,255": "F2e6t4",
  "251,249,0": "F2e6t4",
  "2,13,255": "F2h3",
  "4,1,5": "F2h3",
  "1,15,254": "F2h3p2",
  "4,5,1": "F2h3p2",
  "5,16,255": "F2h3t1",
  "5,0,5": "F2h3t1",
  "1,14,255": "F2h3t3",
  "5,1,4": "F2h3t3",
  "3,13,253": "F2j7",
  "5,2,3": "F2j7",
  "2,14,253": "F2j7t1",
  "5,3,2": "F2j7t1",
  "2,13,254": "F2j7y1",
  "5,4,1": "F2j7y1",
  "1,15,253": "F2z1t1",
  "1,14,254": "F2z1t3",
  "0,3,168": "F3",
  "0,3,169": "F3a5",
  "5,5,0": "F3a5",
  "0,3,167": "F3a5p2",
  "1,5,5": "F3a5p2",
  "0,4,168": "F3a5t1",
  "2,4,5": "F3a5t1",
  "0,2,168": "F3a5t3",
  "2,5,4": "F3a5t3",
  "1,3,168": "F3a5t4",
  "3,3,5": "F3a5t4",
  "0,4,169": "F3a7",
  "3,5,3": "F3a7",
  "0,2,167": "F3a7p2",
  "4,2,5": "F3a7p2",
  "1,3,169": "F3a7t1",
  "4,5,2": "F3a7t1",
  "1,4,168": "F3a7t2",
  "5,1,5": "F3a7t2",
  "1,4,169": "F3a7t4",
  "5,2,4": "F3a7t4",
  "0,3,170": "F3d3",
  "5,3,3": "F3d3",
  "0,3,166": "F3d3t1",
  "5,4,2": "F3d3t1",
  "0,5,168": "F3d3y1",
  "5,5,1": "F3d3y1",
  "0,1,168": "F3e6",
  "249,251,0": "F3e6",
  "2,3,168": "F3e6p2",
  "250,249,0": "F3e6p2",
  "0,4,170": "F3e6t1",
  "249,250,0": "F3e6t1",
  "0,2,166": "F3e6t3",
  "249,249,0": "F3e6t3",
  "0,5,169": "F3h3",
  "2,5,5": "F3h3",
  "0,1,167": "F3h3p2",
  "3,4,5": "F3h3p2",
  "1,3,170": "F3h3t1",
  "3,5,4": "F3h3t1",
  "1,5,168": "F3h3t3",
  "4,3,5": "F3h3t3",
  "2,3,169": "F3j7",
  "4,5,3": "F3j7",
  "2,4,168": "F3j7t1",
  "5,2,5": "F3j7t1",
  "0,5,170": "F3j7y1",
  "5,3,4": "F3j7y1",
  "0,1,166": "F3z1",
  "1,4,170": "F3z1p2",
  "1,5,169": "F3z1t",
  "2,3,170": "F3z1t1",
  "2,4,169": "F3z1t2",
  "2,5,168": "F3z1t3",
  "1,5,170": "F3z1t4",
  "0,132,168": "F4",
  "0,132,169": "F4a3",
  "5,4,3": "F4a3",
  "0,132,167": "F4a3p2",
  "5,5,2": "F4a3p2",
  "0,133,168": "F4a3t1",
  "3,5,5": "F4a3t1",
  "0,131,168": "F4a3t2",
  "4,4,5": "F4a3t2",
  "1,132,168": "F4a3t4",
  "4,5,4": "F4a3t4",
  "0,133,169": "F4c1",
  "5,3,5": "F4c1",
  "0,131,167": "F4c1p2",
  "5,4,4": "F4c1p2",
  "1,132,169": "F4c1t1",
  "5,5,3": "F4c1t1",
  "1,133,168": "F4c1t2",
  "4,5,5": "F4c1t2",
  "1,133,169": "F4c1t4",
  "5,4,5": "F4c1t4",
  "0,132,170": "F4h3",
  "5,5,4": "F4h3",
  "0,132,166": "F4h3p2",
  "5,5,5": "F4h3p2",
  "0,134,168": "F4h3t1",
  "0,0,6": "F4h3t1",
  "0,130,168": "F4h3t2",
  "0,6,0": "F4h3t2",
  "2,132,168": "F4h3t4",
  "6,0,0": "F4h3t4",
  "0,133,170": "F4z1",
  "0,131,166": "F4z1p2",
  "0,134,169": "F4z1t1",
  "0,130,167": "F4z1t2",
  "1,132,170": "F4z1t4",
  "0,169,230": "F5",
  "0,169,231": "F5a3",
  "0,1,6": "F5a3",
  "0,169,229": "F5a3p2",
  "0,6,1": "F5a3p2",
  "0,170,230": "F5a3y1",
  "1,0,6": "F5a3y1",
  "0,168,230": "F5z1",
  "1,169,230": "F5z1p2",
  "0,170,231": "F5z1t2",
  "0,168,229": "F5z1t4",
  "1,169,231": "F5z1y1",
  "255,0,0": "G1",
  "255,0,1": "G1a3",
  "1,6,0": "G1a3",
  "255,1,0": "G1a3p1",
  "6,0,1": "G1a3p1",
  "254,0,0": "G1a3p2",
  "6,1,0": "G1a3p2",
  "255,1,1": "G1a3t1",
  "0,2,6": "G1a3t1",
  "255,0,2": "G1a3t3",
  "0,6,2": "G1a3t3",
  "255,2,0": "G1b1",
  "1,1,6": "G1b1",
  "253,0,0": "G1b1p1",
  "1,6,1": "G1b1p1",
  "255,1,2": "G1b1p2",
  "2,0,6": "G1b1p2",
  "255,2,1": "G1b1t1",
  "2,6,0": "G1b1t1",
  "255,2,2": "G1b1t3",
  "6,0,2": "G1b1t3",
  "255,0,3": "G1c1",
  "6,1,1": "G1c1",
  "255,3,0": "G1c1p1",
  "6,2,0": "G1c1p1",
  "252,0,0": "G1c1p2",
  "0,3,6": "G1c1p2",
  "255,1,3": "G1c1t1",
  "0,6,3": "G1c1t1",
  "255,3,1": "G1c1t3",
  "1,2,6": "G1c1t3",
  "255,2,3": "G1h3",
  "1,6,2": "G1h3",
  "255,3,2": "G1h3p1",
  "2,1,6": "G1h3p1",
  "255,3,3": "G1h3p2",
  "2,6,1": "G1h3p2",
  "255,0,4": "G1h3t1",
  "3,0,6": "G1h3t1",
  "255,4,0": "G1h3t3",
  "3,6,0": "G1h3t3",
  "251,0,0": "G1j5",
  "6,0,3": "G1j5",
  "255,1,4": "G1j5p1",
  "6,1,2": "G1j5p1",
  "255,4,1": "G1j5p2",
  "6,2,1": "G1j5p2",
  "255,2,4": "G1j5t1",
  "6,3,0": "G1j5t1",
  "255,4,2": "G1j5t3",
  "0,4,6": "G1j5t3",
  "255,3,4": "G1k1",
  "0,6,4": "G1k1",
  "255,4,3": "G1k1p1",
  "1,3,6": "G1k1p1",
  "255,4,4": "G1k1p2",
  "1,6,3": "G1k1p2",
  "255,0,5": "G1k1t1",
  "2,2,6": "G1k1t1",
  "255,5,0": "G1k1t2",
  "2,6,2": "G1k1t2",
  "250,0,0": "G1k1t3",
  "3,1,6": "G1k1t3",
  "255,1,5": "G1k2t1",
  "3,6,1": "G1k2t1",
  "255,5,1": "G1z1",
  "255,2,5": "G1z1k2",
  "255,5,2": "G1z1p1",
  "255,3,5": "G1z1p2",
  "255,5,3": "G1z1t1",
  "255,4,5": "G1z1t2",
  "255,5,4": "G1z1t3",
  "255,115,223": "G2",
  "255,115,224": "G2a3",
  "4,0,6": "G2a3",
  "255,115,222": "G2a3p1",
  "4,6,0": "G2a3p1",
  "255,116,223": "G2a3p2",
  "6,0,4": "G2a3p2",
  "255,114,223": "G2a3t1",
  "6,1,3": "G2a3t1",
  "254,115,223": "G2a3t3",
  "6,2,2": "G2a3t3",
  "255,116,224": "G2b1",
  "6,3,1": "G2b1",
  "255,114,222": "G2b1p1",
  "6,4,0": "G2b1p1",
  "254,115,222": "G2b1p2",
  "0,5,6": "G2b1p2",
  "254,114,223": "G2b1t1",
  "0,6,5": "G2b1t1",
  "254,114,222": "G2b1t3",
  "1,4,6": "G2b1t3",
  "255,115,225": "G2c1",
  "1,6,4": "G2c1",
  "255,115,221": "G2c1p1",
  "2,3,6": "G2c1p1",
  "255,117,223": "G2c1p2",
  "2,6,3": "G2c1p2",
  "255,113,223": "G2c1t1",
  "3,2,6": "G2c1t1",
  "253,115,223": "G2c1t3",
  "3,6,2": "G2c1t3",
  "255,116,225": "G2h3",
  "4,1,6": "G2h3",
  "255,114,221": "G2h3p1",
  "4,6,1": "G2h3p1",
  "255,117,224": "G2h3p2",
  "5,0,6": "G2h3p2",
  "255,113,222": "G2h3t1",
  "5,6,0": "G2h3t1",
  "254,115,221": "G2h3t3",
  "6,0,5": "G2h3t3",
  "254,113,223": "G2j5",
  "6,1,4": "G2j5",
  "253,115,222": "G2j5p1",
  "6,2,3": "G2j5p1",
  "253,114,223": "G2j5p2",
  "6,3,2": "G2j5p2",
  "255,117,225": "G2j5t1",
  "6,4,1": "G2j5t1",
  "255,113,221": "G2j5t3",
  "6,5,0": "G2j5t3",
  "254,114,221": "G2k1",
  "0,6,6": "G2k1",
  "254,113,222": "G2k1p1",
  "1,5,6": "G2k1p1",
  "253,115,221": "G2k1p2",
  "1,6,5": "G2k1p2",
  "253,114,222": "G2k1t1",
  "2,4,6": "G2k1t1",
  "253,113,223": "G2k1t3",
  "2,6,4": "G2k1t3",
  "254,113,221": "G2k2",
  "3,3,6": "G2k2",
  "253,114,221": "G2k2p1",
  "3,6,3": "G2k2p1",
  "253,113,222": "G2k2p2",
  "4,2,6": "G2k2p2",
  "253,113,221": "G2k2t1",
  "4,6,2": "G2k2t1",
  "255,115,226": "G2k2t2",
  "5,1,6": "G2k2t2",
  "255,115,220": "G2k2t3",
  "5,6,1": "G2k2t3",
  "255,118,223": "G2z1",
  "255,112,223": "G2z1p1",
  "252,115,223": "G2z1p2",
  "255,116,226": "G2z1t1",
  "255,114,220": "G2z1t2",
  "255,118,224": "G2z1t3",
  "115,255,223": "H1",
  "115,255,224": "H1c3",
  "6,0,6": "H1c3",
  "115,255,222": "H1c3p1",
  "6,1,5": "H1c3p1",
  "115,254,223": "H1c3p2",
  "6,2,4": "H1c3p2",
  "116,255,223": "H1c3t1",
  "6,3,3": "H1c3t1",
  "114,255,223": "H1c3t3",
  "6,4,2": "H1c3t3",
  "115,254,222": "H1e5",
  "255,255,7": "H1e5",
  "116,255,224": "H1e5p1",
  "255,248,0": "H1e5p1",
  "114,255,222": "H1e5p2",
  "248,255,0": "H1e5p2",
  "114,254,223": "H1e5t1",
  "254,248,0": "H1e5t1",
  "114,254,222": "H1e5t3",
  "248,254,0": "H1e5t3",
  "115,255,225": "H1h3",
  "6,5,1": "H1h3",
  "115,255,221": "H1h3p1",
  "6,6,0": "H1h3p1",
  "115,253,223": "H1h3p2",
  "1,6,6": "H1h3p2",
  "117,255,223": "H1h3t1",
  "2,5,6": "H1h3t1",
  "113,255,223": "H1h3t3",
  "2,6,5": "H1h3t3",
  "115,254,221": "H1z1",
  "115,253,222": "H1z1p1",
  "116,255,225": "H1z1p2",
  "114,255,221": "H1z1t1",
  "114,253,223": "H1z1t3",
  "163,255,115": "H2",
  "163,255,116": "H2a4",
  "3,4,6": "H2a4",
  "163,255,114": "H2a4p1",
  "3,6,4": "H2a4p1",
  "163,254,115": "H2a4p2",
  "4,3,6": "H2a4p2",
  "164,255,115": "H2a4t1",
  "4,6,3": "H2a4t1",
  "162,255,115": "H2a4t3",
  "5,2,6": "H2a4t3",
  "163,254,114": "H2b4",
  "5,6,2": "H2b4",
  "164,255,116": "H2b4p1",
  "6,1,6": "H2b4p1",
  "162,255,114": "H2b4p2",
  "6,2,5": "H2b4p2",
  "162,254,115": "H2b4t1",
  "6,3,4": "H2b4t1",
  "162,254,114": "H2b4t3",
  "6,4,3": "H2b4t3",
  "163,255,117": "H2c1",
  "6,5,2": "H2c1",
  "163,255,113": "H2c1p1",
  "6,6,1": "H2c1p1",
  "163,253,115": "H2c1p2",
  "2,6,6": "H2c1p2",
  "165,255,115": "H2c1t1",
  "3,5,6": "H2c1t1",
  "161,255,115": "H2c1t2",
  "3,6,5": "H2c1t2",
  "163,254,113": "H2c1t3",
  "4,4,6": "H2c1t3",
  "163,253,114": "H2c1tp2",
  "4,6,4": "H2c1tp2",
  "164,255,117": "H2h3",
  "5,3,6": "H2h3",
  "162,255,113": "H2h3p1",
  "5,6,3": "H2h3p1",
  "162,253,115": "H2h3p2",
  "6,2,6": "H2h3p2",
  "165,255,116": "H2h3t1",
  "6,3,5": "H2h3t1",
  "161,255,114": "H2h3t3",
  "6,4,4": "H2h3t3",
  "161,254,115": "H2z1",
  "163,253,113": "H2z1p1",
  "162,254,113": "H2z1p2",
  "162,253,114": "H2z1t1",
  "165,255,117": "H2z1t2",
  "161,255,113": "H2z1t3",
  "34,140,34": "I1",
  "34,140,35": "I1a3",
  "6,5,3": "I1a3",
  "34,140,33": "I1a3p1",
  "6,6,2": "I1a3p1",
  "34,141,34": "I1a3p2",
  "3,6,6": "I1a3p2",
  "34,139,34": "I1a3t1",
  "4,5,6": "I1a3t1",
  "35,140,34": "I1a3t3",
  "4,6,5": "I1a3t3",
  "33,140,34": "I1c1",
  "5,4,6": "I1c1",
  "34,141,35": "I1c1p1",
  "5,6,4": "I1c1p1",
  "34,139,33": "I1c1p2",
  "6,3,6": "I1c1p2",
  "35,140,35": "I1c1t1",
  "6,4,5": "I1c1t1",
  "33,140,33": "I1c1t3",
  "6,5,4": "I1c1t3",
  "35,141,34": "I1d4",
  "6,6,3": "I1d4",
  "33,139,34": "I1d4p1",
  "4,6,6": "I1d4p1",
  "35,141,35": "I1d4p2",
  "5,5,6": "I1d4p2",
  "33,139,33": "I1d4t1",
  "5,6,5": "I1d4t1",
  "34,140,36": "I1d4t3",
  "6,4,6": "I1d4t3",
  "34,140,32": "I1h3",
  "6,5,5": "I1h3",
  "34,142,34": "I1h3p1",
  "6,6,4": "I1h3p1",
  "34,138,34": "I1h3p2",
  "5,6,6": "I1h3p2",
  "36,140,34": "I1h3t1",
  "6,5,6": "I1h3t1",
  "32,140,34": "I1h3t3",
  "6,6,5": "I1h3t3",
  "34,141,36": "I1i1",
  "6,6,6": "I1i1",
  "34,139,32": "I1i1p1",
  "0,0,7": "I1i1p1",
  "34,142,35": "I1i1p2",
  "0,7,0": "I1i1p2",
  "34,138,33": "I1i1t1",
  "7,0,0": "I1i1t1",
  "35,140,36": "I1i1t2",
  "0,1,7": "I1i1t2",
  "33,140,32": "I1i1t3",
  "0,7,1": "I1i1t3",
  "35,142,34": "I1i2t1",
  "1,0,7": "I1i2t1",
  "33,138,34": "I1j8",
  "1,7,0": "I1j8",
  "36,140,35": "I1j8p1",
  "7,0,1": "I1j8p1",
  "32,140,33": "I1j8p2",
  "7,1,0": "I1j8p2",
  "36,141,34": "I1j8t1",
  "0,2,7": "I1j8t1",
  "32,139,34": "I1j8t3",
  "0,7,2": "I1j8t3",
  "34,142,36": "I1k2",
  "1,1,7": "I1k2",
  "34,138,32": "I1k2p1",
  "1,7,1": "I1k2p1",
  "35,141,36": "I1k2p2",
  "2,0,7": "I1k2p2",
  "33,139,32": "I1k2t1",
  "2,7,0": "I1k2t1",
  "35,142,35": "I1k2t3",
  "7,0,2": "I1k2t3",
  "33,138,33": "I1k3",
  "7,1,1": "I1k3",
  "36,140,36": "I1k3p1",
  "7,2,0": "I1k3p1",
  "32,140,32": "I1k3p2",
  "0,3,7": "I1k3p2",
  "36,141,35": "I1k3t1",
  "0,7,3": "I1k3t1",
  "32,139,33": "I1k3t3",
  "1,2,7": "I1k3t3",
  "36,142,34": "I1z1",
  "32,138,34": "I1z1p1",
  "35,142,36": "I1z1p2",
  "33,138,32": "I1z1t1",
  "36,141,36": "I1z1t3",
  "211,181,141": "J1",
  "211,181,142": "J1a3",
  "1,7,2": "J1a3",
  "211,181,140": "J1a3w3",
  "2,1,7": "J1a3w3",
  "211,182,141": "J1a3w4",
  "2,7,1": "J1a3w4",
  "211,180,141": "J1a3y1",
  "3,0,7": "J1a3y1",
  "212,181,141": "J1z1",
  "210,181,141": "J1z1p3",
  "211,182,142": "J1z1w4",
  "211,180,140": "J1z1w5",
  "212,181,142": "J1z1y1",
  "238,216,175": "J2",
  "238,216,176": "J2a3",
  "3,7,0": "J2a3",
  "238,216,174": "J2a3y1",
  "7,0,3": "J2a3y1",
  "238,217,175": "J2f2",
  "7,1,2": "J2f2",
  "238,215,175": "J2f2y1",
  "7,2,1": "J2f2y1",
  "239,216,175": "J2z1",
  "237,216,175": "J2z1y1",
  "204,204,0": "J3",
  "204,204,1": "J3a3",
  "7,3,0": "J3a3",
  "204,205,0": "J3a3y1",
  "0,4,7": "J3a3y1",
  "204,203,0": "J3f2",
  "0,7,4": "J3f2",
  "205,204,0": "J3f2x3",
  "1,3,7": "J3f2x3",
  "203,204,0": "J3f2y1",
  "1,7,3": "J3f2y1",
  "204,205,1": "J3z1",
  "205,204,1": "J3z1y1",
  "255,255,0": "J4",
  "255,255,1": "J4f1",
  "2,2,7": "J4f1",
  "255,254,0": "J4f1y1",
  "2,7,2": "J4f1y1",
  "254,255,0": "J4f2",
  "3,1,7": "J4f2",
  "254,254,0": "J4f2x3",
  "3,7,1": "J4f2x3",
  "255,255,2": "J4f2y1",
  "4,0,7": "J4f2y1",
  "255,253,0": "J4f3",
  "4,7,0": "J4f3",
  "253,255,0": "J4f3x1",
  "7,0,4": "J4f3x1",
  "254,253,0": "J4f3y1",
  "7,1,3": "J4f3y1",
  "253,254,0": "J4f4",
  "7,2,2": "J4f4",
  "253,253,0": "J4f4y1",
  "7,3,1": "J4f4y1",
  "255,255,3": "J4z1",
  "255,252,0": "J4z1y1",
  "255,255,254": "Z1",
  "255,255,255": "Z4"
};