import React from "react";
// import { Table } from 'reactstrap';
import { Nav, NavItem, NavLink } from "reactstrap";
import { FaAnchor } from "react-icons/fa";
import classnames from "classnames";

const navBar = ({ onScrollTarget, activeTab }) => (
  <Nav tabs id="navbar">
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "1" })}
        onClick={(e) => onScrollTarget("physical_property", "1", e)}
      >
        <small>
          <FaAnchor style={{ margin: "-3px 3px 0 0" }} />
          土壌層物性値
        </small>
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "2" })}
        onClick={(e) => onScrollTarget("modeltype", "2", e)}
      >
        <small>
          <FaAnchor style={{ margin: "-3px 3px 0 0" }} />
          水分移動特性
        </small>
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "3" })}
        onClick={(e) => onScrollTarget("initial_profile", "3", e)}
      >
        <small>
          <FaAnchor style={{ margin: "-3px 3px 0 0" }} />
          土壌層の物質量
        </small>
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "4" })}
        onClick={(e) => onScrollTarget("rate_constants", "4", e)}
      >
        <small>
          <FaAnchor style={{ margin: "-3px 3px 0 0" }} />
          土壌反応速度
        </small>
      </NavLink>
    </NavItem>
  </Nav>
);

export default navBar;
