export const series = (state = [], action) => {
  switch (action.type) {
    case "FETCH_SERIES_SUCCESS":
      return action.series;
    default:
      return state;
  }
};

export const depth = (state = null, action) => {
  switch (action.type) {
    case "FETCH_DEPTH_SUCCESS":
      var depthData = Object.assign({}, action.depth);
      return depthData;
    default:
      return state;
  }
};

export const graph = (
  state = { output: { is_finished: false, message: "インポート待機中..." } },
  action
) => {
  switch (action.type) {
    case "FETCH_GRAPH_DATA_SUCCESS":
      // if(!action.result.output.status){
      //   action.result.output.message = "インポート待機中...";
      //   action.result.output.is_finished = false;
      //   return action.result;
      // }
      // if(action.result.output.status.match(/discontinued|completed/)){
      //   action.result.output.is_finished = true;
      //   if(action.result.output.status.match(/discontinued/)){
      //     action.result.output.message = "インポートに失敗しました。";
      //   }else{
      //     action.result.output.message = "インポートが終了しました。";
      //   }
      // }else{
      //   if(action.result.output.status.match(/in_progress/)){
      //     action.result.output.message = "インポート実行中...";
      //   }else{
      //     action.result.output.message = "インポート中...";
      //   }
      //   action.result.output.is_finished = false;
      // }
      return action.result;
    default:
      return state;
  }
};

export const change_success = (state = null, action) => {
  switch (action.type) {
    case "CHANGE_SERIES_SUCCESS":
      return action.data;
    case "CLEAR_STATE":
      return [];
    default:
      return state;
  }
};

export const change_success2 = (state = [], action) => {
  switch (action.type) {
    case "CHANGE_SERIES_SUCCESS2":
      return action.data;
    case "CLEAR_STATE":
      return [];
    default:
      return state;
  }
};

export const change_depth_success = (state = [], action) => {
  switch (action.type) {
    case "CHANGE_DEPTH_SUCCESS":
      return action.depth;
    case "CLEAR_STATE":
      return [];
    default:
      return state;
  }
};

export const selected_day_success = (state = { dayString: [] }, action) => {
  switch (action.type) {
    case "SELECTED_DAY_SUCCESS":
      return action.day;
    case "CLEAR_STATE":
      return { dayString: [] };
    default:
      return state;
  }
};

export const graphData = (state = null, action) => {
  switch (action.type) {
    case "FETCH_RESULT_GRAPH_DATA_SUCCESS":
      return action.result;
    default:
      return state;
  }
};

export const loadGraph = (state = true, action) => {
  switch (action.type) {
    case "LOAD_GRAPH_DATA":
      return action.isLoading;
    default:
      return state;
  }
};
