import React from "react";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import LandUseType from "./options/land_use_type";

const DataSelector = ({ updateGeoLocation }) => (
  <Card style={{ marginTop: "3em" }}>
    <CardBody>{updateGeoLocation && <LandUseType />}</CardBody>
  </Card>
);

const mapStateToProps = (state) => ({
  updateGeoLocation: state.updateGeoLocation,
});

export default connect(mapStateToProps)(DataSelector);
