import { API } from "../consts_path";

export const eYear = (year) => {
  return {
    type: "eYear_SUCCESS",
    year,
  };
};

export const modal = (isOpen, crop) => {
  return {
    type: "OPEN_SUCCESS",
    isOpen,
    crop,
  };
};

export const sendToken = (value) => {
  return {
    type: "GET_TOKEN",
    value,
  };
};

export const sendCalc = (json) => {
  return {
    type: "SEND_CALC",
    json,
  };
};

export const getToken = () => {
  var token = null;
  return (dispatch) => {
    fetch(API.URL.calculations, {
      credentials: "same-origin",
      method: "GET",
    })
      .then((_res) => {
        token = _res.headers.get("X-CSRF-Token");
        return _res.json();
      })
      .then((_res) => {
        dispatch(sendToken(token));
        dispatch(sendCalc(_res));
      });
  };
};

export const onChangeEYear = (event, Rotations) => {
  return (dispatch) => {
    const assign = Object.assign(Rotations);
    const { target } = event;
    dispatch(eYear({ target, assign }));
  };
};

export const handleModal = (isOpen, crop) => {
  return (dispatch) => {
    dispatch(modal(isOpen, crop));
  };
};
