export const loadFertilizations = (state = false, action) => {
  switch (action.type) {
    case "LOAD_FERTILIZATIONS":
      return action.isLoading;
    default:
      return state;
  }
};

export const fertilization = (state = { fertilizations: [] }, action) => {
  switch (action.type) {
    case "FETCH_FERTILIZATION_SUCCESS": {
      const copyFert = Object.assign({}, action.fertilization);
      const isValid = copyFert.fertilizations.filter(
        (el) => el["is_valid?"] === false
      ).length;
      copyFert.is_valid = isValid === 0 ? null : true;
      return copyFert;
    }
    default: {
      return state;
    }
  }
};

export const fertshow = (state = null, action) => {
  switch (action.type) {
    case "FETCH_SHOW_SUCCESS":
      action.show.ferKey = Object.keys(action.show.fertilization).filter(
        (key) => {
          return !key.match(
            /id|crop|dfer|incor|fert_name|var_fer11|var_fer13|var_fer15/
          )
            ? key
            : "";
        }
      );
      action.show.ferUpdateKey = Object.keys(action.show.fertilization).filter(
        (key) => {
          return !key.match(/crop|var_incor|id|crop_id|is_valid['?']|fert_name/)
            ? key
            : "";
        }
      );

      return action.show;
    default:
      return state;
  }
};
