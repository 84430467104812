export const parameter = (myJson) => {
  return {
    type: "FETCH_SUCCESS_PARAM",
    myJson,
  };
};

export const soilLayerphysicalproperty = (prop) => {
  return {
    type: "FETCH_SUCCESS_PROP",
    prop,
  };
};

export const soilLayerphysicalpropertydata = (data, target, env, label) => {
  return {
    type: env,
    data,
    target,
    label,
  };
};

export const toggleState = (toggle) => {
  return {
    type: "TOGGLE_SUCCESS",
    toggle,
  };
};

export const loading = (status) => {
  return {
    type: "LOADING",
    isLoading: status,
  };
};

export const fetchParameter = (url) => {
  return (dispatch) => {
    dispatch(loading(true));
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        dispatch(parameter(myJson));
        dispatch(loading(false));
      });
  };
};

export const FetchSoilLayerPhysicalProperty = (url) => {
  return (dispatch) => {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        dispatch(soilLayerphysicalproperty(myJson));
      });
  };
};

export const FetchSoilLayerPhysicalPropertyData = (url, label) => {
  return (dispatch) => {
    dispatch(loading(true));
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        dispatch(
          soilLayerphysicalpropertydata(myJson, "", "FETCH_SUCCESS_DATA", label)
        );
        dispatch(loading(false));
      });
  };
};

export const toggleDropDown = (toggle) => {
  return (dispatch) => {
    dispatch(toggleState(!toggle));
  };
};

export const onChangeValue = (e, prop, variable) => {
  return (dispatch) => {
    dispatch(soilLayerphysicalpropertydata(prop, e.target, variable));
  };
};
