import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { API, CLIENT } from "../../consts_path";
import { connect } from "react-redux";
import { fetchFertilization } from "../../Actions/fertilization";
import * as common from "../Common/common";
import { handleModal } from "../../Actions/common";

class FertilizationTbody extends Component {
  onDelete = (event, fert) => {
    event.preventDefault();
    const result = window.confirm(
      `" ${fert.fert_name}「${fert.crop.crop_detail}」 " を削除します。\nよろしいですか？`
    );
    if (!result) return false;
    const { token, input } = this.props;
    const id = event.target.getAttribute("data-id");
    const urlForFertilizationDel = API.URL.fertilizationdelete
      .replace(":input_id", input.id)
      .replace(":id", id);
    fetch(urlForFertilizationDel, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        const urlForFertilization = API.URL.fertilization.replace(
          ":input_id",
          this.props.input.id
        );
        this.props.fetchFertilization(urlForFertilization);
      })
      .catch((error) => console.log(error));
  };
  render() {
    const { fertilization, objectModal, OpenModal } = this.props;
    return (
      <tr>
        <td>
          {fertilization["is_valid?"] ? (
            ""
          ) : (
            <FaExclamationCircle style={{ color: "#F57F17" }} />
          )}
        </td>
        <td>{fertilization.crop.base_year}</td>
        <td>
          <button
            type="button"
            className="buttonHref"
            onClick={(e) => OpenModal(objectModal.isOpen, fertilization)}
          >
            {fertilization.fert_name}
          </button>
        </td>
        <td>{fertilization.crop.crop_detail}</td>
        <td>{common.addOneDay(fertilization.crop.prev_end_date)}</td>
        <td>{fertilization.crop.end_date}</td>
        <td>
          <Link
            to={CLIENT.URL.fertconfig
              .replace(":input_id", this.props.input.id)
              .replace(":id", fertilization.id)}
          >
            <Button color="primary">設定</Button>
          </Link>
        </td>
        <td>
          <Button
            color="danger"
            data-id={fertilization.id}
            onClick={(e) => this.onDelete(e, fertilization)}
          >
            {i18next.t("crops.thead.remove")}
          </Button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  objectModal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFertilization: (url) => dispatch(fetchFertilization(url)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FertilizationTbody);
