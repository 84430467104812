import React from "react";
import { Input, FormGroup, Label } from "reactstrap";

const ApplyToSameLayerCheckbox = ({ ischecked, allData, onChange }) => (
  <FormGroup check>
    <Label check>
      <Input
        type="checkbox"
        name="copy_soil_data"
        checked={ischecked}
        onChange={(e) => onChange(e, allData, "CHANGE_CHECKBOX")}
      />
      同じ土壌層すべてに変更を適用する。
    </Label>
  </FormGroup>
);

export default ApplyToSameLayerCheckbox;
