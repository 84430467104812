import React, { Component } from "react";
// import { Row, Col, Button } from 'reactstrap';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

class ScrollDown extends Component {
  componentDidMount() {
    const { watchUnderPosition } = this.props;
    window.addEventListener(
      "scroll",
      (event) => watchUnderPosition(event),
      true
    );
  }

  render() {
    const { scrollDown } = this.props;
    return (
      <Button
        style={{ right: "100px" }}
        color="primary"
        className="scroll-down"
        id="down-button"
        onClick={(e) => scrollDown("down-button", "300", e)}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </Button>
    );
  }
}

export default ScrollDown;
