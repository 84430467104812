import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { API, CLIENT } from "../../../consts_path";

const ParameterTableTd = ({ param, inputid }) => (
  <tbody>
    {param.soil_data.map((v, i) => {
      return (
        <tr key={v.id}>
          <td>{i + 1}</td>
          <td>{`${v.layer_num} 層`}</td>
          <td>{`${v.segm_num}`}</td>
          <td>
            <Link
              to={CLIENT.URL.segmconfig
                .replace(API.REP.inputid, inputid)
                .replace(API.REP.paramid, v.id)}
            >
              <Button color="primary">設定</Button>
            </Link>
          </td>
        </tr>
      );
    })}
  </tbody>
);

export default ParameterTableTd;
