import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import * as common from "../Common/common";
import { FaExclamationCircle } from "react-icons/fa";
import { fetchCultivations } from "../../Actions/cultivation";
import { connect } from "react-redux";
import { API, CLIENT } from "../../consts_path";
import { handleModal } from "../../Actions/common";

//import ShowModal from './showModal';

class CultivationTbody extends Component {
  onDelete = (event, culti) => {
    event.preventDefault();
    const result = window.confirm(
      `" ${culti.cult_name} 「${culti.crop.crop_detail}」 " を削除します。\nよろしいですか？`
    );
    if (!result) return false;
    const { token } = this.props;
    const urlForCulti = common.makeUrlShow(
      API.URL.cultivationdelete,
      this.props.input.id,
      event.target.getAttribute("data-id")
    );
    fetch(urlForCulti, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        const urlForCultiIndex = common.makeUrlIndex(
          API.URL.cultivation,
          this.props.input.id
        );
        this.props.fetchCultivations(
          urlForCultiIndex,
          "FETCH_CULTIVATION_SUCCESS"
        );
      })
      .catch((error) => console.log(error));
  };
  render() {
    const { cultivation, OpenModal, objectModal, input } = this.props;
    return (
      <tr>
        <td>
          {cultivation["is_valid?"] ? (
            ""
          ) : (
            <FaExclamationCircle style={{ color: "#F57F17" }} />
          )}
        </td>
        <td>{cultivation.crop.base_year}</td>
        <td>
          <button
            type="button"
            className="buttonHref"
            onClick={(e) => OpenModal(objectModal.isOpen, cultivation)}
          >
            {cultivation.cult_name}
          </button>
        </td>
        <td>{cultivation.crop.crop_detail}</td>
        <td>{common.addOneDay(cultivation.crop.prev_end_date)}</td>
        <td>{cultivation.crop.end_date}</td>
        <td>
          <Link
            to={CLIENT.URL.cultivationconfig
              .replace(":input_id", input.id)
              .replace(":id", cultivation.id)}
          >
            <Button color="primary">設定</Button>
          </Link>
        </td>
        <td>
          <Button
            color="danger"
            data-id={cultivation.id}
            onClick={(e) => this.onDelete(e, cultivation)}
          >
            {i18next.t("crops.thead.remove")}
          </Button>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state) => ({
  input: state.input,
  objectModal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCultivations: (url, variable) =>
    dispatch(fetchCultivations(url, variable)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CultivationTbody);
