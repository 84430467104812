import React, { Component } from "react";
import { FormGroup, Input, Label, Col } from "reactstrap";

class calcYears extends Component {
  render() {
    const { Rotations, startYear, max, onChangeEYear, endYear } = this.props;
    return (
      <FormGroup row id="calcYear">
        <Label for="mixmax" id="mm" sm={2}>
          収穫年
        </Label>
        <Col sm={2}>
          <Input
            onChange={(e) => onChangeEYear(e, Rotations)}
            type="select"
            name="start_year"
            id="max"
            value={startYear}
          >
            {(() => {
              return max.map((m, i) => <option key={m}>{m}</option>);
            })()}
          </Input>
        </Col>
        <span id="dashi">〜</span>
        <Col sm={2}>
          <Input
            onChange={(e) => onChangeEYear(e, Rotations)}
            type="select"
            name="end_year"
            id="min"
            value={endYear}
          >
            {(() => {
              return max.map((m, i) => <option key={m}>{m}</option>);
            })()}
          </Input>
        </Col>
      </FormGroup>
    );
  }
}

export default calcYears;
