import React from "react";
import { Table } from "reactstrap";
import TBodies from "./TBody";
import CalcYear from "./calcYear";

const headers = ["作物", "分類", "施肥日/耕起日(初回)", "植え付け日", "収穫日"];

const CropTables = ({
  data,
  startYear,
  endYear,
  onChangeEYear,
  onClickCheckBox,
}) => (
  <div>
    <CalcYear
      min={data.minYearArr}
      max={data.maxYearArr}
      startYear={startYear}
      endYear={endYear}
      Rotations={data}
      onChangeEYear={onChangeEYear}
    />
    <Table>
      <thead>
        <tr>
          {(() => {
            return headers.map((head) => <th key={head}>{head}</th>);
          })()}
        </tr>
      </thead>
      <TBodies crops={data} onClickCheckBox={onClickCheckBox} />
    </Table>
  </div>
);

export default CropTables;
