export const cropRotations = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CROP_ROTATIONS_SUCCESS":
      var minYearArr = [];
      var maxYearArr = [];
      for (
        let m = action.cropRotations.min_year;
        m <= action.cropRotations.max_year;
        m++
      ) {
        minYearArr.push(m);
      }
      for (
        let mm = action.cropRotations.max_year;
        mm >= action.cropRotations.min_year;
        mm--
      ) {
        maxYearArr.push(mm);
      }
      const chekced_id = action.cropRotations.crop_selections.filter((e) => {
        return e.checked === 1;
      });
      chekced_id.map((e) => {
        if (e.crop_name === "作物なし") {
          action.cropRotations.disabled = true;
        } else {
          action.cropRotations.disabled = false;
        }
        return "";
      });
      action.cropRotations.minYearArr = minYearArr;
      action.cropRotations.maxYearArr = maxYearArr;
      // action.cropRotations.max_year = action.cropRotations.max_year;
      action.cropRotations.is_valid = true;
      if (action.cropRotations.crop_selections.length === 0) {
        action.cropRotations.is_valid = false;
      }
      action.cropRotations.yearStr = "収穫年を選択してください。";
      action.cropRotations.cropStr = "作物を選択してください。";
      return action.cropRotations;
    default:
      return state;
  }
};

export const checked = (state = false, action) => {
  switch (action.type) {
    case "CHECK_SUCCESS":
      action.checked.assignS[action.checked.target.name] =
        Number(action.checked.target.value) === 1 ? 0 : 1;
      if (Number(action.checked.target.value) === 1) {
        // XXX check off
        const slice_ids = action.checked.assignR.crop_rotation_ids.filter(
          (id) => {
            return id !== Number(action.checked.target.getAttribute("data-id"));
          }
        );
        action.checked.assignR.crop_rotation_ids = slice_ids;
        action.checked.assignR.disabled = false;
      } else {
        // XXX check on
        action.checked.assignR.crop_rotation_ids =
          action.checked.assignR.crop_rotation_ids !== "[]"
            ? action.checked.assignR.crop_rotation_ids
            : [];
        if (action.checked.target.getAttribute("data-value") === "作物なし") {
          action.checked.assignR.crop_rotation_ids = [];
          action.checked.assignR.crop_rotation_ids.push(
            Number(action.checked.target.getAttribute("data-id"))
          );
          action.checked.assignR.disabled = true;
          action.checked.assignR.crop_selections.filter((e) => {
            if (e.crop_name !== "作物なし") {
              if (e.checked === 1) {
                e.checked = e.checked === 1 ? 0 : 1;
              }
            }
            return "";
          });
        } else {
          action.checked.assignR.crop_rotation_ids.push(
            Number(action.checked.target.getAttribute("data-id"))
          );
        }
      }
      return action.checked;
    default:
      return state;
  }
};

export const newCropsChangeValue = (
  state = {
    years: "-1",
    crops: "-1",
    button_style: { disable: true, color: "warning" },
  },
  action
) => {
  switch (action.type) {
    case "ON_CHANGE_NEW_SUCCESS":
      action.value[action.target.name] = action.target.value;
      if (action.value.crops !== "-1" && action.value.years !== "-1") {
        action.value.button_style = { disable: false, color: "warning" };
      } else {
        action.value.button_style = { disable: true, color: "warning" };
      }

      return Object.assign({}, action.value);
    default:
      return state;
  }
};

export const loadCropRotations = (state = false, action) => {
  switch (action.type) {
    case "LOAD_CROP_ROTATIONS":
      return action.isLoading;
    default:
      return state;
  }
};
