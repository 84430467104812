import React, { Component } from "react";
import { connect } from "react-redux";
import { MapContainer, TileLayer } from 'react-leaflet'

import { Card, CardBody } from "reactstrap";
import {
  onChangeSoilType,
  fetchWeather,
  fetchIrrigate,
  fetchNearestSites,
} from "../../Actions/landUse";
import Map from "./map";

const DEFAULT_ZOOM = 7;
const MIN_ZOOM = 5;
const MAX_ZOOM = 16;
const DEFAULT_LATLNG = [36.346560861455465, 136.30372779054431];
const SOIL_TYPE_TILE_URL = "assets/tile/{z}/{x}/{y}.png";
const SOIL_TYPE_TILE_OPACITY = 0.4;

class DataSelector extends Component {
  componentDidMount() {}
  render() {
    const { changeSoilType, weather, NearestSites } = this.props;
    return (
      <div>
        <Card>
          <CardBody className="row">
            <div className="col-12 col-sm-12">
              <div id="mapcontainer" style={{height: 400}}>
                <MapContainer center={DEFAULT_LATLNG} zoom={DEFAULT_ZOOM} scrollWheelZoom={true} minZoom={MIN_ZOOM} maxZoom={MAX_ZOOM}>
                  <Map getNearestSites={NearestSites} changeSoilType={changeSoilType} weather={weather}  />
                  <TileLayer
                    attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
                    url="https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png"
                  />
                  <TileLayer
                    opacity={SOIL_TYPE_TILE_OPACITY}
                    url={SOIL_TYPE_TILE_URL}
                  />
                </MapContainer>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  weather: state.weather,
  irrigate: state.irrigateData,
  isLoading: state.load,
});

const mapDispatchToProps = (dispatch) => ({
  changeSoilType: (e, geo, variable) =>
    dispatch(onChangeSoilType(e, geo, variable)),
  Weather: (url) => dispatch(fetchWeather(url)),
  Irrigate: (url, variable) => dispatch(fetchIrrigate(url, variable)),
  NearestSites: (url) => dispatch(fetchNearestSites(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSelector);
