import React, { Component } from "react";

import { connect } from "react-redux";
import ShowModal from "./fertShow";
// import * as common from '../Common/common';
import { handleModal } from "../../Actions/common";

class DetailScreen extends Component {
  render() {
    const { objectModal, OpenModal } = this.props;
    return (
      <div>
        {objectModal.isOpen && (
          <ShowModal
            isOpen={objectModal.isOpen}
            OpenModal={OpenModal}
            crop={objectModal.crop}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  objectModal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  OpenModal: (isOpen) => dispatch(handleModal(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailScreen);
