import React from "react";
import { TabContent, TabPane, Card, Row, Col } from "reactstrap";
import TimeLine from "./graph/timeLineGraph";
import DepthDistribution from "./graph/depthDistributionGraph";
import ControlPanel from "./controlPanel";
import { connect } from "react-redux";

const TabContents = ({ activeTab, graphData }) => {
  return (
    <div>
      {graphData && (
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col lg="12">
                <Card body>
                  <h5>{graphData.input.title}</h5>
                  <Row>
                    <Col xl="12">
                      <TimeLine />
                    </Col>
                    <Col xl="12">
                      <ControlPanel flg={1} col={6} activeTab={activeTab} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Card body>
                  <h5>{graphData.input.title}</h5>
                  <Row>
                    <Col xl="8">
                      <DepthDistribution />
                    </Col>
                    <Col xl="4">
                      <ControlPanel flg={0} col={12} activeTab={activeTab} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  graphData: state.graphData,
});

export default connect(mapStateToProps)(TabContents);
