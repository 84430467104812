import React, { Component } from "react";
import { Row, Card, Col, Button, Form } from "reactstrap";
import Calendar from "./datepicker";
import * as common from "../Common/common";

class newCrops extends Component {
  state = {
    year: -1,
    month: -1,
    day: -1,
    cropName: "",
  };
  onChangeCalender = (event) => {
    this.setState({ [event.target.name]: parseInt(event.target.value) });
  };
  render() {
    const { crops } = this.props;
    const buttonStyle =
      this.state.year !== -1 && this.state.month !== -1 && this.state.day !== -1
        ? { disable: false }
        : { disable: true };
    return (
      <Row>
        <Col md={8} sm={12} xs={12}>
          <Card body id="new">
            <Form id="newCultivation">
              {crops && (
                <Calendar
                  year={{
                    min: this.props.cropRotations.min_year,
                    max: this.props.cropRotations.max_year,
                  }}
                  ymd={this.state}
                  onChangeCalender={this.onChangeCalender}
                  name={common.setCropName(this.state, this.props.crops.crops)}
                  monthday={common.monthday}
                />
              )}
              <Button
                color="warning"
                onClick={(e) =>
                  this.props.create(e, this.state)
                }
                disabled={buttonStyle.disable}
              >
                新しい耕起を追加
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default newCrops;
