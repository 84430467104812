import React, { Component } from "react";

import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { fetchCrops } from "../../Actions/crops";
import { API, CLIENT } from "../../consts_path";
import * as common from "../Common/common";
import { handleModal } from "../../Actions/common";

/*
 * 設定開始:   start_at
 * 植え付け日: pday
 * 収穫日:     dayhar
 * 設定終了:   end_at
 * */

class cropTbody extends Component {
  onDelete = (event, crop) => {
    event.preventDefault();
    const result = window.confirm(
      `" ${crop.base_year} ${crop.crop_detail} " を削除します。\nよろしいですか？`
    );
    if (!result) {
      return false;
    }
    const id = event.target.getAttribute("data-id");
    const { input, Crops, token } = this.props;
    const urlForCropsDelete = common.makeUrlShow(
      API.URL.cropdelete,
      input.id,
      id
    );
    fetch(urlForCropsDelete, {
      headers: {
        "X-CSRF-Token": token,
      },
      method: "DELETE",
      credentials: "same-origin",
    }).then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      const urlForCrops = common.makeUrlIndex(API.URL.crop, input.id);
      Crops(urlForCrops);
    });
    return true;
  };

  render() {
    const { crop, input, OpenModal, objectModal, index, arrCrops } = this.props;
    return (
      <tr>
        <td>
          {crop["is_valid?"] ? (
            ""
          ) : (
            <FaExclamationCircle style={{ color: "#F57F17" }} />
          )}
        </td>
        <td> {crop.base_year} </td>
        <td>
          <button
            type="button"
            className="buttonHref"
            onClick={(e) =>
              OpenModal(objectModal.isOpen, arrCrops.crops[index])
            }
          >
            {crop.crop_detail}
          </button>
        </td>
        <td>{common.addOneDay(crop.prev_end_date)}</td>
        <td>{crop.var_pday}</td>
        <td>{crop.var_dayhar}</td>
        <td>{crop.end_date}</td>
        <td>
          <Link
            to={common.makeUrlShow(CLIENT.URL.cropconfig, input.id, crop.id)}
          >
            <Button color="primary">{i18next.t("crops.thead.fix")}</Button>
          </Link>
        </td>
        <td>
          <Button
            color="danger"
            data-id={crop.id}
            onClick={(e) => this.onDelete(e, crop)}
          >
            {i18next.t("crops.thead.remove")}
          </Button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  objectModal: state.modal,
  arrCrops: state.crops,
});

const mapDispatchToProps = (dispatch) => ({
  Crops: (url) => dispatch(fetchCrops(url)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(cropTbody);
