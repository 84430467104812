import { CLIENT } from "../consts_path";

export const fetchFertilizationSuccess = (fertilization) => {
  return {
    type: "FETCH_FERTILIZATION_SUCCESS",
    fertilization,
  };
};

export const fetchShowSuccess = (show) => {
  return {
    type: "FETCH_SHOW_SUCCESS",
    show,
  };
};

export const loadFertilizations = (status) => {
  return {
    type: "LOAD_FERTILIZATIONS",
    isLoading: status,
  };
};

export const fetchFertilization = (url) => {
  return (dispatch) => {
    dispatch(loadFertilizations(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          // throw Error(responce.statusText);
          window.location.href = "/land_use";
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((fertilization) => {
        dispatch(fetchFertilizationSuccess(fertilization));
        dispatch(loadFertilizations(false));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const fetchShow = (url) => {
  return (dispatch) => {
    dispatch(loadFertilizations(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((show) => {
        dispatch(fetchShowSuccess(show));
        dispatch(loadFertilizations(false));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};
