import { CLIENT } from "../consts_path";

export const loadCultivations = (status) => {
  return {
    type: "LOAD_CULTIVATIONS",
    isLoading: status,
  };
};

export const fetchCultivationSuccess = (cultivation, variable) => {
  return {
    type: variable,
    cultivation,
  };
};

export const fetchCultivations = (url, variable) => {
  return (dispatch) => {
    dispatch(loadCultivations(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          window.location.href = "/land_use";
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((cultivation) => {
        dispatch(fetchCultivationSuccess(cultivation, variable));
        dispatch(loadCultivations(false));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const fetchCultivationShow = (url, variable) => {
  return (dispatch) => {
    dispatch(loadCultivations(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          window.location.href = "/land_use";
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((cultivation) => {
        dispatch(fetchCultivationSuccess(cultivation, variable));
        dispatch(loadCultivations(false));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};
