import React, { Component } from "react";
import ReactDom from "react-dom";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton/src/easy-button.css";
/*eslint no-unused-vars: 0*/
import easyButton from "leaflet-easybutton";

var featureGroup = null;
var center = [37.421762, 141.028143];
var dzoom = "10";
/**
 * マップオブジェクトの初期化
 */
function initializeMap(ref, results) {
  var tile = L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    {
      attribution:
        "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>",
    }
  );
  var map = L.map(ref, {
    zoom: dzoom,
    minZoom: "7",
    maxZoom: "16",
    center: center,
    layers: [tile],
  });
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  var markerList = results.lat.map((_, index) => {
    return L.marker([results.lat[index], results.lon[index]], {
      autoPan: true,
    }).addTo(map);
  });
  featureGroup = L.featureGroup(markerList);
  if (markerList.length !== 0) {
    map.fitBounds(featureGroup.getBounds());
    L.easyButton({
      states: [
        {
          stateName: "pan",
          icon: "fa-expand",
          title: "ズームレベルの初期化",
          onClick: function (control) {
            control._map.fitBounds(featureGroup.getBounds());
          },
        },
      ],
    }).addTo(map);
  }
  return { map, markerList };
}

function addPoint(map, latlong) {
  if (latlong) {
    var marker = L.marker(latlong, {}).addTo(map);
    map.setView(latlong, 18, { duration: 1 });
    map.removeLayer(marker);
  }
}

class GeoSearchMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = null;
    this.setMapRef = (element) => {
      this.mapRef = element;
    };
  }

  componentDidMount() {
    const { results } = this.props;
    const { map, markerList } = initializeMap(
      ReactDom.findDOMNode(this.mapRef),
      results
    );
    this.setState({ map, markerList });
  }

  componentDidUpdate(prevProps) {
    const { latlong, results } = this.props;
    const { map } = this.state;
    if (results.lat.length !== prevProps.results.lat.length) {
      this.addToMarker();
    }
    addPoint(map, latlong);
    map.invalidateSize();
  }

  addToMarker = () => {
    const { results } = this.props;
    const { map, markerList } = this.state;
    if (results.lat.length !== 0) {
      for (var i = 0; i < markerList.length; i++) {
        map.removeLayer(markerList[i]);
      }
      results.lat.map((_, index) => {
        return L.marker([results.lat[index], results.lon[index]], {
          autoPan: true,
        }).addTo(map);
      });
      map.fitBounds(featureGroup);
    } else {
      map.removeLayer(markerList[0]);
      map.setView(center, dzoom);
      this.setState({ markerList: null });
    }
  };
  render() {
    return <div ref={this.setMapRef} id="map" />;
  }
}
export default GeoSearchMap;
