import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Col } from "reactstrap";
import { fetchGeoLocation } from "../../Actions/landUse";
import { API } from "../../consts_path";
import { round } from "../Common/common";

class DataInfoChild extends Component {
  componentDidMount() {
    const { input, fetchGeo } = this.props;
    const urlForGeo = API.URL.geolocation.replace(API.REP.inputid, input.id);
    fetchGeo(urlForGeo);
  }
  render() {
    const { geoLocation } = this.props;
    return (
      <div>
        {geoLocation && (
          <span>
            <FormGroup row style={{ marginLeft: "0px" }}>
              <Label>
                <b>土壌種</b>
              </Label>
              <Col xs={3}>
                {/*<a
                  href={
                    makeUrlSoilInventory(
                      CLIENT.URL.soilinvetory,
                      toUpperCase(geoLocation.input.soil_code))
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                </a>*/}
                {geoLocation.input.soil_name}
              </Col>
              <Label>
                <b>位置情報</b>
              </Label>
              <Col>
                <span>
                  {round(geoLocation.input.latitude)},{" "}
                  {round(geoLocation.input.longitude)} (北緯東経)
                </span>
              </Col>
            </FormGroup>
            <hr />
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  geoLocation: state.geoLocation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGeo: (url, qry) => dispatch(fetchGeoLocation(url, qry)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataInfoChild);
