import React from "react";
import { connect } from "react-redux";
import { Row, Card, Col, Button, Form, FormGroup, Label } from "reactstrap";
// import { API } from '../../consts_path';
import { onChange } from "../../Actions/cropRotations";
import OptionCrop from "./optionCrop";
import MakeYearOption from "./makeYearOption";
import MakeCropsOption from "./makeCropsOption";
// import * as common from '../Common/common';

function newCrops({ style, onChangeValue, create, commonBtn }) {
  return (
    <Row>
      <Col md={8} sm={12} xs={12}>
        <Card body id="cropNew">
          <Form id="newCrops">
            <FormGroup row>
              <Label for="year" md={2} sm={12} xs={12}>
                収穫年:
              </Label>
              <MakeYearOption onchange={onChangeValue} />
            </FormGroup>
            <FormGroup row>
              <Label for="crops" md={2} sm={12} xs={12}>
                作物:
              </Label>
              <MakeCropsOption onchange={onChangeValue} />
            </FormGroup>
            <Button
              color={style.button_style.color}
              onClick={(e) => create(e)}
              disabled={style.button_style.disable}
            >
              新しい作物を追加
            </Button>
          </Form>
        </Card>
      </Col>
      <Col md={2} sm={12} xs={12}>
        <OptionCrop commonBtn={commonBtn} />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  input: state.input,
  style: state.newCropsChangeValue,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (e, value) => dispatch(onChange(e, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(newCrops);
