import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Col } from "reactstrap";

class makeYearOption extends Component {
  makeYearOptions = (min, max, string) => {
    const option = [];
    for (let n = max; n >= min; n -= 1) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    option.unshift(
      <option key={-1} id={-1} value={-1}>
        {string}
      </option>
    );
    return option;
  };
  render() {
    const { val, crops, onchange } = this.props;
    return (
      <Col md={5} sm={5} xs={5}>
        <Input
          type="select"
          name="years"
          id="years"
          value={val.years || -1}
          onChange={(e) => onchange(e, val)}
        >
          {this.makeYearOptions(
            crops.min_year,
            crops.max_year,
            crops.yearStr
          )}
        </Input>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  val: state.newCropsChangeValue,
  crops: state.crops,
});

export default connect(mapStateToProps)(makeYearOption);
