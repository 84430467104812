import { CLIENT } from "../consts_path";

export const fetchSeriesSuccess = (series) => ({
  type: "FETCH_SERIES_SUCCESS",
  series,
});

export const fetchDepthSuccess = (depth) => ({
  type: "FETCH_DEPTH_SUCCESS",
  depth,
});

export const fetchResultDataSuccess = (result) => ({
  type: "FETCH_RESULT_GRAPH_DATA_SUCCESS",
  result,
});

export const changeSeriesSuccess = (data) => ({
  type: "CHANGE_SERIES_SUCCESS",
  data,
});

export const changeSeriesSuccess2 = (data) => ({
  type: "CHANGE_SERIES_SUCCESS2",
  data,
});

export const changeDepthSuccess = (depth) => ({
  type: "CHANGE_DEPTH_SUCCESS",
  depth,
});

export const selectedDaySuccess = (day) => ({
  type: "SELECTED_DAY_SUCCESS",
  day,
});

export const fetchSeries = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => responce.json())
      .then((series) => {
        dispatch(fetchSeriesSuccess(series));
      })
      .catch((error) => console.log(error));
  };
};

export const fetchDepth = (url) => {
  return (dispatch) => {
    dispatch(loadGraphData(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => responce.json())
      .then((depth) => {
        dispatch(fetchDepthSuccess(depth));
        dispatch(loadGraphData(false));
      })
      .catch((error) => console.log(error));
  };
};

export const loadGraphData = (isLoading) => ({
  type: "LOAD_GRAPH_DATA",
  isLoading,
});

export const fetchResult = (url) => {
  return (dispatch) => {
    // dispatch(loadGraphData(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => responce.json())
      .then((result) => {
        dispatch(fetchResultDataSuccess(result));
        // dispatch(loadGraphData(false));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        window.location.href = CLIENT.URL.root;
      });
  };
};

export const onChangeSeries = (data) => {
  return (dispatch) => {
    dispatch(changeSeriesSuccess(data));
  };
};

export const onChangeSeries2 = (data) => {
  return (dispatch) => {
    dispatch(changeSeriesSuccess2(data));
  };
};

export const onChangeDepth = (depth) => {
  return (dispatch) => {
    // XXX
    depth = depth === null ? [] : depth;
    dispatch(changeDepthSuccess(depth));
  };
};

export const onSelectedDay = (day) => {
  return (dispatch) => {
    dispatch(selectedDaySuccess(day));
  };
};

export const clearStateGraph = (data) => ({
  type: "CLEAR_STATE",
  data,
});
export const clearState = () => {
  return (dispatch) => {
    dispatch(clearStateGraph(null));
  };
};
