import React from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const CollapseArrowIcon = ({ collapse, toggle }) => (
  <center style={{ borderTop: "1px solid #cdcdcd" }}>
    <button
      className="buttonHref"
      style={{ fontSize: "1.3em" }}
      onClick={(e) => toggle(e)}
    >
      {collapse === true ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
    </button>
  </center>
);

export default CollapseArrowIcon;
