import React, { Component } from "react";
import { Input, Col, Label, FormGroup, FormFeedback } from "reactstrap";
import { FaMinus } from "react-icons/fa";

class Calendar extends Component {
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  makeOption = (min, max) => {
    const option = [];
    for (let n = min; n <= max; n += 1) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };

  makeYOption = (min, max) => {
    const option = [];
    for (let n = max; n >= min; n -= 1) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };

  render() {
    const {
      monthday,
      idate,
      cumt,
      year,
      labelFor,
      data,
      onChangeCalender,
      ChangeTitle,
      title,
    } = this.props;
    const lastdayIdate = monthday(idate[0], parseInt(idate[1], 10));
    const lastdayCumt = monthday(cumt[0], parseInt(cumt[1], 10));
    const optionYear = this.makeYOption(year.min, year.max, "年");
    const optionMonth = this.makeOption(1, 12, "月");
    const optionDayIdate = this.makeOption(1, lastdayIdate, "日");
    const optionDayCumt = this.makeOption(1, lastdayCumt, "日");
    return (
      <div>
        <FormGroup row>
          <Label for={labelFor} md="2" sm="3" xs="12">
            計算名：
          </Label>
          <Col md="6" sm="9" xs="8" style={{ margin: "0px 0px" }}>
            <Input
              type="text"
              name="title"
              value={title}
              placeholder="計算名を入力してください"
              onChange={(e) => ChangeTitle(e, data)}
              invalid={data.invalid}
            />
            {data.invalid && (
              <FormFeedback>計算名を設定してください。</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for={labelFor} md="2" sm="3" xs="12">
            計算開始日：
          </Label>
          <Col md="6" sm="9" xs="12" style={{ margin: "0px 0px" }}>
            <FormGroup row>
              <Col sm="auto" xs="4" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_idate"
                  data-which-one="y"
                  value={idate[0]}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionYear}
                </Input>
              </Col>
              <span>
                <FaMinus />
              </span>
              <Col sm="auto" xs="3" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_idate"
                  data-which-one="m"
                  value={`${Number(idate[1])}`}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionMonth}
                </Input>
              </Col>
              <span>
                <FaMinus />
              </span>
              <Col sm="auto" xs="3" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_idate"
                  data-which-one="d"
                  value={`${Number(idate[2])}`}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionDayIdate}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for={labelFor} md="2" sm="3" xs="12">
            計算終了日：
          </Label>
          <Col md="6" sm="9" xs="12" style={{ margin: "0px 0px" }}>
            <FormGroup row>
              <Col sm="auto" xs={4} style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_cumt1"
                  data-which-one="y"
                  value={cumt[0]}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionYear}
                </Input>
              </Col>
              <span>
                <FaMinus />
              </span>
              <Col sm="auto" xs={3} style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_cumt1"
                  data-which-one="m"
                  value={`${Number(cumt[1])}`}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionMonth}
                </Input>
              </Col>
              <span>
                <FaMinus />
              </span>
              <Col sm="auto" xs={3} style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  name="var_cumt1"
                  data-which-one="d"
                  value={`${Number(cumt[2])}`}
                  onChange={(e) => onChangeCalender(e, data)}
                >
                  {optionDayCumt}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default Calendar;
