import React from "react";
import { Table } from "reactstrap";
import CalculateTbody from "./Tbody";
import { VISUAL } from "../../consts_path";
import { Spinner } from "../iconSpinner";

const CalculateTable = ({
  results,
  duplication,
  onPoint,
  isLoading,
  deleteResult,
}) => (
  <Table hover>
    <thead>
      <tr>
        <th>#</th>
        <th>ステータス</th>
        <th>
          計算開始
          <br />
          終了日時
        </th>
        <th>
          計算名
          <br />
          土壌名
        </th>
        <th>
          位置情報
          <br />
          (東経北緯)
        </th>
        <th>評価期間</th>
        <th
          style={VISUAL.GRAPH ? { display: "none" } : { display: "table-cell" }}
        >
          計算条件
        </th>
        <th
          style={
            !VISUAL.GRAPH ? { display: "none" } : { display: "table-cell" }
          }
        >
          可視化
        </th>
        <th>再計算</th>
        <th>
          ダウンロード
          <br />
          (ZIP形式)
        </th>
        <th>削除</th>
      </tr>
    </thead>
    <tbody>
      {isLoading ? (
        <tr>
          <td colSpan="9" className="text-center">
            <Spinner />
          </td>
        </tr>
      ) : results.length !== 0 ? (
        results.map((result, i) => {
          return (
            <CalculateTbody
              key={i}
              result={result}
              index={i}
              duplication={duplication}
              onPoint={onPoint}
              flag={results.flag[i]}
              statusFlag={results.statusFlag[i]}
              deleteResult={deleteResult}
            />
          );
        })
      ) : (
        <tr>
          <td colSpan={10} style={{ textAlign: "center" }}>
            <h4>現在、あなたの計算履歴はありません。</h4>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default CalculateTable;
