export const getCropsError = (status) => {
  return {
    type: "GET_CROPS_ERROR",
    hasError: status,
  };
};

export const loadCrops = (status) => {
  return {
    type: "LOAD_CROPS",
    isLoading: status,
  };
};

export const fetchVisItemsSuccess = (items) => {
  return {
    type: "FETCH_VIS_TIMELINE_SUCCESS",
    items,
  };
};

export const fetchVisItems = (url) => {
  return (dispatch) => {
    dispatch(loadCrops(true));

    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        dispatch(loadCrops(false));
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((items) => {
        dispatch(fetchVisItemsSuccess(items));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};
