import { CLIENT } from "../consts_path";
export const getCropRotationsError = (status) => {
  return {
    type: "GET_CROP_ROTATIONS_ERROR",
    hasError: status,
  };
};

export const loadCropRotations = (status) => {
  return {
    type: "LOAD_CROP_ROTATIONS",
    isLoading: status,
  };
};

export const fetchCropRotationsSuccess = (cropRotations) => {
  return {
    type: "FETCH_CROP_ROTATIONS_SUCCESS",
    cropRotations,
  };
};

export const onClickCheck = (checked) => {
  return {
    type: "CHECK_SUCCESS",
    checked,
  };
};

export const onChangeSuccess = (target, value) => {
  return {
    type: "ON_CHANGE_NEW_SUCCESS",
    target,
    value,
  };
};

export const fetchCropRotations = (url) => {
  return (dispatch) => {
    dispatch(loadCropRotations(true));

    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        dispatch(loadCropRotations(false));
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((cropRotations) => {
        dispatch(fetchCropRotationsSuccess(cropRotations));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const onClickCheckBox = (event, selections, rotations) => {
  return (dispatch) => {
    const { target } = event;
    const assignS = Object.assign(selections);
    const assignR = Object.assign(rotations);
    dispatch(onClickCheck({ target, assignS, assignR }));
  };
};

export const onChange = (event, value) => {
  return (dispatch) => {
    const { target } = event;
    dispatch(onChangeSuccess(target, value));
  };
};
