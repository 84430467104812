import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import TPLOT from "./tplot";

const flatten = (xs) =>
  xs.reduce(
    (acc, e) => (Array.isArray(e) ? acc.concat(flatten(e)) : acc.concat(e)),
    []
  );

const dataMap = (data) => {
  return data.map((d, index) => {
    return d.value;
  });
};

const makeQuery = (depth_id, series_vl) => {
  return depth_id.map((d, index) => {
    if (series_vl.length === 1) {
      return `${series_vl[0]}-${d}`;
    } else {
      var series_and_depth = `${series_vl[0]}-${d},${series_vl[1]}-${d}`;
      series_and_depth = series_and_depth.split(/,/);
      return series_and_depth;
    }
  });
};

const filter = (query) => {
  var flux = [];
  var val;
  return query.filter((v) => {
    if (v.match(/99/)) {
      if (v.match(/flux/)) {
        flux.push(v);
      }
    } else {
      val = v;
    }
    if (val) {
      return val.concat(flux);
    } else {
      return flux;
    }
  });
};

const makePlotData = (selectedDepth, selectedSeries) => {
  const depth_id = dataMap(selectedDepth);
  const series_vl = dataMap(selectedSeries);
  var query = makeQuery(depth_id, series_vl);
  query = flatten(query);
  return filter(query);
};

class TimeLine extends Component {
  render() {
    const { selectedDepth, selectedSeries, graphData } = this.props;
    const output_id = graphData.output.id; //s.calculations[0].output.id;
    return (
      <div>
        <Row>
          <Col sd="12">
            <TPLOT
              plotDate={
                selectedSeries
                  ? makePlotData(selectedDepth, selectedSeries)
                  : []
              }
              outputId={output_id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // series: state.series,
  selectedSeries: state.change_success,
  selectedDepth: state.change_depth_success,
  graphData: state.graphData,
});

export default connect(mapStateToProps)(TimeLine);
