import React, { Component } from "react";
import { Table } from "reactstrap";
import i18next from "i18next";
import FertilizationTbody from "./TBody";
import { Spinner } from "../iconSpinner";

class FertilizationTable extends Component {
  render() {
    const headers = [
      "",
      i18next.t("crops.thead.annual"),
      i18next.t("fertilization.thead.dfer"),
      i18next.t("crops.thead.crop"),
      i18next.t("crops.common.cultivation_start"),
      i18next.t("crops.common.cultivation_end"),
      i18next.t("crops.thead.fix"),
      i18next.t("crops.thead.remove"),
    ];
    const { isLoading, fertilization, fetchData, token } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            {(() => {
              return headers.map((header, i) => <th key={i}> {header} </th>);
            })()}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="9" className="text-center">
                <Spinner />
              </td>
            </tr>
          ) : (
            fertilization.map((fertilization, i) => (
              <FertilizationTbody
                fetchData={fetchData}
                fertilization={fertilization}
                key={i}
                token={token}
              />
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default FertilizationTable;
