import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { onChangeSeries } from "../../Actions/graph";

class Series extends Component {
  state = {
    selectOptions: [],
  };
  render() {
    const { selectOptions } = this.state;
    const { ChangeSeries, series } = this.props;
    return (
      <Select
        isMulti
        options={
          selectOptions.length === 2 ? selectOptions : series.field_options
        }
        isLading={true}
        onChange={(e) => {
          this.setState({ selectOptions: e || [] });
          ChangeSeries(e);
        }}
        name="series"
        className="seriesSelect"
        placeholder="データの種類を選択してください"
        classNamePrefix="select"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  series: state.series,
});

const mapDispatchToProps = (dispatch) => ({
  ChangeSeries: (e) => dispatch(onChangeSeries(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Series);
