import React, { Component } from "react";
import { connect } from "react-redux";

import * as common from "../Common/common";
import PageController from "../PageController/index";
import BreadCrumb from "../BreadCrumb/index";
import { fetchConfirm } from "../../Actions/confirm";
import { fetchCropRotations } from "../../Actions/cropRotations";
import { onChangeCalender } from "../../Actions/crops";
import { onChangeTitle } from "../../Actions/confirm";
import { getToken } from "../../Actions/common";
import Calendar from "./datepicker";
import { API, CLIENT } from "../../consts_path";

class Confirm extends Component {
  componentDidMount() {
    const { input, ConfirmData, CropRotationsData, getToken } = this.props;
    const urlForConfirm = API.URL.confirm.replace(":input_id", input.id);
    const urlForCroprotation = API.URL.croprotation.replace(
      ":input_id",
      input.id
    );
    ConfirmData(urlForConfirm);
    CropRotationsData(urlForCroprotation);
    getToken();
  }

  calculation = (url) => {
    const { input, history, csrfToken } = this.props;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      history.push(common.makeUrlIndex(CLIENT.URL.result, input.id));
    });
  };

  zeroPadding = (num, length) => {
    return `00${num}`.slice(length);
  };

  update = (event, data) => {
    event.preventDefault();
    const form = new FormData();
    const { input, csrfToken } = this.props;
    const url = common.makeUrlIndex(API.URL.confirm, input.id);

    Object.keys(data).map((key) => {
      if (key !== "title" && key !== "id") {
        const crSp = key.match(/var_/)
          ? data[key].toString().split(/-/)
          : [data[key]];
        const crArr =
          crSp.length > 1
            ? [
                crSp[0],
                this.zeroPadding(crSp[1], -2),
                this.zeroPadding(crSp[2], -2),
              ]
            : crSp;
        return form.append(`input[${key}]`, crArr.join(""));
      } else {
        return form.append(`input[${key}]`, data[key]);
      }
    });
    if (common.isEmpty(data.title)) {
      return false;
    }
    fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        alert("地点選択からやり直してください。");
        //throw Error((window.location = CLIENT.URL.root));
      } else {
        const urlRes = common.makeUrlIndex(API.URL.result, input.id);
        this.calculation(urlRes);
      }
    });
  };

  render() {
    const {
      input,
      cropRotations,
      confirm,
      ChangeCalender,
      ChangeTitle,
    } = this.props;
    const assign = Object.assign({}, common.pageController);
    assign.display = { display: "block" };
    assign.url = [
      common.makeUrlIndex(CLIENT.URL.cultivation, input.id),
      common.makeUrlIndex(CLIENT.URL.result, input.id),
    ];
    assign.value = ["戻る", "計算実行"];
    return (
      <div>
        <BreadCrumb
          landuse={0}
          irrigate={0}
          partition={0}
          parameter={0}
          crop={0}
          fertilization={0}
          cultivation={0}
          confirm={1}
        />
        <br />
        {cropRotations && confirm && (
          <div>
            <Calendar
              year={{
                min: cropRotations.min_year,
                max: cropRotations.max_year,
              }}
              cumt={confirm.var_cumt1.split(/-/)}
              idate={confirm.var_idate.split(/-/)}
              title={confirm.title}
              data={confirm}
              onChangeCalender={ChangeCalender}
              monthday={common.monthday}
              ChangeTitle={ChangeTitle}
            />
            <PageController
              assign={assign}
              serverSendFunction={(event) => this.update(event, confirm)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  confirm: state.confirm,
  cropRotations: state.cropRotations,
  calender: state.calender,
  input: state.input,
  title: state.title,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  ConfirmData: (url) => dispatch(fetchConfirm(url)),
  CropRotationsData: (url) => dispatch(fetchCropRotations(url)),
  ChangeCalender: (event, data) => dispatch(onChangeCalender(event, data)),
  ChangeTitle: (event, data) => dispatch(onChangeTitle(event, data)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
