export const fetchResultDataSuccess = (result) => {
  return {
    type: "FETCH_RESULT_DATA_SUCCESS",
    result,
  };
};

export const fetchMarkDownSuccess = (markdown) => {
  return {
    type: "FETCH_MARKDOWN_SUCCESS",
    markdown,
  };
};

export const fetchCalcResSuccess = (calcRes) => {
  return {
    type: "FETCH_CALC_RES_SUCCESS",
    calcRes,
  };
};

export const loadResult = (status) => {
  return {
    type: "LOAD_RESULT",
    isLoading: status,
  };
};

export const mapOnPointSuccess = (res) => {
  return {
    type: "MAP_ON_CLICK_SUCCESS",
    res,
  };
};

export const fetchResults = (url) => {
  return (dispatch) => {
    dispatch(loadResult(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((result) => {
        dispatch(fetchResultDataSuccess(result));
        dispatch(loadResult(false));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const mapOnPoint = (value) => {
  return (dispatch) => {
    dispatch(mapOnPointSuccess(value));
  };
};

export const fetchMarkDown = (url) => {
  return (dispatch) => {
    dispatch(loadResult(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        dispatch(loadResult(false));
        return responce;
      })
      .then((responce) => {
        return responce.text();
      })
      .then((markdown) => {
        dispatch(fetchMarkDownSuccess(markdown));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const fetchCalcRes = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((calc) => {
        dispatch(fetchCalcResSuccess(calc));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};
