import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import i18next from "i18next";

class ShowModal extends Component {
  render() {
    const headers = [
      "耕起年月日",
      `${i18next.t("cultivation.showEdit.cultdep")}（${i18next.t(
        "extension.mm"
      )}）`,
    ];
    const keys = ["var_dcult", "var_cultdep"];
    const { isOpen, OpenModal, crop } = this.props;
    return (
      <Modal isOpen={isOpen} size="lg" toggle={(e) => OpenModal(isOpen, crop)}>
        <ModalHeader toggle={(e) => OpenModal(isOpen, crop)}>
          {crop.crop.crop_detail}
          <br />
          {crop.cult_name}
        </ModalHeader>
        <ModalBody>
          <Table bordered size="sm">
            <tbody>
              {(() => {
                return headers.map((header, index) => (
                  <tr key={header}>
                    <th>{index + 1}</th>
                    <th>{header}</th>
                    {(() => {
                      return <td>{crop[keys[index]]}</td>;
                    })()}
                  </tr>
                ));
              })()}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

export default ShowModal;
