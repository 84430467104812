import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

import NavItems from "./navItems";
import TabContents from "./tabContents";
import { API, GRAPHAPI } from "../../consts_path";
import * as common from "../Common/common";
import { fetchSeries, fetchDepth, fetchResult } from "../../Actions/graph";
import { Spinner } from "../iconSpinner";
import { onChangeDepth } from "../../Actions/graph";

class Graph extends Component {
  state = { activeTab: "1", calendarActive: false };
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  componentDidMount() {
    const { match, Series, Result, Depth } = this.props;
    const calc_id = match.params.calculation_id;
    const output_id = match.params.output_id;
    // /api/calculations/:calculation_id
    const urlForGraph = common.makeUrlGraph(API.URL.calculation, calc_id);
    // api/outputs/:output_id/segmental_results/field_options
    const urlForFiled = common.makeVisualPartsUrl(
      GRAPHAPI.URL.fieldoptions,
      output_id
    );
    // api/outputs/:output id/segmental_results/segmental_options
    const urlForDepth = common.makeVisualPartsUrl(
      GRAPHAPI.URL.segmentoptions,
      output_id
    );
    Series(urlForFiled);
    Result(urlForGraph);
    Depth(urlForDepth);
  }
  render() {
    const { graphData, isLoading, ChangeDepth } = this.props;
    const { activeTab } = this.state;
    return (
      <div id="plot">
        <Row>
          <Col sm={12}>
            <div />
            <NavItems activeTab={activeTab} toggle={this.toggle} />
            {isLoading && (
              <span className="text-center">
                <Spinner />
              </span>
            )}
            {!isLoading && graphData && <TabContents activeTab={activeTab} />}
          </Col>
        </Row>
        <a
          href="/#/result"
          onClick={() => {
            ChangeDepth([]);
          }}
        >
          <Button color={"warning"}>
            <FaArrowLeft />
            計算結果一覧に戻る
          </Button>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  graphData: state.graphData,
  isLoading: state.loadGraph,
});

const mapDispatchToProps = (dispatch) => ({
  Series: (url) => dispatch(fetchSeries(url)),
  Depth: (url) => dispatch(fetchDepth(url)),
  Result: (url) => dispatch(fetchResult(url)),
  ChangeDepth: (e) => dispatch(onChangeDepth(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
