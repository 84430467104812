import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as common from "../Common/common";

import { CLIENT } from "../../consts_path";

class OptionCrop extends React.Component {
  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };

  render() {
    const { input } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={this.toggle}
        id="common_crop_config"
      >
        <DropdownToggle caret>一括選択と共通設定</DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            <Link to={common.makeUrlIndex(CLIENT.URL.croprotation, input.id)}>
              作物の一括選択
            </Link>
          </DropdownItem>
          <DropdownItem header>
            <Link to={common.makeUrlIndex(CLIENT.URL.cropcommon, input.id)}>
              全作物共通設定
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
});

export default connect(mapStateToProps)(OptionCrop);
