import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { connect } from "react-redux";
import BreadCrumb from "../BreadCrumb/index";
import { fetchShow } from "../../Actions/fertilization";
import { fetchCropRotations } from "../../Actions/cropRotations";
import {
  onChangeTextValue,
  onChangeCalender,
  onClickCheckbox,
} from "../../Actions/crops";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import TextBox from "./FormGroup/TextBox";
import Calender from "./FormGroup/datepicker";
import { makeUrlShow, makeUrlIndex } from "../Common/common";
import { API, CLIENT } from "../../consts_path";
import { Spinner } from "../iconSpinner";
import { getToken } from "../../Actions/common";

const splitter = (str, fertilization) => {
  return fertilization[str].toString().split(/-/);
};

class FertilizationConfig extends Component {
  componentDidMount() {
    const { input, match, getToken, fetchShow, fetchData } = this.props;
    const urlForFert = makeUrlShow(
      API.URL.fertilizationdelete,
      input.id,
      match.params.id
    );
    const urlForCropRotation = makeUrlIndex(API.URL.croprotation, input.id);
    fetchShow(urlForFert);
    fetchData(urlForCropRotation);
    getToken();
  }
  zeroPadding = (num, length) => {
    return ("00" + num).slice(length);
  };
  update = (event, fertilization) => {
    event.preventDefault();
    const { input, show, history, csrfToken } = this.props;
    const url = makeUrlShow(
      API.URL.fertilizationupdate,
      input.id,
      fertilization.id
    );
    const form = new FormData();
    show.ferUpdateKey.map((key) => {
      const ferSp = splitter(key, fertilization);
      const ferArr =
        ferSp.length > 1
          ? [
              ferSp[0],
              this.zeroPadding(ferSp[1], -2),
              this.zeroPadding(ferSp[2], -2),
            ]
          : ferSp;
      form.append(`fertilization[${key}]`, ferArr.join(""));
      return true;
    });
    if (show.fertilization.copy_fertilizations) {
      form.append(
        `fertilization[copy_fertilizations]`,
        show.fertilization.copy_fertilizations
      );
    }
    fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        // window.location.href = makeUrlIndex(CLIENT.URL.fertilization, input.id);
        history.push(makeUrlIndex(CLIENT.URL.fertilization, input.id));
      })
      .catch((error) => console.log(error));
  };
  render() {
    const labeles = [
      i18next.t("fertilization.showEdit.dep"),
      i18next.t("fertilization.showEdit.fer"),
      i18next.t("fertilization.showEdit.nh4N"),
      i18next.t("fertilization.showEdit.no3N"),
      i18next.t("fertilization.showEdit.residueN"),
      i18next.t("fertilization.showEdit.residueC"),
      i18next.t("fertilization.showEdit.manureN"),
      i18next.t("fertilization.showEdit.manureC"),
    ];
    const extension = [
      i18next.t("extension.mm"),
      i18next.t("extension.kgN"),
      i18next.t("extension.kgN"),
      i18next.t("extension.kgN"),
      i18next.t("extension.kgN"),
      i18next.t("extension.kgC"),
      i18next.t("extension.kgN"),
      i18next.t("extension.kgC"),
    ];
    const comment = [
      i18next.t("fertilization.comment.dep"),
      i18next.t("fertilization.comment.fer"),
      i18next.t("fertilization.comment.nh4N"),
      i18next.t("fertilization.comment.no3N"),
      i18next.t("fertilization.comment.residueN"),
      i18next.t("fertilization.comment.residueC"),
      i18next.t("fertilization.comment.manureN"),
      i18next.t("fertilization.comment.manureC"),
    ];
    const { show, crops, isLoading } = this.props;
    return (
      <div>
        <BreadCrumb
          landuse={0}
          irrigate={0}
          partition={0}
          parameter={0}
          crop={0}
          fertilization={1}
        />
        <br />
        {isLoading ? (
          <Spinner />
        ) : (
          show &&
          crops && (
            <div>
              <Row>
                <Col xs={6} style={{ marginBottom: "0px" }}>
                  <h4>{`${show.fertilization.crop.crop_detail}`}</h4>
                  <h4>{`${show.fertilization.fert_name}のイベント設定`}</h4>
                </Col>
                <Col xs={6} style={{ marginBottom: "0px" }}>
                  <small id="help">
                    <FaQuestionCircle />
                    にマウスカーソル（矢印）を重ねるとヘルプが表示されます。
                  </small>
                </Col>
              </Row>
              <hr />
              <Form className="fertilization_config">
                {crops && (
                  <Calender
                    year={{
                      min: this.props.crops.min_year,
                      max: this.props.crops.max_year,
                    }}
                    labelFor="var_dfer"
                    fertilization={show.fertilization}
                    dateArr={
                      show.fertilization.var_dfer
                        ? show.fertilization.var_dfer.split(/-/)
                        : [this.props.crops.max_year, 1, 1]
                    }
                    label={i18next.t("fertilization.showEdit.fdate")}
                    comment={i18next.t("fertilization.comment.fdate")}
                    onChangeCalender={this.props.onChangeCalender}
                  />
                )}
                {labeles.map((l, index) => (
                  <TextBox
                    key={index}
                    label={l}
                    value={show.ferKey[index]}
                    comment={comment[index]}
                    fertilization={show.fertilization}
                    onChangeTextValue={this.props.onChangeTextValue}
                    step={1}
                    ext={extension[index]}
                  />
                ))}

                <FormGroup row>
                  <Col sm={5} style={{ margin: "10px" }}>
                    <Label for="check">
                      <Input
                        type="checkbox"
                        name="copy_fertilizations"
                        id="check"
                        onClick={(e) =>
                          this.props.onClickCheckbox(e, show.fertilization)
                        }
                      />
                      {`${i18next.t("fertilization.showEdit.check")}`}
                    </Label>
                  </Col>
                </FormGroup>
                <Row>
                  <Col xs={"auto"}>
                    <Button
                      color="success"
                      onClick={(event) =>
                        this.update(event, show.fertilization)
                      }
                    >
                      更新
                    </Button>
                  </Col>
                  <Col xs={"auto"}>
                    <Link
                      to={makeUrlIndex(
                        CLIENT.URL.fertilization,
                        this.props.input.id
                      )}
                    >
                      <Button type="button" size="" color="secondary">
                        キャンセル
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          )
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  show: state.fertshow,
  crops: state.cropRotations,
  textbox: state.textbox,
  calender: state.calender,
  checkbox: state.checkbox,
  input: state.input,
  isLoading: state.loadFertilizations,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  fetchShow: (url) => dispatch(fetchShow(url)),
  fetchData: (url) => dispatch(fetchCropRotations(url)),
  onChangeTextValue: (event, crop) => dispatch(onChangeTextValue(event, crop)),
  onChangeCalender: (event, crop) => dispatch(onChangeCalender(event, crop)),
  onClickCheckbox: (event, crop) => dispatch(onClickCheckbox(event, crop)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FertilizationConfig);
