import React, { Component } from "react";
import { connect } from "react-redux";
import vis from "vis-timeline";
import "vis-timeline/dist/vis-timeline-graph2d.css";
import "./original_vis_timeline.css";
import {
  FaPlusCircle,
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaMinusCircle,
  FaExpand,
} from "react-icons/fa";
import { OPTIONS } from "./timeLine_const";
import { Row, Col, Collapse } from "reactstrap";
import CollapseArrowIcon from "../CollapseArrowIcon/index";
// import { VISUAL } from '../../consts_path';

function initializeVis(timelines) {
  // DOM element where the Timeline will be attached
  var container = document.getElementById("timeline");

  // Create a DataSet (allows two way data-binding)
  var item = new vis.DataSet(timelines.items);
  var group = timelines.groups;
  // Configuration for the Timeline
  var options = OPTIONS;
  // Create a Timeline
  var timeline = new vis.Timeline(container, item, group, options);

  function move(percentage) {
    var range = timeline.getWindow();
    var interval = range.end - range.start;

    timeline.setWindow({
      start: range.start.valueOf() - interval * percentage,
      end: range.end.valueOf() - interval * percentage,
    });
  }

  function onMouseover(event) {
    var prop = timeline.getEventProperties(event);
    if (prop.what != null) {
      // select item when itself on mouseover
      if (prop.what === "item") {
        timeline.setSelection(prop.item);
        event.stopPropagation();
      }
    }
  }

  container.addEventListener("mouseover", onMouseover);
  document.getElementById("zoomIn").onclick = function () {
    timeline.zoomIn(0.2);
  };
  document.getElementById("zoomOut").onclick = function () {
    timeline.zoomOut(0.2);
  };
  document.getElementById("moveLeft").onclick = function () {
    move(0.2);
  };
  document.getElementById("moveRight").onclick = function () {
    move(-0.2);
  };
  document.getElementById("reset").onclick = function () {
    timeline.fit();
  };

  return timeline;
}

class VisTimeLine extends Component {
  state = {
    collapse: true,
  };
  componentDidMount() {
    const { timeline } = this.props;
    // VISUAL.GRAPH &&
    null && timeline && initializeVis(timeline);
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };
  render() {
    const { collapse } = this.state;
    return (
      <div>
        {
          // VISUAL.GRAPH &&
          null && (
            <div>
              <Collapse isOpen={collapse} style={{ width: "100%" }}>
                <div id="timeline" />
                <div className="menu">
                  <Row>
                    <Col sm={4}>
                      <FaPlusCircle id="zoomIn" />
                      <FaMinusCircle id="zoomOut" />
                    </Col>
                    <Col sm={4}>
                      <FaArrowCircleLeft id="moveLeft" />
                      <FaArrowCircleRight id="moveRight" />
                    </Col>
                    <Col sm={4}>
                      <FaExpand id="reset" style={{ float: "right" }} />
                    </Col>
                  </Row>
                </div>
              </Collapse>
              <CollapseArrowIcon collapse={collapse} toggle={this.toggle} />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
});

export default connect(mapStateToProps)(VisTimeLine);
