import { CLIENT } from "../consts_path";

export const fetchConfirmDataSuccess = (confirm) => {
  return {
    type: "FETCH_CONFIRM_DATA_SUCCESS",
    confirm,
  };
};

export const eYear = (year) => {
  return {
    type: "eYear_SUCCESS",
    year,
  };
};

export const title = (value, data) => {
  return {
    type: "ONCHANGE_TITLE",
    value,
    data,
  };
};

export const fetchConfirm = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((confirm) => {
        dispatch(fetchConfirmDataSuccess(confirm));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        // window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const onChangeTitle = (e, data) => {
  return (dispatch) => {
    dispatch(title(e.target.value, data));
  };
};
