export const input = (
  state = {
      id: null,
      latitude: null,
      longitude: null,
      crop_rotations: null,
      start_year: null,
      end_year: null,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_INITIAL_INPUT_SUCCESS":
      return action.input;
    case "FETCH_INPUT_SUCCESS":
      const obj = {};
      obj.id = action.input.id;
      obj.latitude = action.input.latitude;
      obj.longitude = action.input.longitude;
      return obj
    default:
      return state;
  }
};

export const landUseData = (state = null, action) => {
  switch (action.type) {
    case "FETCH_DATA_LANDUSE_SUCCESS":
      switch (action.data.input.land_use_id) {
        case null: {
          const copyObject = Object.assign({}, action.data);
          // TODO option-uudaas hamgiin bagiig ni songoh hereggui.
          // const idList = [];
          // for (var i in action.data.land_uses) {
          //   idList.push(action.data.land_uses[i].id);
          // }
          // copyObject.input.land_use_id = Math.min(...idList);
          copyObject.input.land_use_id = "";
          return copyObject;
        }
        default:
          return action.data;
      }
    case "LAND_USE": {
      const copyObject = Object.assign({}, action.landUse);
      copyObject.input.land_use_id = action.value;
      return Object.assign({}, copyObject);
    }
    default:
      return state;
  }
};

export const updateGeoLocation = (state = null, action) => {
  switch (action.type) {
    case "BACKTO":
    case "UPDATE_GEO": {
      return action.status;
    }
    default: {
      return state;
    }
  }
};

export const geoLocation = (state = null, action) => {
  switch (action.type) {
    case "FETCH_GEO_LOCATION_SUCCESS": {
      if (action.qry) {
        const copyObject = Object.assign(action.gl);
        // copyObject.input.latitude = action.qry.lat;
        // copyObject.input.longitude = action.qry.lon;
        // // CO2サイトから送信されるsoil_codeは、英字が大文字になっている。
        // // しかし、DB上に保存されているsoil_codeは、小文字になっているので
        // // toLowerCase関数で大文字から小文字に変換を行う。
        // copyObject.input.mesh_code = action.qry.mcode;
        copyObject.input.soil_code = action.qry.soil_code.toLowerCase();
        // copyObject.input.soil_name = action.qry.soil_name;
        // null 対策として初期値を念のために設定
        copyObject.time_out = null;
        // updateGeo(copyObject, action.qry.input_id)
        return copyObject;
      }
      if (action.gl.input.latitude) {
        return action.gl;
      }
      const copy = Object.assign({}, action.gl);
      copy.time_out = true;
      return copy;
    }
    case "FETCH_GEO_LOCATION_ERROR": {
      const copyError = Object.assign({}, action.gl);
      copyError.error = true;
      return copyError;
    }
    default: {
      return state;
    }
  }
};

export const nearestSitesData = (state = null, action) => {
  switch (action.type) {
    case "FETCH_NEAREST_SITES":
      return action.data;
    default:
      return state;
  }
}

export const weather = (state = null, action) => {
  switch (action.type) {
    case "FETCH_WEATHER":
      const copyWeather = Object.assign({}, action.weather);
      if (action.value) {
        const site_values = action.value.split(',');
        copyWeather.input.weather_station_type_id = parseInt(site_values[6] === "1" ? 1 : 0, 10);
        copyWeather.weatherKey = copyWeather.input.weather_station_type_id === 0 ? "amedas" : "kansho";
        copyWeather.input.weather_station_id = site_values[1];
        // used form select form
        copyWeather.input.weather_station_value = action.value;
      }
      copyWeather.isDis = copyWeather.input.et0_coefficient_type_id !== -1;
      return copyWeather;
    case "NEAREST_SITE":
      const copy = Object.assign({}, action.landUse);
      const site_values = action.value.split(',');
      copy.input.weather_station_type_id = parseInt(site_values[6] === "1" ? 1 : 0, 10);
      copy.weatherKey = copy.input.weather_station_type_id === 0 ? "amedas" : "kansho";
      copy.input.weather_station_id = site_values[1];
      // used form select form
      copy.input.weather_station_value = action.value;
      return copy;
    case "REGION": {
      const copy = Object.assign({}, action.landUse);
      copy.input.weather_region_id = parseInt(action.value);
      copy.weatherKey =
        copy.input.weather_station_type_id === 0 ? "amedas" : "kansho";
      copy.all_weather_stations[copy.weatherKey].map((v, i) => {
        if (copy.input.weather_region_id === v.id) {
          copy.stationList = v.weather_stations;
          copy.input.weather_station_id = v.weather_stations[0].id;
        }
        return true;
      });
      for (var iii in copy.stationList) {
        if (copy.input.weather_station_id === copy.stationList[iii].id) {
          const station = copy.stationList[iii];
          copy.collected_date = `(観測期間: ${station.oldest_collected_date}~${station.latest_collected_date})`;
          break;
        }
      }
      return copy;
    }
    case "STATION": {
      const copyWeather = Object.assign({}, action.landUse);
      copyWeather.input.weather_station_id = parseInt(action.value, 10);
      for (var iiii in copyWeather.stationList) {
        if (
          copyWeather.input.weather_station_id ===
          copyWeather.stationList[iiii].id
        ) {
          const station = copyWeather.stationList[iiii];
          copyWeather.collected_date = `(観測期間: ${station.oldest_collected_date}~${station.latest_collected_date})`;
          break;
        }
      }
      return copyWeather;
    }
    case "METEOCROP": {
      const copyWeather = Object.assign({}, action.landUse);
      copyWeather.input.weather_station_type_id = parseInt(action.value, 10);
      copyWeather.weatherKey =
        copyWeather.input.weather_station_type_id === 0 ? "amedas" : "kansho";
      copyWeather.input.weather_region_id = parseInt(
        copyWeather.all_weather_stations[copyWeather.weatherKey][0].id
      );
      copyWeather.input.weather_station_id = parseInt(
        copyWeather.all_weather_stations[copyWeather.weatherKey][0]
          .weather_stations[0].id
      );
      copyWeather.all_weather_stations[copyWeather.weatherKey].map((v, i) => {
        if (copyWeather.input.weather_region_id === v.id) {
          copyWeather.stationList = v.weather_stations;
        }
        return true;
      });
      return copyWeather;
    }
    case "SOIL_TYPE": {
      const copyWeather = Object.assign({}, action.landUse);
      const id = parseInt(action.value, 10);
      copyWeather.input.et0_coefficient_type_id = action.value;

      copyWeather.all_et0_coefficients.map((v) => {
        if (id === v.et0_coefficient_type_id) {
          copyWeather.input.et0_coefficient_value = v.et0_coefficient_value;
        }
        return true;
      });
      copyWeather.isDis = id !== -1;
      return copyWeather;
    }
    case "SOIL_TYPE_INIT": {
      const copyWeather = Object.assign({}, action.landUse);
      const id = parseInt(action.value, 10);
      if (!copyWeather.input) {
        copyWeather.input = {};
      }
      copyWeather.input.et0_coefficient_type_id = action.value;
      copyWeather.isDis = id !== -1;
      return copyWeather;
    }
    case "ONCHANGE_TEXT": {
      const copyWeather = Object.assign({}, action.landUse);
      copyWeather.input.et0_coefficient_value = action.value;
      return copyWeather;
    }
    default:
      return state;
  }
};

export const irrigateData = (state = null, action) => {
  switch (action.type) {
    case "FETCH_DATA_IRRIGATE_SUCCESS":
      const copyIrrigate = Object.assign(action.irrigate);
      copyIrrigate.key = Object.keys(action.irrigate.input).filter(
        (v) => v !== "id"
      );
      return copyIrrigate;
    case "ONCHANGE_IRRIGATE": {
      const copyIrrigate = Object.assign({}, action.landUse);
      copyIrrigate.input[action.name] = action.value;
      return copyIrrigate;
    }
    default:
      return state;
  }
};

