import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../Reducers";
// import { logger } from 'redux-logger';

const configureStore = (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk)
    //applyMiddleware(thunk, logger)
  );
};

export default configureStore;
