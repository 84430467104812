import React from "react";
import { Row, Col } from "reactstrap";

import SeriesTimeLine from "./series_timeline";
import SeriesDepth from "./series_depth";
import DayPicker from "./daypicker";
import Depth from "./depth";
import { connect } from "react-redux";

const ControlPanel = ({ flg, col, activeTab }) => (
  <div>
    {flg ? (
      <Row className="timeLine">
        <Col sm={col} style={{ zIndex: "11" }}>
          <SeriesTimeLine />
        </Col>
        <Col
          sm={6}
          id="depth"
          style={{
            display: activeTab === "1" ? "block" : "none",
            zIndex: "10",
          }}
        >
          <Depth />
        </Col>
      </Row>
    ) : (
      <Row className="distributeGraph">
        <Col sm={col} style={{ zIndex: "11" }}>
          <SeriesDepth />
        </Col>
        <Col
          sm={12}
          id="daypicker"
          style={{
            display: activeTab === "2" ? "block" : "none",
            zIndex: "10",
          }}
        >
          <DayPicker />
        </Col>
      </Row>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  input: state.input,
  series: state.series,
});

export default connect(mapStateToProps)(ControlPanel);
