import React, { Component } from "react";
import { Input, Col, Label, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import * as common from "../../Common/common";
// import { FaQuestionCircle } from 'react-icons/fa';
import Tips from "../../Tips/index";

import "../../../assets/crop_config.css";

class Calendar extends Component {
  makeOption = (min, max) => {
    const option = [];
    for (var n = min; n <= max; n++) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };
  makeYOption = (min, max, string) => {
    const option = [];
    for (var n = max; n >= min; n--) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };
  render() {
    const lastMonthDay = common.monthday(
      this.props.dateArr[0],
      parseInt(this.props.dateArr[1])
    );
    const optionYear = this.makeYOption(
      this.props.year.min,
      this.props.year.max
    );
    const optionMonth = this.makeOption(1, 12);
    const optionDay = this.makeOption(1, lastMonthDay);
    return (
      <FormGroup row>
        <Label for={this.props.labelFor} sm={6} xs={12}>
          {this.props.label}
        </Label>
        <Col sm="6" xs={12} style={{ margin: "0px 0px" }}>
          <FormGroup row>
            <Col xs="auto" sm="auto">
              <Input
                type="select"
                value={this.props.dateArr[0]}
                name={this.props.labelFor}
                data-which-one={"y"}
                onChange={(event) =>
                  this.props.onChangeCalender(
                    event,
                    this.props.fertilization,
                    "y"
                  )
                }
              >
                {optionYear}
              </Input>
            </Col>
            <span>−</span>
            <Col xs="auto" sm="auto" style={{ margin: "0px 0px" }}>
              <Input
                type="select"
                name={this.props.labelFor}
                data-which-one={"m"}
                value={`${Number(this.props.dateArr[1])}`}
                onChange={(event) =>
                  this.props.onChangeCalender(
                    event,
                    this.props.fertilization,
                    "m"
                  )
                }
              >
                {optionMonth}
              </Input>
            </Col>
            <span>−</span>
            <Col xs="auto" sm="auto" style={{ margin: "0px 0px" }}>
              <Input
                type="select"
                name={this.props.labelFor}
                data-which-one={"d"}
                value={`${Number(this.props.dateArr[2])}`}
                onChange={(event) =>
                  this.props.onChangeCalender(
                    event,
                    this.props.fertilization,
                    "d"
                  )
                }
              >
                {optionDay}
              </Input>
            </Col>
            <Tips comment={this.props.comment} id={"date"} />
          </FormGroup>
        </Col>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  crop: state.cropShow,
});

export default connect(mapStateToProps)(Calendar);
