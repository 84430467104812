import React, { Component } from "react";
import { Table } from "reactstrap";
import i18next from "i18next";
import { Spinner } from "../iconSpinner";

import CultivationTbody from "./TBody";

class FertilizationTable extends Component {
  render() {
    const headers = [
      "",
      i18next.t("crops.thead.annual"),
      i18next.t("cultivation.thead.dcult"),
      i18next.t("crops.thead.crop"),
      i18next.t("crops.common.cultivation_start"),
      i18next.t("crops.common.cultivation_end"),
      i18next.t("crops.thead.fix"),
      i18next.t("crops.thead.remove"),
    ];
    const { cultivations, isLoading, cookie, fetchData, token } = this.props;
    return (
      <Table responsive>
        <thead>
          <tr>
            {(() => {
              return headers.map((header, i) => <th key={i}> {header} </th>);
            })()}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="9" className="text-center">
                <Spinner />
              </td>
            </tr>
          ) : (
            cultivations.map((cultivation, i) => (
              <CultivationTbody
                cultivation={cultivation}
                fetchData={fetchData}
                key={i}
                cookie={cookie}
                token={token}
              />
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default FertilizationTable;
