import React from "react";
import { Route, Switch } from "react-router-dom";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import Co2 from "./Co2/index";
import LandUse from "./LandUse/index";
import Irrigate from "./Irrigate/index";
import Partition from "./Segment/Partition/index";
import Parameter from "./Segment/Parameter/index";
import ParameterConfig from "./Segment/Parameter/edit";
import CropRotations from "./CropRotations/index";
import Crops from "./Crops/index";
import CommonCropConfig from "./Crops/commonCropConfig";
import CropConfig from "./Crops/cropConfig";
import Fertilization from "./Fertilization/index";
import FertilizationConfig from "./Fertilization/fertilizationConfig";
import Cultivation from "./Cultivation/index";
import CultivationConfig from "./Cultivation/cultivationConfig";
import Confirm from "./Confirm/index";
import Result from "./Result/index";
import Graph from "./Graph/index";
import CalculateCondition from "./Result/calculate_condition";
// import { fetchInitialInput } from "./../Actions/landUse";
import NotFound from "../NotFound";
/** ***************** */
//import Footer from './Footer';
import { CLIENT } from "../consts_path";

// const COMPONENT_IN_INPUT_ID = [
//   // { component: Sample, path: '/sample' },
//   { component: LandUse, path: CLIENT.URL.root },
//   { component: LandUse, path: CLIENT.URL.landuse },
//   { component: Irrigate, path: CLIENT.URL.weather },
//   { component: Partition, path: CLIENT.URL.segmentpartition },
//   { component: Parameter, path: CLIENT.URL.segmentparamater },
//   { component: ParameterConfig, path: CLIENT.URL.segmconfig },
//   { component: CropRotations, path: CLIENT.URL.croprotation },
//   { component: Crops, path: CLIENT.URL.crop },
//   { component: CommonCropConfig, path: CLIENT.URL.commonconfig },
//   { component: CropConfig, path: CLIENT.URL.cropconfig },
//   { component: Fertilization, path: CLIENT.URL.fertilization },
//   { component: FertilizationConfig, path: CLIENT.URL.fertconfig },
//   { component: Cultivation, path: CLIENT.URL.cultivation },
//   { component: CultivationConfig, path: CLIENT.URL.cultivationconfig },
//   { component: Confirm, path: CLIENT.URL.confirm },
//   { component: CalculateCondition, path: CLIENT.URL.condition },
// ];

// const COMPONENT_OUT_INPUT_ID = [
//   { component: Result, path: CLIENT.URL.result },
//   { component: Graph, path: CLIENT.URL.graph },
// ];

const Routing = ({ input }) => {
  return (
    <Switch>
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.root}
          render={(props) => <Co2 {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.landuse}
          render={(props) => <LandUse {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.weather}
          render={(props) => <Irrigate {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.segmentpartition}
          render={(props) => <Partition {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.segmentparamater}
          render={(props) => <Parameter {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.segmconfig}
          render={(props) => <ParameterConfig {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.croprotation}
          render={(props) => <CropRotations {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.crop}
          render={(props) => <Crops {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.commonconfig}
          render={(props) => <CommonCropConfig {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.cropconfig}
          render={(props) => <CropConfig {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.fertilization}
          render={(props) => <Fertilization {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.fertconfig}
          render={(props) => <FertilizationConfig {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.cultivation}
          render={(props) => <Cultivation {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.cultivationconfig}
          render={(props) => <CultivationConfig {...props} />}
        />
      )}
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.confirm}
          render={(props) => <Confirm {...props} />}
        />
      )}
      <Route
        exact
        path={CLIENT.URL.result}
        render={(props) => <Result {...props} />}
      />
      <Route
        exact
        path={CLIENT.URL.graph}
        render={(props) => <Graph {...props} />}
      />
      {input.id && (
        <Route
          exact
          path={CLIENT.URL.condition}
          render={(props) => <CalculateCondition {...props} />}
        />
      )}
      {input.id && <Route exact component={NotFound} />}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  input: state.input,
});

// const mapDispatchToProps = (dispatch) => ({
//   InitialInput: (url) => dispatch(fetchInitialInput(url)),
// });

export default withCookies(connect(mapStateToProps)(Routing));
