import React from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { CLIENT } from "./consts_path";
import * as common from "./Component/Common/common";

function initialize() {
  // cookie.remove("input_id");
  window.location.href = CLIENT.URL.root;
}

const Header = ({ toggle, isOpen, cookie, pathname, results, input }) => (
  <Navbar color="light" light expand="md">
    <NavbarBrand>土壌の窒素見える化ツール（プロ版）</NavbarBrand>
    <NavbarToggler onClick={() => toggle()} />
    <Collapse isOpen={isOpen} navbar>
      <Nav className="ml-auto" navbar>
        {results &&
          pathname !== "result" &&
          results.calculations.length !== 0 &&
          cookie.cookies.has_calculation === "false" && (
            <NavItem>
              <Link
                to={common.makeUrlIndex(
                  CLIENT.URL.result,
                  cookie.cookies.input_id
                )}
              >
                <Button
                  size="small"
                  color="primary"
                  title="計算履歴一覧を参照する。"
                >
                  <span title="計算履歴一覧を参照する。">計算履歴</span>
                </Button>
              </Link>
            </NavItem>
          )}
        {pathname === "result" && cookie.cookies.has_calculation === "false" && (
          <NavItem>
            <Link to={CLIENT.URL.landuse}>
              <Button size="small" color="primary" title="土地利用条件に戻る。">
                <span title="土地利用条件に戻る。">土地利用条件</span>
              </Button>
            </Link>
          </NavItem>
        )}

        <NavItem>
          <Button
            size="small"
            color="warning"
            title="地点の選択からやり直す"
            className="init"
            onClick={(e) => initialize(cookie)}
          >
            <span title="地点の選択からやり直す">
              <span>
                TOP
              </span>
            </span>
          </Button>
        </NavItem>
      </Nav>
    </Collapse>
  </Navbar>
);

const mapStateToProps = (state) => ({
  results: state.calc,
});

export default connect(mapStateToProps)(Header);
