import { CLIENT } from "../consts_path";

export const eventValue = (value, landUse, variable, name) => {
  return {
    type: variable,
    value,
    landUse,
    name,
  };
};

export const fetchDataLandUse = (data) => {
  return {
    type: "FETCH_DATA_LANDUSE_SUCCESS",
    data,
  };
};

export const nearestSitesSuccess = (data) => {
  return {
    type: "FETCH_NEAREST_SITES",
    data,
  };
};

export const weatherSuccess = (weather, nearest_site) => {
  return {
    type: "FETCH_WEATHER",
    weather,
    value: nearest_site,
  };
};

export const irrigateSuccess = (irrigate, variable) => {
  return {
    type: variable,
    irrigate,
  };
};

export const fetchInitialInputSuccess = (input) => {
  return {
    type: "FETCH_INITIAL_INPUT_SUCCESS",
    input,
  };
};

export const fetchInputSuccess = (input) => {
  return {
    type: "FETCH_INPUT_SUCCESS",
    input,
  };
};

export const geoUpdateSuccess = (status, variable) => {
  return {
    type: variable,
    status,
  };
};

export const fetchGeoLocationSuccess = (gl, qry, variable) => {
  return {
    type: variable,
    gl,
    qry,
  };
};

export const onChangeSoilType = (e, landUse, variable) => {
  return (dispatch) => {
    dispatch(eventValue(e.target.value, landUse, variable, e.target.name));
  };
};

/*
 * get /api/inputs/input_id/land_use
 * */
export const fetchLandUse = (url) => {
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((data) => {
        if (data.land_uses.length === 0) {
          alert("土壌パラメータがありません。地点選択からやり直してください。");
          window.location = CLIENT.URL.root;
        }
        dispatch(fetchDataLandUse(data));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

/*
 * get /api/inputs
 * */
export const fetchInitialInput = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          // throw Error(responce.statusText);
          // window.location.href = `/land_use`
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((inputs) => {
        dispatch(fetchInitialInputSuccess(inputs.input));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。", error);
        window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

/*
 * get /api/inputs/input_id/geo_locations
 * */
export const fetchGeoLocation = (url, qry, variable) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((geo_location) => {
        // URLパラメータがなく、かつ、geo_locationもnullの場合はエラーを発生させる
        if (!qry && !geo_location.input.latitude) {
          // dispatch(fetchGeoLocationSuccess(geo_location, qry, 'FETCH_GEO_LOCATION_ERROR'));
          // alert("地点選択からやり直してください。");
          // window.location = CLIENT.URL.root;
        } else {
          dispatch(
            fetchGeoLocationSuccess(
              geo_location,
              qry,
              "FETCH_GEO_LOCATION_SUCCESS"
            )
          );
          // dispatch(fetchInputSuccess(geo_location.input));
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const loading = (status) => {
  return {
    type: "LOADING",
    isLoading: status,
  };
};

/*
 * get /api/nearest_sites
 * */
export const fetchNearestSites = (url) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((nearest_sites) => {
        dispatch(nearestSitesSuccess(nearest_sites));
      })
      .catch((error) => {
        return console.log(error);
      });
  }
}

/*
 * get /api/inputs/input_id/geo_locations
 * */
export const fetchWeather = (url, nearest_site) => {
  return (dispatch) => {
    dispatch(loading(true));
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((weather) => {
        if (!weather.input.weather_station_id) {
          // alert("地点選択からやり直してください。");
          // window.location = CLIENT.URL.root;
        }
        dispatch(weatherSuccess(weather, nearest_site));
        dispatch(loading(false));
      })
      .catch((error) => {
        return console.log(error);
      });
  };
};

export const fetchIrrigate = (url, variable) => {
  return (dispatch) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => {
        return responce.json();
      })
      .then((irrigate) => {
        dispatch(irrigateSuccess(irrigate, variable));
      })
      .catch((error) => {
        alert("地点選択からやり直してください。");
        window.location = CLIENT.URL.root;
        return console.log(error);
      });
  };
};

export const updateGeoLocation = (url, form, variable, token) => {
  if (form) {
    return async (dispatch) => {
      await fetch(url, {
        method: "PUT",
        credentials: "same-origin",
        body: form,
      })
        .then((responce) => {
          if (!responce.ok) {
            throw Error(responce.statusText);
          }
          dispatch(geoUpdateSuccess(responce.ok, variable));
        })
        .catch((error) => {
          return error.status;
        });
    };
  } else {
    return (dispatch) => {
      dispatch(geoUpdateSuccess(true, variable));
    };
  }
};

