export const watchCurrentPosition = () => {
  const navbar = document.getElementById("navbar");
  const jsButton = document.getElementById("js-button");
  if (navbar) {
    const sticky = navbar.offsetTop;
    if (window.pageYOffset > sticky) {
      window.pageYOffset <= 150
        ? navbar.classList.remove("sticky")
        : navbar.classList.add("sticky");
      window.pageYOffset <= 177
        ? jsButton.classList.remove("show")
        : jsButton.classList.add("show");
    } else {
      navbar.classList.remove("sticky");
    }
  }
};

export const watchUnderPosition = () => {
  const jsButton = document.getElementById("down-button");
  const sticky =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  if (jsButton !== null)
    window.pageYOffset === sticky
      ? jsButton.classList.add("hidden")
      : jsButton.classList.remove("hidden");
};
