import React from "react";
import { connect } from "react-redux";
import DataInfoChild from "./dataInfoChild";

const DataInfo = ({ updateGeoLocation }) => (
  <div id="datainfo">{updateGeoLocation && <DataInfoChild />}</div>
);

const mapStateToProps = (state) => ({
  updateGeoLocation: state.updateGeoLocation,
});

export default connect(mapStateToProps)(DataInfo);
