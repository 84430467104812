import React from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Input,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { FaCloudShowersHeavy } from "react-icons/fa";

const irrigateLabel = ["アンモニア態窒素[NH4-N]", "硝酸態窒素[NO3-N]"];
const irrigateUnit = ["mg/L", "mg/L"];

const Irrigate = ({ changeSoilType, irrigate }) => (
  <div>
    <h3>
      {/*<img src={kangai} id="irrigateIcon" alt="灌漑" />*/}
      <FaCloudShowersHeavy /> 降水濃度
    </h3>
    <hr />
    {irrigate &&
      irrigate.key.map((v, i) => (
        <FormGroup row id="irrigate" key={v}>
          <Label xs={3} sm={3}>
            {irrigateLabel[i]}
          </Label>
          <Col xs={5} sm={4}>
            <InputGroup>
              <Input
                type="number"
                step={0.1}
                name={v}
                value={irrigate.input[v]}
                onChange={(e) =>
                  changeSoilType(e, irrigate, "ONCHANGE_IRRIGATE")
                }
              />
              <InputGroupAddon addonType="append">
                {irrigateUnit[i]}
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      ))}
  </div>
);

const mapStateToProps = (state) => ({
  value: state.value,
  irrigate: state.irrigateData,
});

export default connect(mapStateToProps)(Irrigate);
