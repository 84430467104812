import React from "react";
import {
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Tips from "../../Tips/index";

// const id = label => {
//   const spLabel = label.split('/').length;
//   // return spLabel.langth != 1 ? spLabel[0] : spLabel[1];
// };

const TextBox = ({
  label,
  value,
  comment,
  fertilization,
  onChangeTextValue,
  step,
  index,
  ext,
}) => (
  <FormGroup row>
    <Label for={value} sm={6} xs={12}>
      {label}
    </Label>
    <Col sm={3} xs="auto" style={{ margin: "0px 0px" }}>
      <InputGroup>
        <Input
          type="number"
          step={step}
          name={value}
          value={fertilization[value]}
          onChange={(e) => onChangeTextValue(e, fertilization)}
        />
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{ext}</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Col>
    <Tips comment={comment} id={value.split("_")} />
  </FormGroup>
);

export default TextBox;
