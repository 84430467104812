import React from "react";
import { Table } from "reactstrap";
import i18next from "i18next";
import Td from "./td";
import Errors from "../../Common/error";
import { AlertError } from "../../Common/alert";

const PartitionTable = ({ soilLayer, onChange }) => (
  <Table hover>
    <thead id="partition">
      <tr>
        {/* <th>#</th> */}
        <th>層</th>
        <th>分割数</th>
        {/* <th></th> */}
      </tr>
    </thead>
    {soilLayer.error === true ? (
      <Errors errormsg={AlertError(i18next.t("session.error"))} />
    ) : (
      <Td soilLayer={soilLayer} onChange={onChange} />
    )}
  </Table>
);

export default PartitionTable;
