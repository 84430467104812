import React from "react";
import { Badge } from "reactstrap";
import { MdArrowForward } from "react-icons/md";

/* 0: previous, 1: current, null: future */
function color(num) {
  const defaultStyle = {
    padding: "1em",
    color: "#FAFAFA",
    backgroundColor: "",
    textShadow: "",
  };
  switch (num) {
    case 0:
      defaultStyle.backgroundColor = "#FCE5EC";
      defaultStyle.color = "#000000";
      defaultStyle.textShadow = "1px 1px 0 #CDCDCD";
      return defaultStyle;
    case 1:
      defaultStyle.backgroundColor = "#28a755";
      defaultStyle.textShadow = "1px 1px 0 #757575";
      return defaultStyle;
    default:
      // defaultStyle.backgroundColor = '#525252';
      // defaultStyle.textShadow      = '1px 1px 0 #FF5722';
      return defaultStyle;
  }
}

const style = { display: "inline-flex", paddingBottom: "15px" };
const margin = { marginTop: "9px" };

/**
 * 画面の上部のパンくずリストのコンポーネント
 * di, pr, cr, f, cu, co, re: 現在位置を示す数値
 * @returns {undefined}
 */

const BreadCrumb = ({
  co2,
  landuse,
  irrigate,
  partition,
  parameter,
  croprotation,
  crop,
  fertilization,
  cultivation,
  confirm,
  result,
}) => (
  <div id="crumb" style={style}>
    {/* <h5><Badge style={color(di)} pill>地点の選択</Badge></h5> */}
    <h5>
      <Badge style={color(co2)} pill>
        CO2
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(irrigate)} pill>
        気象条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(partition)} pill>
        分割条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(parameter)} pill>
        土壌条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    {/*
      <h5><Badge style={color(croprotation)} pill>作物選択</Badge></h5>
      <h5 style={margin}><MdArrowForward /></h5>
    */}

    <h5>
      <Badge style={color(crop)} pill>
        作物条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(fertilization)} pill>
        施肥条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(cultivation)} pill>
        耕起条件
      </Badge>
    </h5>
    <h5 style={margin}>
      <MdArrowForward />
    </h5>

    <h5>
      <Badge style={color(confirm)} pill>
        確認・計算実行
      </Badge>
    </h5>
  </div>
);

export default BreadCrumb;
