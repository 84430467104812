import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  FaRegCopy,
  FaChartLine,
  FaRegFileAlt,
  FaFileDownload,
  FaHourglassHalf,
  FaTrashAlt,
} from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { CLIENT, DL } from "../../consts_path";
import * as common from "../Common/common";
import { VISUAL } from "../../consts_path";

const whichIcon = (status) => {
  switch (status) {
    case 0:
      return <MdCheckCircle style={{ color: "green" }} />;
    case 1:
      return <FiLoader className="icon-spin" />;
    case 2:
      return <MdCancel style={{ color: "red" }} />;
    default:
      return <FaHourglassHalf />;
  }
};

const CalculateTbody = ({
  result,
  index,
  duplication,
  onPoint,
  flag,
  statusFlag,
  deleteResult,
}) => (
  <tr>
    <td>{index + 1}</td>
    <td>
      <center>
        <span title={result.message} style={{ fontSize: "2.5vh" }}>
          {whichIcon(statusFlag)}
        </span>
      </center>
    </td>
    <td>
      {result.start_at || "-"}
      <br />
      {result.end_at || "-"}
    </td>
    <td>
      {result.input.title}
      <br />
      {result.input.soil_name}
    </td>
    <td>
      <button
        type="button"
        className="buttonHref"
        style={{ textAlign: "left" }}
        onClick={() =>
          onPoint(`${result.input.latitude},${result.input.longitude}`)
        }
      >
        {common.round(result.input.longitude)}
        <br />
        {common.round(result.input.latitude)}
      </button>
    </td>
    <td>
      {result.input.var_idate}
      ~
      <br />
      {result.input.var_cumt1}
    </td>
    <td style={VISUAL.GRAPH ? { display: "none" } : { display: "table-cell" }}>
      <span title="全ての計算条件をテキスト形式で参照できます。">
        <Link
          className={flag ? "disabled-link" : ""}
          to={
            result.output
              ? common.makeUrlShow(
                  CLIENT.URL.condition,
                  result.input_id,
                  result.output.id
                )
              : "#"
          }
        >
          <Button>
            <FaRegFileAlt />
          </Button>
        </Link>
      </span>
    </td>
    <td style={!VISUAL.GRAPH ? { display: "none" } : { display: "table-cell" }}>
      <Link
        className={flag ? "disabled-link" : ""}
        to={
          result.output
            ? common.makeUrlGraph(CLIENT.URL.graph, result.id, result.output.id)
            : "#"
        }
      >
        <span title="計算条件を可視化します。">
          <Button color="primary" disabled={flag}>
            <FaChartLine />
          </Button>
        </span>
      </Link>
    </td>
    <td>
      <span title="計算条件を複製して再計算します。">
        <Button color="warning" onClick={() => duplication(result.input_id)}>
          <FaRegCopy />
        </Button>
      </span>
    </td>
    <td>
      <a
        className={statusFlag === 2 ? null : flag ? "disabled-link" : ""}
        href={`${DL.BASE_URL}/${result.id}.zip`}
      >
        <span title="計算結果の圧縮ファイル(ZIP形式)をダウンロードできます。">
          <Button
            disabled={statusFlag === 2 ? false : flag === true ? true : false}
            className="btn-dark"
          >
            <FaFileDownload />
          </Button>
        </span>
      </a>
    </td>
    <td>
      <Button
        title="計算結果を削除します。"
        color="danger"
        onClick={(e) => deleteResult(e, result)}
      >
        <FaTrashAlt />
      </Button>
    </td>
  </tr>
);

export default CalculateTbody;
