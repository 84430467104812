import React, { Component } from "react";
import { Table } from "reactstrap";

import i18next from "i18next";
import CropTbody from "./Tbody";
import { Spinner } from "../iconSpinner";

class CropTables extends Component {
  render() {
    const headers = [
      "",
      i18next.t("crops.thead.annual"),
      i18next.t("crops.thead.crop"),
      i18next.t("crops.common.cultivation_start"),
      i18next.t("crops.common.planting_date"),
      i18next.t("crops.common.harvest_date"),
      i18next.t("crops.common.cultivation_end"),
      i18next.t("crops.thead.fix"),
      i18next.t("crops.thead.remove"),
    ];
    const { data, isLoading, token } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            {(() => {
              return headers.map((header) => <th key={header}> {header} </th>);
            })()}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="9" className="text-center">
                <Spinner />
              </td>
            </tr>
          ) : (
            data.crops.map((crop, index) => (
              <CropTbody
                key={crop.id}
                crop={crop}
                index={index}
                token={token}
              />
            ))
          )}
        </tbody>
      </Table>
    );
  }
}

export default CropTables;
