import React, { Component } from "react";
import { connect } from "react-redux";

import BreadCrumb from "../../BreadCrumb/index";
import PageController from "../../PageController/index";
import ParameterTable from "./parameter_table";
import { fetchParameter } from "../../../Actions/parameter";
import { fetchGeoLocation } from "../../../Actions/landUse";
import * as common from "../../Common/common";
import { API, CLIENT } from "../../../consts_path";

class Parameter extends Component {
  componentDidMount() {
    const { GeoLocation, Param, input } = this.props;
    const urlForGeo = API.URL.geolocation.replace(API.REP.inputid, input.id);
    const urlForSoilData = API.URL.soildata.replace(API.REP.inputid, input.id);
    GeoLocation(urlForGeo);
    Param(urlForSoilData);
  }

  render() {
    const assign = common.pageController;
    const { parameter, input, isLoading } = this.props;
    assign.display = { display: "block" };
    assign.url = [
      CLIENT.URL.segmentpartition.replace(API.REP.inputid, input.id),
      CLIENT.URL.crop.replace(API.REP.inputid, input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb landuse={0} irrigate={0} partition={0} parameter={1} />
        <ParameterTable
          param={parameter}
          inputid={input.id}
          isLoading={isLoading}
        />
        <br />
        {parameter && <PageController assign={assign} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  parameter: state.parameter,
  geoLocation: state.geoLocation,
  isLoading: state.load,
});

const mapDispatchToProps = (dispatch) => ({
  Param: (url) => dispatch(fetchParameter(url)),
  GeoLocation: (url) => dispatch(fetchGeoLocation(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Parameter);
