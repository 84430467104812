export const loadCultivations = (state = false, action) => {
  switch (action.type) {
    case "LOAD_CULTIVATIONS":
      return action.isLoading;
    default:
      return state;
  }
};

export const cultivation = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CULTIVATION_SUCCESS":
      var copyCulti = Object.assign({}, action.cultivation);
      var is_valid = copyCulti.cultivations.filter((el) => {
        return el["is_valid?"] === false;
      }).length;
      copyCulti.is_valid = is_valid === 0 ? null : true;
      return copyCulti;
    default:
      return state;
  }
};

export const cultishow = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CULTIVATION_SHOW_SUCCESS":
      var copyCulti = Object.assign({}, action.cultivation);
      return copyCulti;
    default:
      return state;
  }
};
