function isValidate(data) {
  return data.soil_layer_segm_nums.map((v) => {
    return !(v >= 1 && v <= 20);
  });
}

export const soilLayer = (state = null, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS": {
      const copy = Object.assign(action.myJson);
      copy.is_invalid = isValidate(copy.input);
      copy.error = false;
      return copy;
    }
    case "FETCH_ERROR": {
      const copyError = Object.assign(action.myJson);
      copyError.error = true;
      return copyError;
    }
    case "ON_CHANGE_SUCCESS": {
      const input = Object.assign({}, action.partition.input);
      const newArr = action.partition.input.soil_layer_segm_nums.concat();
      newArr[action.index] = action.value;
      input.soil_layer_segm_nums = newArr;
      return { input, is_invalid: isValidate(input) };
    }
    case "ON_CHANGE_MODEL_TYPE": {
      const copyPar = Object.assign({}, action.partition);
      copyPar.input.cc_modret_num = parseInt(action.value, 10);
      copyPar.cc_modret_num = parseInt(action.value, 10);
      return copyPar;
    }
    case "ON_CHANGE_BBC_TYPE": {
      const copyParType = Object.assign({}, action.partition);
      copyParType.input.var_bbc = parseInt(action.value, 10);
      return copyParType;
    }
    default: {
      return state;
    }
  }
};

export const parameter = (state = null, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS_PARAM":
      return action.myJson;
    default:
      return state;
  }
};

export const physicalproperty = (state = null, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS_PROP": {
      const assign = Object.assign({}, action.prop);
      assign.ppkeys = Object.keys(assign.physical_property);
      assign.vgkeys = Object.keys(assign.van_genuchten);
      assign.dukeys = Object.keys(assign.durner);
      assign.omkeys = Object.keys(assign.initial_profile);
      assign.rrkeys = Object.keys(assign.rate_constants);
      return assign;
    }
    default: {
      return state;
    }
  }
};

export const physicalpropertydata = (state = null, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS_DATA": {
      const structure = Object.assign({}, action.label);
      const assign = Object.assign({}, action.data);
      assign.officialKey = Object.keys(assign)[0];
      assign.ppkeys = Object.keys(structure.physical_property);
      assign.vgkeys = Object.keys(structure.van_genuchten);
      assign.dukeys = Object.keys(structure.durner);
      assign.omkeys = Object.keys(structure.initial_profile);
      assign.rrkeys = Object.keys(structure.rate_constants);
      assign.ppkeys.map((v) => {
        return (structure.physical_property[v] = assign.soil_datum[v]);
      });
      assign.vgkeys.map((v) => {
        return (structure.van_genuchten[v] = assign.soil_datum[v]);
      });
      assign.dukeys.map((v) => {
        return (structure.durner[v] = assign.soil_datum[v]);
      });
      assign.omkeys.map((v) => {
        return (structure.initial_profile[v] = assign.soil_datum[v]);
      });
      assign.rrkeys.map((v) => {
        return (structure.rate_constants[v] = assign.soil_datum[v]);
      });
      assign.modret_num = String(action.data.soil_datum.modret_num);
      assign.ischecked = false;
      assign.structure = structure;
      return assign;
    }
    case "CHANGE_TEXT_VALUE": {
      const assign = Object.assign({}, action.data);
      assign.soil_datum[action.target.name] = action.target.value;
      assign.structure[action.target.id][action.target.name] =
        action.target.value;
      return assign;
    }
    case "CHANGE_CHECKBOX": {
      const assign = Object.assign({}, action.data);
      assign.ischecked = action.target.checked;
      return assign;
    }
    case "CHANGE_MODEL_TYPE": {
      const assign = Object.assign({}, action.data);
      assign.modret_num = action.target.value;
      assign.soil_datum.modret_num = action.target.value;
      return assign;
    }
    default: {
      return state;
    }
  }
};

export const toggleState = (state = false, action) => {
  switch (action.type) {
    case "TOGGLE_SUCCESS":
      return action.toggle;
    default:
      return state;
  }
};

export const load = (state = true, action) => {
  switch (action.type) {
    case "LOADING":
      return action.isLoading;
    default:
      return state;
  }
};
