import React from "react";
import i18next from "i18next";
import { Col, FormGroup, Label, Input } from "reactstrap";

import * as common from "../../Common/common";
import Calender from "./datepicker";

const CalenderGroup = ({ onChangeCalender, crops, crop }) => (
  <div>
    {crops && (
      <span>
        {/* 設定開始日 */}
        <FormGroup row>
          <Label for="cultivation_start" sm={3}>
            {i18next.t("crops.common.cultivation_start")}
          </Label>
          <Col sm={4} xs={4}>
            <Input
              type="text"
              value={common.addOneDay(crop.prev_end_date)}
              disabled
            />
          </Col>
        </FormGroup>
        {/* 植え付け日 */}
        <Calender
          onChangeCalender={onChangeCalender}
          year={{ min: crops.min_year, max: crops.max_year }}
          dateArr={crop.var_pday.split(/-/)}
          labelFor="var_pday"
          label={i18next.t("crops.common.planting_date")}
        />
        {/* 出芽日 */}
        <Calender
          onChangeCalender={onChangeCalender}
          year={{ min: crops.min_year, max: crops.max_year }}
          dateArr={crop.var_estart.split(/-/)}
          labelFor="var_estart"
          label={i18next.t("crops.showEdit.estart")}
        />
        {/* 根成熟日 */}
        <Calender
          onChangeCalender={onChangeCalender}
          year={{ min: crops.min_year, max: crops.max_year }}
          dateArr={crop.var_rdfday.split(/-/)}
          labelFor="var_rdfday"
          label={i18next.t("crops.showEdit.rdfday")}
        />
        {/* 作物成熟日 */}
        <Calender
          onChangeCalender={onChangeCalender}
          year={{ min: crops.min_year, max: crops.max_year }}
          dateArr={crop.var_estop.split(/-/)}
          labelFor="var_estop"
          label={i18next.t("crops.showEdit.estop")}
        />
        {/* 収穫日 */}
        <Calender
          onChangeCalender={onChangeCalender}
          year={{ min: crops.min_year, max: crops.max_year }}
          dateArr={crop.var_dayhar.split(/-/)}
          labelFor="var_dayhar"
          label={i18next.t("crops.common.harvest_date")}
        />
        {/* 設定終了日 */}
        <FormGroup row>
          <Label for="rres" sm={3}>
            {i18next.t("crops.common.cultivation_end")}
          </Label>
          <Col sm={4} xs={4}>
            <Input type="text" value={crop.end_date} disabled />
          </Col>
        </FormGroup>
      </span>
    )}
  </div>
);

export default CalenderGroup;
