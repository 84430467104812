import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "reactstrap";
import { Link } from "react-router-dom";
import NavBar from "./navBar";
import TableComponentList from "./table_component_list";
import {
  FetchSoilLayerPhysicalPropertyData,
  onChangeValue,
} from "../../../Actions/parameter";
import ScrollTop from "./scroll_top_button";
import ScrollDown from "./scroll_down_button";
import { watchCurrentPosition, watchUnderPosition } from "./event_function";
import { API, CLIENT } from "../../../consts_path";
import * as common from "../../Common/common";
import { getToken } from "../../../Actions/common";
import { Spinner } from "../../iconSpinner";

const tableLabel = require("../table_label_rev1.json");

const CACHE = { activeTab: "" };

class EditParameter extends Component {
  state = {
    activeTab: "",
  };
  componentDidMount() {
    const {
      input,
      match,
      SoilLayerPhysicalPropertyData,
      getToken,
    } = this.props;
    const urlForSoilDataEdit = common.makeUrlShow(
      API.URL.soildataedit,
      input.id,
      match.params.id
    );
    SoilLayerPhysicalPropertyData(urlForSoilDataEdit, tableLabel);
    getToken();
  }

  update = (event, data) => {
    event.preventDefault();
    const { input, history, csrfToken } = this.props;
    const form = new FormData();
    Object.keys(data.soil_datum).map((v) =>
      form.append(`${data.officialKey}[${v}]`, data.soil_datum[v])
    );
    form.append(`${data.officialKey}[copy_soil_data]`, data.ischecked);
    const urlForSoilData = common.makeUrlShow(
      API.URL.soildataedit,
      input.id,
      data.soil_datum.id
    );
    fetch(urlForSoilData, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        // throw Error( window.location = `/land_use` );
      }
      history.push(common.makeUrlIndex(CLIENT.URL.segmentparamater, input.id));
    });
  };

  onScrollTarget = (t, activeTab) => {
    if (CACHE.activeTab === activeTab) return false;
    CACHE.activeTab = activeTab;
    const interval = 10;
    const divisor = 8;
    const range = divisor / 2 + 1;
    const target = document.getElementById(t);
    let toY;
    let nowY = window.pageYOffset;
    const headH = 120;
    const rect = target.getBoundingClientRect();
    const targetY = rect.top + nowY - headH;
    (function doScroll() {
      toY = nowY + Math.round((targetY - nowY) / divisor); // 次に移動する場所（近く割合は除数による。）
      window.scrollTo(0, toY); // スクロールさせる
      nowY = toY; // nowY更新
      if (document.body.clientHeight - window.innerHeight < toY) {
        // 最下部にスクロールしても対象まで届かない場合は下限までスクロールして強制終了
        window.scrollTo(0, document.body.clientHeight);
        return false;
      }
      if (toY >= targetY + range || toY <= targetY - range) {
        // +-rangeの範囲内へ近くまで繰り返す
        window.setTimeout(doScroll, interval);
      } else {
        // +-range の範囲内にくれば正確な値へ移動して終了。
        window.scrollTo(0, targetY);
      }
    })();
    this.setState({ activeTab });
  };

  scrollTop = (_, duration) => {
    // トップに戻るボタンの要素の取得
    const begin = new Date() - 0;
    const yOffset = window.pageYOffset;
    const timer = setInterval(() => {
      let current = new Date() - begin;
      if (current > duration) {
        clearInterval(timer);
        current = duration;
      }

      // スクロール位置を単位時間で変更する
      window.scrollTo(0, yOffset * (1 - current / duration));
    }, 10);
    this.setState({ activeTab: "" });
  };

  // XXX スムーススクロールが聞いていない
  scrollDown = () => {
    const doc = document.documentElement;
    const y = doc.scrollHeight - doc.clientHeight;
    window.scrollTo(0, y);
    this.setState({ activeTab: "" });
  };

  render() {
    const { data, onChange, input, isLoading } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <NavBar onScrollTarget={this.onScrollTarget} activeTab={activeTab} />
        <br />
        {isLoading ? (
          <Spinner />
        ) : (
          data && (
            <Form id="parameterConfig" name="parametercropConfig">
              <TableComponentList data={data} onChange={onChange} />
              <Row>
                <Col xs="auto">
                  <Button
                    color="success"
                    name="submit"
                    onClick={(e) => this.update(e, data)}
                  >
                    更新
                  </Button>
                </Col>
                <Col xs="auto">
                  <Link
                    to={common.makeUrlIndex(
                      CLIENT.URL.segmentparamater,
                      input.id
                    )}
                  >
                    <Button color="secondary" name="cancel">
                      キャンセル
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          )
        )}
        <ScrollTop
          scrollTop={this.scrollTop}
          watchCurrentPosition={watchCurrentPosition}
        />
        <ScrollDown
          scrollDown={this.scrollDown}
          watchUnderPosition={watchUnderPosition}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  label: state.physicalproperty,
  data: state.physicalpropertydata,
  isLoading: state.load,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  SoilLayerPhysicalPropertyData: (url, label) =>
    dispatch(FetchSoilLayerPhysicalPropertyData(url, label)),
  onChange: (e, prop, variable) => dispatch(onChangeValue(e, prop, variable)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditParameter);
