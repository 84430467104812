import React, { Component } from "react";
import { connect } from "react-redux";
// import * as common from '../Common/common';
// import PageController from '../PageController/index';
import { fetchResults, mapOnPoint } from "../../Actions/results";
import CalculateTable from "./Table";
import CollapseArrowIcon from "../CollapseArrowIcon/index";
import { API, CLIENT } from "../../consts_path";
import { FormText, Button, Row, Col, Collapse } from "reactstrap";
import GeoSearch from "./geo_search";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaCalculator,
} from "react-icons/fa"; import { MdRefresh } from "react-icons/md";
import { getToken } from "../../Actions/common";
import { fetchInitialInput } from "../../Actions/landUse";

class Result extends Component {
  state = {
    collapse: false,
  };
  componentDidMount() {
    const { getToken } = this.props;
    this.getResults();
    getToken();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.latlong !== this.props.latlong) {
      this.setState({ collapse: true });
    }
  }

  // 計算結果一覧の取得イベント
  getResults = () => {
    const { Results } = this.props;
    const urlForRes = API.URL.calculations;
    Results(urlForRes);
  };

  deleteResult = (event, res) => {
    event.preventDefault();
    const { csrfToken } = this.props;
    const end_at = res.end_at !== null ? res.end_at.split(" ")[1] : "";
    const calc_at = res.start_at + "〜" + end_at;
    const crop_name = res.input.title;
    const result = window.confirm(
      `${crop_name}(計算時間: ${calc_at})を削除します。\nよろしいですか？`
    );
    if (!result) return false;
    const id = res.id;
    const urlForResultDel = API.URL.resultdelete.replace(":id", id);
    fetch(urlForResultDel, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        this.getResults();
      })
      .catch((error) => console.log(error));
  };

  // 再計算イベント
  duplication = (input_id) => {
    const { csrfToken } = this.props;
    const dup = window.confirm(
      `再計算をするために土地利用条件に遷移します。\nよろしいですか？`
    );
    if (!dup) return false;
    const url = API.URL.inputDup;
    fetch(`${url}?input_id=${input_id}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((json) => json.json())
      .then(() => {
        const { history } = this.props;
        // cookie.remove("input_id");
        // cookie.remove("calc_id");
        // cookie.set("input_id", data.input.id, { path: "/" });
        // window.location.href = CLIENT.URL.landuse;
        const { InitialInput } = this.props;
        InitialInput(API.URL.inputs);
        history.push(CLIENT.URL.landuse);
        // window.location.reload();
      });
  };

  // マップの開閉イベント
  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  // 再読込ボタンイベント
  reload = (event) => {
    event.preventDefault();
    this.getResults();
  };

  render() {
    const { results, onPoint, latlong, input, isLoading } = this.props;
    const { collapse } = this.state;
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Collapse isOpen={collapse} style={{ width: "100%" }}>
              {!isLoading && results && (
                <GeoSearch latlong={latlong} results={results.calculations} />
              )}
            </Collapse>
            <CollapseArrowIcon collapse={collapse} toggle={this.toggle} />
          </Col>
          <Col sm={12}>
            {results && (
              <div>
                <Row>
                  <Col md={10}>
                    <Row>
                      <Col md={"auto"}>
                        <span style={{ verticalAlign: "sub" }}>
                          <FaCalculator id="resultico" />
                          <h5 className="displayInlinBlock">計算履歴一覧</h5>
                        </span>
                      </Col>
                      {results.calculations.filter(
                        (v) => v.status === "in_progress"
                      ).length !== 0 && (
                        <Col md={"auto"}>
                          <FaAngleDoubleLeft />
                          <FormText
                            color="danger"
                            style={{
                              fontSize: "15px",
                              display: "-webkit-inline-box",
                            }}
                          >
                            「再読込」を押すと、計算が終わったかどうか分かります。
                          </FormText>
                          <FaAngleDoubleRight />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col md={2}>
                    <Button
                      style={{
                        float: "right",
                      }}
                      disabled={
                        results.calculations.length === 0 ? true : false
                      }
                      color="success"
                      onClick={(e) => this.reload(e)}
                    >
                      <MdRefresh />
                      再読込
                    </Button>
                  </Col>
                </Row>
                <CalculateTable
                  input={input}
                  results={results.calculations}
                  duplication={this.duplication}
                  onPoint={onPoint}
                  isLoading={isLoading}
                  deleteResult={this.deleteResult}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.result,
  cropRotations: state.cropRotations,
  input: state.input,
  latlong: state.latlong,
  calcres: state.calcres,
  isLoading: state.loadResult,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  Results: (url) => dispatch(fetchResults(url)),
  // CalcRes: url => dispatch(fetchCalcRes(url)),
  onPoint: (event) => dispatch(mapOnPoint(event)),
  getToken: (url) => dispatch(getToken(url)),
  InitialInput: (url) => dispatch(fetchInitialInput(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
