export const soilLayer = (myJson, variable) => {
  return {
    type: variable,
    myJson,
  };
};

export const partitionParam = (partition, value, index, variable) => {
  return {
    type: variable,
    partition,
    value,
    index,
  };
};

export const loading = (status) => {
  return {
    type: "LOADING",
    isLoading: status,
  };
};

export const FetchPartitionData = (url) => {
  return (dispatch) => {
    dispatch(loading(true));
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        if (myJson.input.soil_layer_segm_nums) {
          dispatch(soilLayer(myJson, "FETCH_SUCCESS"));
        } else {
          dispatch(soilLayer(myJson, "FETCH_ERROR"));
        }
        dispatch(loading(false));
      });
  };
};

export const onChangePartion = (partition, event, index, variable) => {
  return (dispatch) => {
    // dispatch(partitionParam(partition, event.target.value, index, variable));
    // event &&
    dispatch(partitionParam(partition, event, index, variable));
  };
};
