/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from "react";
import { Tooltip } from "reactstrap";
// import { mapToCssModules } from '../../../src/utils'
// import classNames from 'classnames'
import { FaQuestionCircle } from "react-icons/fa";

class Tips extends React.Component {
  state = {
    tooltipOpen: false,
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    const { comment, styles, id } = this.props;
    const { tooltipOpen } = this.state;
    // console.log(id.length == 2 ? id[1] : id[0])
    return (
      <div>
        <span href="#" id={id.length === 2 ? id[1] : id[0]} style={styles}>
          <FaQuestionCircle className="tips" />
        </span>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target={id.length === 2 ? id[1] : id[0]}
          toggle={this.toggle}
          delay={{ show: 250, hide: 2000 }}
        >
          {comment}
        </Tooltip>
      </div>
    );
  }
}

export default Tips;
