import React from "react";
import { CLIENT } from "../../consts_path";

const error = ({ errormsg }) => (
  <tbody>
    <tr>
      <td colSpan={2}>
        {errormsg}
        <br />
        <a href={CLIENT.URL.root}>CO2サイト</a>
      </td>
    </tr>
  </tbody>
);

export default error;
