import React, { Component } from "react";
import { Input, Col, Label, FormGroup } from "reactstrap";

class Calendar extends Component {
  makeOption = (min, max, string) => {
    const option = [];
    for (var n = min; n <= max; n++) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    option.unshift(
      <option key={-1} id={-1} value={-1}>
        {string}
      </option>
    );
    return option;
  };
  makeYOption = (min, max, string) => {
    const option = [];
    for (var n = max; n >= min; n--) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    option.unshift(
      <option key={-1} id={-1} value={-1}>
        {string}
      </option>
    );
    return option;
  };

  render() {
    const lastMonth = this.props.monthday(
      this.props.ymd.year,
      this.props.ymd.month
    );
    const optionYear = this.makeYOption(
      this.props.year.min,
      this.props.year.max,
      "年"
    );
    const optionMonth = this.makeOption(1, 12, "月");
    const optionDay = this.makeOption(1, lastMonth, "日");
    var style = lastMonth ? false : true;
    return (
      <div>
        <FormGroup row>
          <Label for={this.props.labelFor} sm="auto" xs={12}>
            耕起日：
          </Label>
          <Col sm="auto" xs={12} style={{ margin: "0px 0px" }}>
            <FormGroup row>
              <Col sm="auto" xs="4" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  value={this.props.ymd.year}
                  name="year"
                  onChange={(e) => this.props.onChangeCalender(e)}
                >
                  {optionYear}
                </Input>
              </Col>
              <span>−</span>
              <Col sm="auto" xs="3" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  value={this.props.ymd.month}
                  name="month"
                  onChange={(e) => this.props.onChangeCalender(e)}
                >
                  {optionMonth}
                </Input>
              </Col>
              <span>−</span>
              <Col sm="auto" xs="3" style={{ margin: "0px 0px" }}>
                <Input
                  type="select"
                  value={this.props.ymd.day}
                  name="day"
                  onChange={(e) => this.props.onChangeCalender(e)}
                  disabled={style}
                >
                  {optionDay}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for={this.props.labelFor} sm="auto" xs={12}>
            作物名：
          </Label>
          <Col sm={5} xs={12}>
            <Input type="text" disabled={true} value={this.props.name || ""} />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default Calendar;
