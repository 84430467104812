import React, { Component } from "react";
import {
  Container,
  // Breadcrumb,
  // BreadcrumbItem
} from "reactstrap";
import { withCookies } from "react-cookie";
// import { FaHome } from "react-icons/fa";
import { connect } from "react-redux";
import Header from "./Header";
import { fetchInitialInput } from "./Actions/landUse";
import { API } from "./consts_path";
import Routing from "./Component/Routing";

const phantom = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    if (window.location.hash.split("/")[1] !== "result") {
      if (window.location.hash.split("/")[1] !== "calculation") {
        const { InitialInput } = this.props;
        InitialInput(API.URL.inputs);
      }
    }
  }
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { cookies, input } = this.props;
    const { isOpen } = this.state;
    return (
      <div id="wrap">
        <Header
          toggle={this.toggle}
          input={input}
          isOpen={isOpen}
          cookie={cookies}
          pathname={window.location.hash.split("/")[1]}
        />
        <Container id="container">
          <Routing cookie={cookies} input={input} />
        </Container>
        {/*<Breadcrumb>
          <BreadcrumbItem>
            <a href={CLIENT.URL.co2}>
              <FaHome title="土壌のCO2吸収「見える化」サイト" />
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>土壌の窒素見える化ツール</BreadcrumbItem>
        </Breadcrumb>*/}
        <div style={phantom} id="phantom" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
});

const mapDispatchToProps = (dispatch) => ({
  InitialInput: (url) => dispatch(fetchInitialInput(url)),
});

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
