import React, { Component } from "react";
import { Input, Col, Label, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import * as common from "../../Common/common";

import "../../../assets/crop_config.css";

class Calendar extends Component {
  makeOption = (min, max) => {
    const option = [];
    for (let n = min; n <= max; n += 1) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };

  makeYOption = (min, max) => {
    const option = [];
    for (let n = max; n >= min; n -= 1) {
      option.push(
        <option key={n} id={n}>
          {n}
        </option>
      );
    }
    return option;
  };

  render() {
    const {
      dateArr,
      year,
      labelFor,
      label,
      onChangeCalender,
      crop,
    } = this.props;
    const lastMonthDay = common.monthday(dateArr[0], parseInt(dateArr[1], 10));
    const optionYear = this.makeYOption(year.min, year.max);
    const optionMonth = this.makeOption(1, 12);
    const optionDay = this.makeOption(1, lastMonthDay);
    return (
      <FormGroup row>
        <Label for={labelFor} sm={3}>
          {label}
        </Label>
        <Col sm={9} style={{ margin: "0px 0px" }}>
          <FormGroup row>
            <Col sm="4" xs="4" style={{ margin: "0px 0px" }}>
              <Input
                type="select"
                value={dateArr[0]}
                name={labelFor}
                data-which-one="y"
                onChange={(event) => onChangeCalender(event, crop)}
              >
                {optionYear}
              </Input>
            </Col>
            <span>−</span>
            <Col sm="3" xs="3">
              <Input
                type="select"
                name={labelFor}
                data-which-one="m"
                value={`${Number(dateArr[1])}`}
                onChange={(event) => onChangeCalender(event, crop)}
              >
                {optionMonth}
              </Input>
            </Col>
            <span>−</span>
            <Col sm="3" xs="3">
              <Input
                type="select"
                name={labelFor}
                data-which-one="d"
                value={`${Number(dateArr[2])}`}
                onChange={(event) => onChangeCalender(event, crop)}
              >
                {optionDay}
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  crop: state.cropShow,
});

export default connect(mapStateToProps)(Calendar);
