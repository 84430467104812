import * as i18next from "i18next";
import * as LanguageDetector from "i18next-browser-languagedetector";
import enLocalesTranslationJson from "../locales/en.json";
import jsLocalesTranslationJson from "../locales/ja.json";

i18next.use(LanguageDetector).init({
  fallbackLng: "ja",
  debug: false,
  resources: {
    en: {
      translation: enLocalesTranslationJson,
    },
    ja: {
      translation: jsLocalesTranslationJson,
    },
  },
});

export default i18next;
