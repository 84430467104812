import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/index";
import { fetchCultivationShow } from "../../Actions/cultivation";
import { fetchCropRotations } from "../../Actions/cropRotations";
import {
  onChangeTextValue,
  onChangeCalender,
  onClickCheckbox,
} from "../../Actions/crops";
import Calendar from "./FormGroup/datepicker";
import { API, CLIENT } from "../../consts_path";
import { Spinner } from "../iconSpinner";
import { getToken } from "../../Actions/common";

class CultivationConfig extends Component {
  componentDidMount() {
    this.getCropRotate();
    this.getFertShow();
    this.getCSFRToken();
  }
  getCropRotate = () => {
    const { input, fetchCropRotate } = this.props;
    const urlForCropRotation = API.URL.croprotation.replace(
      API.REP.inputid,
      input.id
    );
    fetchCropRotate(urlForCropRotation);
  };
  getFertShow = () => {
    const { input, match, fetchCultShow } = this.props;
    const urlForCultiShow = API.URL.cultivationdelete
      .replace(API.REP.inputid, input.id)
      .replace(API.REP.paramid, match.params.id);
    fetchCultShow(urlForCultiShow, "FETCH_CULTIVATION_SHOW_SUCCESS");
  };
  getCSFRToken = () => {
    const { getToken } = this.props;
    getToken();
  };
  zeroPadding = (num, length) => {
    return ("00" + num).slice(length);
  };
  update = (event, cultivation) => {
    event.preventDefault();
    const { input, match, history, csrfToken } = this.props;
    const urlForCultiShow = API.URL.cultivationdelete
      .replace(API.REP.inputid, input.id)
      .replace(API.REP.paramid, match.params.id);
    const form = new FormData();
    for (var key in cultivation) {
      if (
        cultivation[key] != null &&
        !key.match(/crop/) &&
        !key.match(/cult_name/) &&
        !key.match("is_valid") &&
        !key.match("id")
      ) {
        const culSp = cultivation[key].toString().split(/-/);
        const culArr =
          culSp.length > 1
            ? [
                culSp[0],
                this.zeroPadding(culSp[1], -2),
                this.zeroPadding(culSp[2], -2),
              ]
            : culSp;
        form.append(`cultivation[${key}]`, culArr.join(""));
      }
      //else if (key.match(/cult_name/)) {
      //  // form.append(`cultivation[${key}]`, cultivation[key]);
      //}
    }
    fetch(urlForCultiShow, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        const urlForCulti = CLIENT.URL.cultivation.replace(
          API.REP.inputid,
          input.id
        );
        history.push(urlForCulti);
      })
      .catch((error) => console.log(error));
  };
  render() {
    const { cropRotate, tableData, isLoading } = this.props;
    return (
      <div>
        <BreadCrumb
          landuse={0}
          irrigate={0}
          partition={0}
          parameter={0}
          crop={0}
          fertilization={0}
          cultivation={1}
        />
        <br />
        {isLoading ? (
          <span className="text-center">
            <Spinner />
          </span>
        ) : (
          cropRotate &&
          tableData && (
            <div>
              <Row>
                <Col md={12}>
                  <h4>{tableData.cultivation.crop.crop_detail}</h4>
                  <h4>{`${tableData.cultivation.cult_name}のイベント設定`}</h4>
                </Col>
              </Row>
              <hr />
              <Form className="cultivation_config">
                <Calendar
                  year={{ min: cropRotate.min_year, max: cropRotate.max_year }}
                  labelFor="var_dcult"
                  dateArr={tableData.cultivation.var_dcult.split(/-/)}
                  label={i18next.t("cultivation.showEdit.dcult")}
                  cultivation={tableData.cultivation}
                  onChangeCalendar={this.props.onChangeCalendar}
                />
                <FormGroup row>
                  <Label for="var_cultdep" md={2} sm={12}>
                    {`${i18next.t("cultivation.showEdit.cultdep")}（${i18next.t(
                      "extension.mm"
                    )}）`}
                  </Label>
                  <Col md={3} sm={5} xs={5}>
                    <Input
                      type="number"
                      step={1}
                      name="var_cultdep"
                      id="var_cultdep"
                      onChange={(e) =>
                        this.props.onChangeTextValue(e, tableData.cultivation)
                      }
                      value={tableData.cultivation.var_cultdep}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={12} xs={12} className="cutiConfig">
                    <Label for="check">
                      <Input
                        type="checkbox"
                        name="copy_cultivations"
                        id="check"
                        onClick={(e) =>
                          this.props.onClickCheckbox(e, tableData.cultivation)
                        }
                      />
                      {`${i18next.t("cultivation.showEdit.check")}`}
                    </Label>
                  </Col>
                </FormGroup>
                <Row>
                  <Col xs="auto">
                    <Button
                      type="button"
                      color="success"
                      onClick={(event) =>
                        this.update(event, tableData.cultivation)
                      }
                    >
                      更新
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Link
                      to={CLIENT.URL.cultivation.replace(
                        API.REP.inputid,
                        this.props.input.id
                      )}
                    >
                      <Button type="button" size="" color="secondary">
                        キャンセル
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.cultishow,
  cropRotate: state.cropRotations,
  input: state.input,
  textbox: state.textbox,
  calendar: state.calender,
  checkbox: state.checkbox,
  isLoading: state.loadCultivations,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCultShow: (url, variable) =>
    dispatch(fetchCultivationShow(url, variable)),
  fetchCropRotate: (url) => dispatch(fetchCropRotations(url)),
  onChangeTextValue: (event, crop) => dispatch(onChangeTextValue(event, crop)),
  onChangeCalendar: (event, crop) => dispatch(onChangeCalender(event, crop)),
  onClickCheckbox: (event, crop) => dispatch(onClickCheckbox(event, crop)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CultivationConfig);
