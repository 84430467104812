import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import {
  fetchCropShow,
  fetchCrops,
  onChangeRadio,
  onChangeCalender,
  onChangeTextValue,
  onClickCheckbox,
} from "../../Actions/crops";
import BreadCrumb from "../BreadCrumb/index";
import RadioGroup from "./FormGroup/radioGroup";
import CalenderGroup from "./FormGroup/calenderGroup";
import TextBoxGroup from "./FormGroup/textBoxGroup";
import CheckboxGroup from "./FormGroup/checkboxGroup";
import { API, CLIENT } from "../../consts_path";
import * as common from "../Common/common";
import { getToken } from "../../Actions/common";
import { Spinner } from "../iconSpinner";

class CropConfig extends Component {
  componentDidMount() {
    const { input, match, Crop, cropShow, getToken } = this.props;

    const urlForCrops = API.URL.crop.replace(":input_id", input.id);
    const urlForCrop = common.makeUrlShow(
      API.URL.cropdelete,
      input.id,
      match.params.id
    );
    Crop(urlForCrops);
    cropShow(urlForCrop);
    getToken();
    const phantom = document.getElementById("phantom");
    phantom.style.height = "40px";
  }

  zeroPadding = (num, length) => {
    return `00${num}`.slice(length);
  };

  update = (event, crop) => {
    event.preventDefault();
    const form = new FormData();
    Object.keys(crop).map((key) => {
      if (!key.match(/id/)) {
        const crSp = key.match(/var_|end_date|prev_end_date|start_date/)
          ? crop[key].toString().split(/-/)
          : [crop[key]];
        const crArr =
          crSp.length > 1
            ? [
                crSp[0],
                this.zeroPadding(crSp[1], -2),
                this.zeroPadding(crSp[2], -2),
              ]
            : crSp;
        form.append(`crop[${key}]`, crArr.join(""));
      }
      return true;
    });
    const { input, history, csrfToken } = this.props;
    const urlForCrop = common.makeUrlShow(
      API.URL.cropdelete,
      input.id,
      crop.id
    );
    fetch(urlForCrop, {
      credentials: "same-origin",
      method: "PUT",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      history.push(common.makeUrlIndex(CLIENT.URL.crop, input.id));
      // window.location = common.makeUrlIndex(CLIENT.URL.crop, input.id);
    });
  };

  render() {
    const {
      crop,
      crops,
      changeRadio,
      changeCalender,
      changeTextValue,
      input,
      clickCheckbox,
      isLoading,
    } = this.props;
    return (
      <div>
        <BreadCrumb
          landuse={0}
          partition={0}
          parameter={0}
          irrigate={0}
          crop={1}
        />
        <br />
        {isLoading ? (
          <Spinner />
        ) : (
          crop &&
          crops && (
            <div>
              <Row>
                <Col xs={6} style={{ marginBottom: 0 }}>
                  <h4>{`収穫年 : ${crop.base_year}`}</h4>
                  <h4>{`${crop.crop_detail}のイベント設定`}</h4>
                </Col>
                <Col xs={6} style={{ marginBottom: 0 }}>
                  <small id="help">
                    <FaQuestionCircle />
                    にマウスカーソル（矢印）を重ねるとヘルプが表示されます。
                  </small>
                </Col>
              </Row>
              <hr />
              <Form id="cropConfig" name="cropConfig">
                <RadioGroup onChangeRadio={changeRadio} crop={crop} />
                <hr />
                <CalenderGroup
                  onChangeCalender={changeCalender}
                  crops={crops}
                  crop={crop}
                />
                <hr />
                <TextBoxGroup onChangeTextValue={changeTextValue} crop={crop} />
                <CheckboxGroup crop={crop} onClickCheckbox={clickCheckbox} />
                <Row>
                  <Col xs="auto">
                    <Button
                      color="success"
                      onClick={(e) => this.update(e, crop)}
                    >
                      更新
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Link to={common.makeUrlIndex(CLIENT.URL.crop, input.id)}>
                      <Button color="secondary">キャンセル</Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
              <br />
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crops: state.crops,
  crop: state.cropShow,
  success: state.success,
  calender: state.calender,
  textbox: state.textbox,
  checkbox: state.checkbox,
  input: state.input,
  isLoading: state.loadCrops,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  Crop: (url) => dispatch(fetchCrops(url)),
  cropShow: (url) => dispatch(fetchCropShow(url)),
  changeRadio: (event, crop) => dispatch(onChangeRadio(event, crop)),
  changeCalender: (event, crop) => dispatch(onChangeCalender(event, crop)),
  changeTextValue: (event, crop) => dispatch(onChangeTextValue(event, crop)),
  clickCheckbox: (event, crop) => dispatch(onClickCheckbox(event, crop)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CropConfig);
