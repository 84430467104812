import React, { Component } from "react";
import { connect } from "react-redux";
import Plot from "react-plotly.js";
import * as conf from "../plotConf";
import { API } from "../../../consts_path";

class TPLOT extends Component {
  state = { activeTab: "1", d: [], c: [] };
  componentDidUpdate(previousProps, previousState) {
    const { outputId, plotDate } = this.props;
    if (previousProps.plotDate.length !== plotDate.length) {
      if (plotDate.length !== 0) {
        const url = API.URL.timeLine
          .replace(":output_id", outputId)
          .replace(":query", plotDate.join(","));
        if (!plotDate.some((item) => item.match(/undefined-\d/))) {
          this.timeData(url);
        } else {
          this.setState({ c: [] });
        }
      } else {
        this.setState({ c: [] });
      }
    }
  }
  timeData = (url) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => responce.json())
      .then((data) => {
        var assign = conf.default.timeY;
        var a = data.time_line.y1.map((y, i) => {
          var line = { color: "red", dash: "" };
          var marker = { symbol: "" };
          y.type = assign.type;
          y.mode = assign.mode;
          line.dash = conf.default.dashArray[i];
          marker.symbol = conf.default.marker[i];
          y.line = line;
          y.marker = marker;
          return y;
        });
        data.time_line.y2 = data.time_line.y2 ? data.time_line.y2 : [];
        var assign2 = conf.default.timeY2;
        const b = data.time_line.y2.map((y, i) => {
          var line = { dash: "", color: "blue", width: 4 };
          var marker = { symbol: "" };
          y.type = assign2.type;
          y.mode = assign2.mode;
          y.yaxis = assign2.yaxis;
          line.dash = conf.default.dashArray[i];
          marker.symbol = conf.default.marker[i];
          y.line = line;
          y.marker = marker;
          return y;
        });
        const ab = a.concat(b);
        this.setState({
          c: ab,
          xAxis: data.time_line.x_axis,
          y1Axis: data.time_line.y1_axis,
          y2Axis: data.time_line.y2_axis,
        });
      })
      .catch((error) => {
        this.setState({ c: [] });
      });
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Plot
          data={this.state.c}
          style={{ width: "100%", height: "100%" }}
          layout={{
            autosize: true,
            linewidth: 0.5,
            showlegend: true,
            legend: { x: 0, y: 1.5 },
            xaxis: {
              title: `${this.state.xAxis || "-"}`,
              linecolor: "black",
              autorange: "tozero",
              tickformat: "%Y-%m-%d",
              tickangle: -45,
            },
            yaxis: { title: `${this.state.y1Axis || "-"}`, linecolor: "red" },
            yaxis2: {
              title: `${this.state.y2Axis}`,
              linecolor: "blue",
              overlaying: "y",
              side: "right",
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TPLOT);
