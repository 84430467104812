import React, { Component } from "react";
import DayPickerJs, { DateUtils } from "react-day-picker";
import { connect } from "react-redux";
import "react-day-picker/lib/style.css";
import { FaTimesCircle } from "react-icons/fa";
import { Badge } from "reactstrap";
// import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/ja";
import { API } from "../../consts_path";
import * as common from "../Common/common";
import { onSelectedDay } from "../../Actions/graph";

var moment = require("moment");

// function YearMonthForm({ date, localeUtils, onChange, range }) {
function YearMonthForm({ date, onChange, range }) {
  // momentのlocaleUtilsを使うと漢数字の月が帰ってくる。
  const months = monthsList(1, 12); // monthsList(range.min[1], range.max[1]); // localeUtils.getMonths('ja');
  // const months = localeUtils.getMonths('ja');
  const years = [];
  //for (var ii = Number(range.min[1]); ii >=1; ii -= 1) {
  //  console.log(ii)
  //}
  for (var i = Number(range.min[0]); i <= Number(range.max[0]); i += 1) {
    years.push(i);
  }
  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    // console.log( year.value, month.value )
    // state.year = year.value;
    // state.month = month.value;
    onChange(new Date(year.value, month.value));
  };
  return (
    <form className="DayPicker-Caption">
      <div>
        <select
          type="select"
          name="year"
          id="year"
          onChange={handleChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          type="select"
          id="month"
          name="month"
          onChange={handleChange}
          value={date.getMonth()}
        >
          {months.map((month, i) => (
            <option key={month} value={month.split("月")[0] - 1}>
              {month}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
}

const monthsList = (min, max) => {
  moment().format("MMM");
  var months = [];
  var i = Number(min) - 1;
  while (i < Number(max)) {
    months.push(moment().locale("ja").month(i).format("MMM"));
    i += 1;
  }
  return months;
};

class DayPicker extends Component {
  state = {
    selectedDays: [],
    dayString: [],
    badgeString: [],
    month: "",
    min: [],
    max: [],
    mdo: null,
  };
  componentDidMount() {
    const { graphData } = this.props;
    const urlForTimeRange = common.makeVisualPartsUrl(
      API.URL.timelinerange,
      graphData.output.id
    );
    this.timeData(urlForTimeRange);
  }
  timeData = (url) => {
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
    })
      .then((responce) => {
        if (!responce.ok) {
          throw Error(responce.statusText);
        }
        return responce;
      })
      .then((responce) => responce.json())
      .then((data) => {
        const min = data.min.split(/-/);
        const max = data.max.split(/-/);
        const mdo = new Date(max[0], Number(max[1]) - 1, max[2]);
        const mnt = new Date(min[0], Number(min[1]) - 1, min[2]);
        this.setState({ min: min, max: max, mdo: mdo, month: mnt });
      });
  };
  /*
   * 日付選択イベント
   * day: 選択された日付オブジェクト
   * selected: すでに選択されている日付がクリックされればtrue。基本は、undefined
   * */
  handleDayClick = (day, { selected }) => {
    const { selectedDays, dayString, badgeString } = this.state;
    const { onSelectedDay } = this.props;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
      dayString.splice(selectedIndex, 1);
      badgeString.splice(selectedIndex, 1);
    } else {
      if (selectedDays.length === 3) return false;
      selectedDays.push(day);
      dayString.push(moment(day).format("YYYYMMDD"));
      badgeString.push(moment(day).format("YYYY年MM月DD日"));
    }
    this.setState({ selectedDays, dayString, badgeString });
    onSelectedDay({ dayString });
  };

  /*
   * 選択月の変更イベント
   * month: 月イベント
   * */
  handleYearMonthChange = (month) => {
    this.setState({ month: month, mdo: month });
  };
  clearDay = (index) => {
    const { selectedDays, dayString, badgeString } = this.state;
    const { onSelectedDay } = this.props;
    selectedDays.splice(index, 1);
    dayString.splice(index, 1);
    badgeString.splice(index, 1);
    this.setState({ selectedDays, dayString, badgeString });
    onSelectedDay({ dayString });
  };
  render() {
    const { badgeString, min, max, mdo, month, selectedDays } = this.state;
    const dayBadge = badgeString.map((str, index) => {
      return (
        <span key={index} style={{ marginRight: "5px" }} id={`span_${index}`}>
          <Badge color="warning" style={{ fontSize: "15px" }}>
            {str}
            <FaTimesCircle
              style={{ verticalAlign: "bottom", marginLeft: "5px" }}
              onClick={() => this.clearDay(index)}
            />
          </Badge>
        </span>
      );
    });
    const modifiers = {
      start: new Date(min[0], min[1] - 1, min[2]),
      end: new Date(max[0], max[1] - 1, max[2]),
    };
    const modifiersStyles = {
      start: {
        borderLeft: "1px solid",
        borderTop: "1px solid",
      },
      end: {
        borderRight: "1px solid",
        borderBottom: "1px solid",
      },
    };
    return (
      <div>
        評価期間：{min.join("/")} ~ {max.join("/")}
        <div style={{ marginBottom: "5px" }}>{dayBadge}</div>
        {mdo && (
          <DayPickerJs
            month={month}
            initialMonth={new Date(min[0], min[1] - 1, min[2])}
            fromMonth={new Date(min[0], min[1] - 1, min[2])}
            toMonth={new Date(max[0], max[1] - 1, max[2])}
            selectedDays={selectedDays}
            onDayClick={this.handleDayClick}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            // captionElement={({ date, localeUtils }) => (
            captionElement={({ date }) => (
              <YearMonthForm
                date={date}
                // localeUtils={MomentLocaleUtils}
                onChange={this.handleYearMonthChange}
                range={{ max: max, min: min }}
              />
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedDays: state.selected_day_success,
  graphData: state.graphData,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedDay: (day) => dispatch(onSelectedDay(day)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayPicker);
