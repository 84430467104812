import React from "react";
import i18next from "i18next";
import TextBox from "./textBox";

const TextBoxGroup = ({ onChangeTextValue, crop }) => (
  <div>
    {/* 相対根深度 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_rdep"
      step={1}
      label={i18next.t("crops.showEdit.rdep")}
    />
    {/* 最大作物被覆率 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_ak1"
      step={0.1}
      label={i18next.t("crops.showEdit.ak1")}
    />
    {/* 収穫時作物被覆率 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_ccovhar"
      step={0.1}
      label={i18next.t("crops.showEdit.ccovhar")}
    />
    {/* マルチによる蒸発抑制効果率 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_mulch"
      step={0.1}
      label={i18next.t("crops.showEdit.mulch")}
    />
    {/* パン蒸発量に乗じる係数 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_panfac"
      step={0.1}
      label={i18next.t("crops.showEdit.panfac")}
    />
    {/* 作物の大気・根からの可能吸収窒素量 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_uptn"
      step={1}
      label={i18next.t("crops.showEdit.uptn")}
    />
    {/* 作物の大気・根からの可能吸収リン量 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_uptp"
      step={1}
      label={i18next.t("crops.showEdit.uptp")}
    />
    {/* 作物の大気から必ず固定される窒素量 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_fixedn"
      step={1}
      comment={i18next.t("crops.comment.fixedn")}
      label={i18next.t("crops.showEdit.fixedn")}
    />
    {/* 収穫率 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_hfrac"
      step={0.1}
      comment={i18next.t("crops.comment.hfrac")}
      label={i18next.t("crops.showEdit.hfrac")}
    />
    {/* 作物のC/N比 */}
    <TextBox
      onChange={onChangeTextValue}
      crop={crop}
      labelFor="var_cnratio"
      step={0.1}
      comment={i18next.t("crops.comment.cnratio")}
      label={i18next.t("crops.showEdit.cnratio")}
    />
  </div>
);

export default TextBoxGroup;
