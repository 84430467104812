import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import BreadCrumb from "../BreadCrumb/index";
import * as common from "../Common/common";
import PageController from "../PageController/index";
import DetailScreen from "../DetailScreen/index";
import { fetchCrops } from "../../Actions/crops";
import { getToken } from "../../Actions/common";
import CropTable from "./Table";
import NewCrops from "./newCrops";
import InvalidAlert from "./invalidAlert";
import VisTimeLine from "../VisTimeLine/index";
import { fetchVisItems } from "../../Actions/vis_timeline";
import { API, CLIENT } from "../../consts_path";
import { handleModal } from "../../Actions/common";
import IconAndLabel from "../IconAndLabel/index";
const crop = require("../../assets/crop2.png");

/**
 * 作物条件コンポーネント
 */

class Crops extends Component {
  componentDidMount() {
    const { input, getToken, Crop } = this.props;
    const urlForCrops = API.URL.crop.replace(":input_id", input.id);
    Crop(urlForCrops);
    getToken();
  }

  create = (event) => {
    event.preventDefault();
    const form = new FormData();
    const crop = document.getElementById("crop");
    const year = document.getElementById("years");
    const { input, csrfToken, Crop } = this.props;
    const urlForCropsPost = API.URL.crop.replace(":input_id", input.id);
    form.append("crop[initial_crop_id]", crop.value);
    form.append("crop[base_year]", year.value);

    fetch(urlForCropsPost, {
      credentials: "same-origin",
      method: "POST",
      body: form,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      if (!res.ok) {
        alert("地点選択からやり直してください。");
        throw Error((window.location = CLIENT.URL.root));
      }
      const urlForCrops = API.URL.crop.replace(":input_id", input.id);
      Crop(urlForCrops);
    });
  };

  isDisabled = (props) => {
    const copyProps = Object.assign({}, props);
    copyProps.isValid = props.crops.length === 0 ? true : props.isValid;
    return copyProps.isValid;
  };

  render() {
    const assign = Object.assign(common.pageController);
    const {
      input,
      Crop,
      crops,
      isLoading,
      match,
      timeline,
      isOpen,
      csrfToken,
    } = this.props;
    assign.display = { display: "block" };
    assign.url = [
      CLIENT.URL.segmentparamater.replace(":input_id", input.id),
      CLIENT.URL.fertilization.replace(":input_id", input.id),
    ];
    assign.value = ["戻る", "次へ"];
    return (
      <div>
        <BreadCrumb
          landuse={0}
          partition={0}
          parameter={0}
          irrigate={0}
          crop={1}
        />
        {timeline && <VisTimeLine timeline={timeline} />}
        <br />
        {crops && (
          <div>
            <NewCrops create={this.create} commonBtn={this.isDisabled(crops)} />
            {crops.isValid && <InvalidAlert text={i18next.t("alert.danger")} />}
            <IconAndLabel
              path={crop}
              alt="作物"
              id="cropico"
              label="作物条件一覧"
            />
            <CropTable
              data={crops}
              fetchCrops={Crop}
              match={match}
              isLoading={isLoading}
              token={csrfToken}
            />
            {!isLoading && (
              <PageController assign={assign} flagN={this.isDisabled(crops)} />
            )}
            {isOpen && <DetailScreen />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crops: state.crops,
  hasError: state.getCropsError,
  isLoading: state.loadCrops,
  input: state.input,
  timeline: state.visTimelineItems,
  isOpen: state.modal,
  csrfToken: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  Crop: (url) => dispatch(fetchCrops(url)),
  VisItems: (url) => dispatch(fetchVisItems(url)),
  OpenModal: (isOpen, index) => dispatch(handleModal(isOpen, index)),
  getToken: (url) => dispatch(getToken(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Crops);
