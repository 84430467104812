import React, { Component } from "react";
import Select from "react-select";
import { FormText, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { fetchDepth, onChangeDepth } from "../../Actions/graph";

class DepthSelector extends Component {
  render() {
    const { selectedDepth, depth, ChangeDepth } = this.props;
    return (
      <div>
        {depth && (
          <FormGroup>
            <Select
              options={
                selectedDepth.length === 3
                  ? selectedDepth
                  : depth.segment_options
              }
              isMulti
              isLading={true}
              onChange={(e) => ChangeDepth(e)}
              name="depth"
              className="depthSelect"
              placeholder="深さを選択してください"
              classNamePrefix="select"
              styles={{ margin: 0 }}
            />
            <FormText>
              <span>
                括弧[()]内に表記されている深さは、Fluxに対応しています。
              </span>
            </FormText>
          </FormGroup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedDepth: state.change_depth_success,
  depth: state.depth,
});

const mapDispatchToProps = (dispatch) => ({
  Depth: (url) => dispatch(fetchDepth(url)),
  ChangeDepth: (e) => dispatch(onChangeDepth(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthSelector);
