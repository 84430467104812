import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, CardBody } from "reactstrap";
// import SoilType from "./options/soil_type";
import RegionAndDistrict from "./options/region_and_district";
import LandUseType from "./../LandUse/options/land_use_type";
import IrrigateType from "./options/irrigate";
import {
  onChangeSoilType,
  fetchWeather,
  fetchIrrigate,
  fetchNearestSites,
} from "../../Actions/landUse";
import { API } from "../../consts_path";
import { Spinner } from "../iconSpinner";
class DataSelector extends Component {
  componentDidMount() {
    const { input, Weather, Irrigate, nearestSitesData } = this.props;
    const urlForWeather = API.URL.weather.replace(API.REP.inputid, input.id);
    const urlForIrrigate = API.URL.irrigate.replace(API.REP.inputid, input.id);
    nearestSitesData && Weather(urlForWeather, nearestSitesData[0].toString());
    Irrigate(urlForIrrigate, "FETCH_DATA_IRRIGATE_SUCCESS");
  }
  render() {
    const { changeSoilType, weather, isLoading, nearestSitesData } = this.props;
    return (
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Card>
            <CardBody className="row">
              <div className="col-12 col-sm-12">
                <LandUseType />
                <RegionAndDistrict
                  weather={weather}
                  nearestSitesData={nearestSitesData}
                  changeMeteoCrop={changeSoilType}
                />
                {/* 2022-12-21 - Unused component */}
                {/* <SoilType changeSoilType={changeSoilType} weather={weather} /> */}
                <IrrigateType changeSoilType={changeSoilType} />
	            </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  input: state.input,
  weather: state.weather,
  irrigate: state.irrigateData,
  isLoading: state.load,
  nearestSitesData: state.nearestSitesData,
});

const mapDispatchToProps = (dispatch) => ({
  changeSoilType: (e, geo, variable) =>
    dispatch(onChangeSoilType(e, geo, variable)),
  Weather: (url, nearest_site) => dispatch(fetchWeather(url, nearest_site)),
  Irrigate: (url, variable) => dispatch(fetchIrrigate(url, variable)),
  NearestSites: (url) => dispatch(fetchNearestSites(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSelector);

