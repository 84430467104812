import React from "react";
import { Table } from "reactstrap";
import Td from "./td";
import {Spinner} from "../../iconSpinner";
const ParameterTable = ({ param, inputid, isLoading }) => (
  <div>
    <h3>セグメントの土壌条件設定</h3>
    {isLoading ? (
      <Spinner />
    ) : (
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>層</th>
            <th>セグメント</th>
            <th />
          </tr>
        </thead>
        {param && <Td param={param} inputid={inputid} />}
      </Table>
    )}
  </div>
);

export default ParameterTable;
